/* eslint-disable camelcase */
/* eslint prefer-const: "error" */
// /* eslint prefer-let: 2 */
/* eslint-env es6 */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { addBlog } from '../../common/api-service';

function AddBlogManagement() {
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [blogDescription, setBlogDescription] = React.useState([]);
  const [blogStatus, setStatus] = React.useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const [blogLink, setBlogLink] = useState([]);
  const [baseImage, setBaseImage] = useState('');
  const [typeOfUser, setTypeOfUser] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const blog_status = ['active', 'disabled'];
  const type_users = ['user', 'sitter'];

  function getStyles(type, Name, theme) {
    return {
      fontWeight: Name.indexOf(type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      title: userName,
      description: blogDescription,
      image: baseImage,
      link: blogLink,
      type_of_user: typeOfUser,
      status: blogStatus,
    };
    return addBlog(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          navigate('/dashboard/blogManagement', { replace: true });
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Add Blog
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid>
                    {/* Title */}
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Description */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      value={blogDescription}
                      onChange={(e) => setBlogDescription(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Link */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Link"
                      variant="outlined"
                      value={blogLink}
                      onChange={(e) => setBlogLink(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Type of User */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Types Of User</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={typeOfUser}
                        onChange={(e) => {
                          setTypeOfUser(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Types Of User" />}
                        MenuProps={MenuProps}
                      >
                        {type_users.map((type_of_user) => (
                          <MenuItem
                            key={type_of_user}
                            value={type_of_user}
                            style={getStyles(type_of_user, typeOfUser, theme)}
                          >
                            {type_of_user}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={blogStatus}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {blog_status.map((blog_status) => (
                          <MenuItem
                            key={blog_status}
                            value={blog_status}
                            style={getStyles(blog_status, blogStatus, theme)}
                          >
                            {blog_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Image upload */}
                  <Grid>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography component="div" display="flex" justifyContent="center" alignItems="center">
                        Icon
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <br />
                      <img src={baseImage} alt="" height="200px" />
                    </Stack>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Add Blog
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default AddBlogManagement;
