/* eslint-disable camelcase */
/* eslint-disable */
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button,
  Tooltip,
  Select,
  Menu,
  MenuItem,
  Modal,
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { GroomingReschduleListHead } from '../../../sections/@dashboard/groomingReschduleBooking';
// mock
import { GrommingReschduleApprove, bookingCancelationRequest, getGroomingReschduleHistoryData, getGroomingReschduleHistorySearchData, getTimeSlotsForReschedule, rescheduleBookingDateUpdate, rescheduleBookingHistoryChangeSitter } from '../../../common/api-service';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import BackButton from '../../../components/BackButton';

import { DatePicker } from 'antd';
import swal from 'sweetalert2';
import { TimePicker } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import RescheduleReqActionBookingDetails from '../RescheduleReqActionBookingDetail';
import CustomerOrderDetails from '../CustomerOrderDetails';

// ---------------------------------------------------------------- ------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'user_name', label: 'Name', alignRight: false },
  // { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  // { id: 'service_name', label: 'Service Name', alignRight: false },
  // { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'reschedule', label: 'Reschedule Date', alignRight: false },
  { id: 'reschedule_time_slot', label: 'Reschedule Time Slot', alignRight: false },
  { id: 'start_date', label: 'Previous Start Date', alignRight: false },
  { id: 'time_slot', label: 'Previous Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  // { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_name', label: 'Package', alignRight: false },
  // { id: 'user_contact_no', label: 'Phone No', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  // { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  // { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  // { id: 'sessions', label: 'Number of Session', alignRight: false },
  // { id: 'offer_description', label: 'Offer Description', alignRight: false },
  // { id: 'offer_count', label: 'Offer Count', alignRight: false },
  // { id: 'offer_remaining', label: 'Offer Remaining', alignRight: false },
  // { id: 'cancellation_request_date', label: 'Cancellation Request Date', alignRight: false },
  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function GroomingReschduleHistory() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('created_at');
  const [selectedbooking, setSelectedBooking] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelResponse, setCancelResponse] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState(null);
  // const [rescheduleTime, setRescheduleTime] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [sessionRescheduleId, setSessionRescheduleId] = useState();

  const [userId, setUserId] = useState();
  const [serviceBookingId, setServiceBookingId] = useState();

  const [rowDatas, setRowData] = useState();
  const navigate = useNavigate();

  const [rowData1, setRowData1] = useState();
  const [rowData2, setRowData2] = useState();

  const [startDate, setStartDate] = useState();
  const [serviceMasterId, setServiceMasterId] = useState();

  const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('week'));
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs());
  const [popupOpen, setPopupOpen] = useState(false);


  const reschedule = () => {
    setLoading(true);
    const payload = page;
    return getGroomingReschduleHistoryData(payload, searchQuery, defaultStartDate.add(1, 'day'),defaultEndDate.add(1, 'day'), selectedDate, rowsPerPage, selectedbooking, defaultStartDate, defaultEndDate).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const handleTimeSlotChange = (timeRange) => {
    setSelectedTimeSlot(timeRange.target.value);
  };

  useEffect(() => {
    reschedule();
    fetchTimeSlots();
  }, [offset, page, searchQuery, fromDate, toDate, selectedDate, rowsPerPage, selectedbooking]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleSearch = () => {

    return getGroomingReschduleHistorySearchData(page, searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );

  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {

      // handleSearch();
    }
  };

  // const datefilterhandleChange = (props) => {
  //   if (props) {
  //     const [start, end] = props;
  //     setFromDate(start);
  //     setToDate(end);
  //   } else {
  //     setFromDate('');
  //     setToDate('');
  //   }
  // };

  const datefilterhandleChange =(props)=>{
 
    const [start, end] = props;

    setDefaultStartDate(start);
    // setDefaultEndDate(end.add(1, 'day'))
    setDefaultEndDate(end)

  
}


  const handleChangeDate = (event) => {
    setSelectedBooking(event.target.value);
  };

  const style = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: "fit-content",
    backgroundColor: 'white',
    boxShadow: 10,
    borderRadius: '10px',
    border: '1.5px solid black',
    p: 4,
    textAlign: 'center',
    fontSize: '2.5rem'
  };


  const handleOpenModal = (_id, row) => {

    setRowData(row)
    setSessionRescheduleId(_id)
    // setIsOpen(true);
  };

  const sitterallocationpage = (row, start_date, service_master_id) => {

    setServiceMasterId(service_master_id);
    setStartDate(start_date);
    setRowData1(row);
    // const setid = service_master_id;
    // const setcalendarData = row;
    // const setdate = moment(start_date != null ? start_date : "").format('DD-MM-YYYY');
    // localStorage.setItem('groomingCalenderData', JSON.stringify(setcalendarData));
    // localStorage.setItem('getGroomingAllocationID', setid);
    // localStorage.setItem('getGroomingAllocationDate', setdate);

  }

  // Function to handle opening the cancel modal
  const handleOpenCancelModal = (_id, user_id, service_booking_id) => {
    setUserId(user_id);
    setServiceBookingId(service_booking_id);
    setIsCancelModalOpen(true);
  };

  // Function to handle closing the cancel modal
  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  // Function to handle user response in the cancel modal
  const handleCancelResponse = (response) => {
    setCancelResponse(response);
    setIsCancelModalOpen(false);

    // Handle user's response here (e.g., if response is 'Yes', cancel the request)
    if (response === 'No') {

      setIsOpen(true);
    } else if (response === 'Yes') {
      const payload = {
        user_id: userId,
        session_id: sessionRescheduleId,
        service_booking_id: serviceBookingId,
        service_master_id: serviceMasterId,
        reason_by_admin: '',
        status: "cancelled",
      };
      bookingCancelationRequest(payload).then((res) => {
        if (res.data.status) {
          
          navigate('/dashboard/groomingCancellationHistory');
          // handleRefresh((preval) => !preval);
          // updatecount();
        }
      });
      
    }
  };



  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleRescheduleDate = (date) => {
    setRescheduleDate(null)
    setRescheduleDate(date.format('YYYY/MM/DD'));
  }

  // const handleRescheduleTime = (time) => {
  // console.log('time :', time);
  // const startTime = moment(time[0]).format('h:mm A');
  // const endTime = moment(time[1]).format('h:mm A');
  // const timeSlot = `${startTime}-${endTime}`;
  // console.log('timeSlot:', timeSlot);
  // setTimeRange(timeSlot);
  // }

  // const handleReferenceID = (booking_ref_id , _id) => {
  //   localStorage.setItem("bookingReferenceId", booking_ref_id);
  //   localStorage.setItem('cancellationBookingReferenceId',_id);
  //   localStorage.setItem("s1", "Reschedule History");
  // }
  const [show,setShow]=useState(false);
  const [rescheduleShow, setRescheduleShow] = useState(false)

  const handleReferenceID = (booking_ref_id, _id , reschedule_status , request_for_rescheduled) => {
    setShow(true);
    setRescheduleShow(reschedule_status === 'pending' && request_for_rescheduled === true ? true : false)
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", _id);
    localStorage.setItem("s1", "Ongoing")

  }

  // const handleRescheduleTime = (value, dateString) => {

  //   if (value && value.length === 2) {
  //     try {
  //       const startTime = moment(dateString[0], 'h:mm a').format('h:mm A');
  //       const endTime = moment(dateString[1], 'h:mm a').format('h:mm A');
  //       const timeSlot = `${startTime}-${endTime}`;
  //       setTimeRange(timeSlot);
  //     } catch (error) {
  //       console.error('Error parsing time:', error);
  //       // Handle the error accordingly
  //     }
  //   } else {
  //     // Handle the case when no valid time range is selected
  //     setTimeRange(null);
  //   }
  // }


  // const [selectedSlot, setSelectedSlot] = useState('');
  const fetchTimeSlots = async () => {
    return getTimeSlotsForReschedule().then(
      (res) => {
        setTimeSlots(res.data.data.get_paginated);
        console.log('res.data.data.get_paginated :', res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };


  // const handleChangeTimeSlot = (event) => {
  //   setSelectedSlot(event.target.value);
  // };



  const handleSubmit = (rowDatas) => {

    const setid = serviceMasterId;

    // const setcalendarData = rowData1;

    const setdate = moment(startDate != null ? startDate : "").format('DD-MM-YYYY');

    // localStorage.setItem('groomingCalenderData', JSON.stringify(setcalendarData));
    localStorage.setItem('getGroomingAllocationID', setid);
    localStorage.setItem('getGroomingAllocationDate', setdate);


    setIsOpen(false);

    const payload = {
      session_id: sessionRescheduleId,
      reschedule_time_slot: selectedTimeSlot,
      reschedule_date: rescheduleDate,
    }

    //   rescheduleBookingDateUpdate(payload).then(() => {


    //   if (rowDatas.hasOwnProperty('sitter_id')) {
    //     console.log("popup")
    //     // If user_name exists, show the confirmation dialog
    //     swal.fire({
    //       title: 'Do you want to change sitter or continue with the same sitter?',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonText: 'Change',
    //       cancelButtonText: 'No Change'
    //     }).then((result) => {
    //       console.log('result :', result);
    //       if (result.isConfirmed === true) {
    //         // If user confirms, approve the request
    //         rescheduleBookingDateUpdate(payload).then((res) => {
    //           console.log(res.data.status);
    //           if (res.data.status === true) {
    //             // If request is approved, navigate and update counts
    //             navigate('/dashboard/groomingSitterAllocation');
    //             handleRefresh((preval) => !preval);
    //             updatecount();
    //           }
    //         });
    //       } else if (result.isConfirmed === false && result.dismiss === "cancel") {
    //         // If user cancels, perform some action (optional)
    //         console.log("test");
    //         rescheduleBookingDateUpdate(payload).then((res) => {
    //           if (res.data.status === true) {
    //             console.log("test1");
    //             swal.fire({
    //               title: res.data.message,
    //               icon: 'success',
    //               timer: 2000, // Duration to automatically close the alert (in milliseconds)
    //               showConfirmButton: false, // Hide the confirmation button
    //             });
    //             handleRefresh((preval) => !preval);
    //             updatecount();
    //           }
    //         });
    //       }
    //     });
    //   } else {

    //     return rescheduleBookingDateUpdate(payload).then((res) => {
    //           if (res.data.status === true) {
    //             navigate('/dashboard/groomingSitterAllocation');
    //             // swal.fire({
    //             //   title: res.data.message,
    //             //   icon: 'success',
    //             //   timer: 2000,
    //             //   button: false,
    //             // });
    //             handleRefresh((preval) => {
    //               return !preval;
    //             });
    //             updatecount();
    //           } else {
    //             swal.fire({
    //               title: res.data.message,
    //               icon: 'danger',
    //               timer: 2000,
    //               button: false,
    //             });
    //           }
    //         });
    //   }

    // })

    // console.log("res.data.data",rowData2);
    rescheduleBookingDateUpdate(payload).then((res) => {
      // console.log('res :', res.data.data);
      localStorage.setItem('groomingCalenderData', JSON.stringify(res.data.data));

      setRowData2(res.data.data);
      if (rowDatas.hasOwnProperty('sitter_id')) {
        // If sitter_id exists, show the confirmation dialog

        swal.fire({
          title: 'Do you want to change sitter or continue with the same sitter?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Change',
          cancelButtonText: 'No Change'
        }).then((result) => {
          if (result.isConfirmed) {
            // If user confirms, approve the request
            const payloadWithChangeSitter = {
              session_id: sessionRescheduleId,
              is_change_sitter: true,
            };
            rescheduleBookingHistoryChangeSitter(payloadWithChangeSitter).then((res) => {
              if (res.data.status) {
                // If request is approved, navigate and update counts
                navigate('/dashboard/groomingAllocationPage');
                handleRefresh((preval) => !preval);
                updatecount();
              }
            });
          } else if (result.dismiss === "cancel") {
            // If user cancels, perform some action (optional)
            const payloadWithChangeSitter = {
              session_id: sessionRescheduleId,
              is_change_sitter: false,
            };
            rescheduleBookingHistoryChangeSitter(payloadWithChangeSitter).then((res) => {
              if (res.data.status) {
                // swal.fire({
                //   title: res.data.message,
                //   icon: 'success',
                //   timer: 2000,
                //   showConfirmButton: false
                // });
                navigate('/dashboard/groomingBookingUpcoming');
                handleRefresh((preval) => !preval);
                updatecount();
              }
            });
          }
        });
      } else {
        // If sitter_id doesn't exist, directly make the API call
        const payloadWithChangeSitter = {
          session_id: sessionRescheduleId,
          is_change_sitter: true,
        };
        return rescheduleBookingHistoryChangeSitter(payloadWithChangeSitter).then((res) => {
          if (res.data.status) {
            // navigate('/dashboard/groomingSitterAllocation');
            navigate('/dashboard/groomingAllocationPage');
            handleRefresh((preval) => !preval);
            updatecount();
          } else {
            swal.fire({
              title: res.data.message,
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
          }
        });
      }
    });


  }



  return ( 
    <>
     {show ? rescheduleShow ? <RescheduleReqActionBookingDetails setShow={setShow} /> :<CustomerOrderDetails setShow={setShow} /> 
     : (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Grooming Reschedule History
          </Typography>
          <BackButton />
        </Stack>
        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={6} >
            <TextField fullWidth label="Search" id="fullWidth"
              InputProps={{
                style: { margin: '6px 2px', height: '34px' },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer' }}
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: { fontSize: '16px', marginTop: '-4px' },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              onKeyDown={handleKeyPress}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={2} style={{ alignContent: 'center', paddingTop: '5px' }}>
            <Select
              style={{ margin: '0px 0px  0px 2px', height: '34px' }}
              labelId="allocation-label"
              id="allocation"
              value={selectedbooking}
              onChange={handleChangeDate}
              defaultValue=""
              displayEmpty
            // inputProps={{ 'aria-label': 'Select' }}
            >
              <MenuItem value="">
                All
              </MenuItem>
              <MenuItem value="approved" selected>
                Approved
              </MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* <RangePicker
                onChange={datefilterhandleChange}
              /> */}
            <RangePicker
                defaultValue={[defaultStartDate, defaultEndDate]}
                open={popupOpen}
                onOpenChange={(open) => {
                  if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                    setPopupOpen(true);
                  } else {
                    setPopupOpen(open);
                  }
                }}
                onChange={datefilterhandleChange}
                renderExtraFooter={() => (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                    <Button onClick={() => { setPopupOpen(false); reschedule(); }} variant='contained' size='small' >Submit</Button>
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
        <Card>
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                  )}
                </Typography>
              ) : (
                <Table size='small'>
                  <GroomingReschduleListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {

                      const {
                        _id,
                        user_name,
                        pet_name,
                        pet_type,
                        service_name,
                        date_of_booking,
                        start_date,
                        time_slot,
                        payment_method,
                        amount,
                        price_paid,
                        add_ons,
                        package_name,
                        user_contact_no,
                        user_location,
                        sitter_name,
                        sitter_contact,
                        number_of_sessions,
                        offer_description,
                        offer_count,
                        offer_remaining,
                        cancellation_request_date,
                        reschedule_status,
                        request_for_rescheduled,
                        reschedule_time_slot,
                        pay_on_service,
                        reschedule_date,
                        booking_ref_id,
                        is_admin_reschedule,
                        service_master_id,
                        session_ref_id,
                        user_id,
                        service_booking_id,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id , _id)}>
                              {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}
                            </Link>
                          </TableCell> */}
                           <TableCell sx={{ textAlign: 'center' }} onClick={() => handleReferenceID(booking_ref_id, _id , reschedule_status , request_for_rescheduled)} >
                              {/* <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}> */}
                                {/* {booking_ref_id} */}
                               <span className='booking-ref-ids'> {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}</span>
                              {/* </Link> */}
                            </TableCell>
                          <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                            <Typography variant="subtitle2" noWrap>
                              {user_name}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{pet_name.join(' , ')}</TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{pet_type.join(' , ')}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{service_name}</TableCell> */}
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Typography>{moment(date_of_booking).format('DD-MM-YYYY')}</Typography>
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Typography>{reschedule_status === "rejected" ? moment(reschedule_date).format('DD-MM-YYYY') : moment(start_date).format('DD-MM-YYYY')}</Typography>
                          </TableCell>
                          {/* <TableCell align="left">
                            <Typography>{reschedule_time_slot}</Typography>
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Typography>{reschedule_status === "rejected" ? reschedule_time_slot : time_slot}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Typography>{reschedule_status === "rejected" ? moment(start_date).format('DD-MM-YYYY') : moment(reschedule_date).format('DD-MM-YYYY')}</Typography>
                          </TableCell>

                          <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_status === "rejected" ? time_slot : reschedule_time_slot}</TableCell>
                          {/* <TableCell align="center">{payment_method === '' ? 'Pay after service' : payment_method}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {/* {amount} */}
                            {price_paid}
                            </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {/* {payment_method === '' ? 'Not Paid' : 'Paid'} */}
                            {payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}
                          </TableCell>
                          {/* <TableCell component="th" scope="row" padding="none" sx={{ textAlign: 'center' }}>
                            {add_ons.map((d, i) => (
                              <Typography variant="subtitle2" wordWrap>
                                {d.title}
                              </Typography>
                            ))}
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{package_name}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{user_contact_no}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${user_location?.coordinates[0]},${user_location?.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_name}</TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_contact}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions}</TableCell>
                          <TableCell sx={{ textAlign: 'center', maxWidth: '100px', maxHeight: '50px', wordWrap: 'break-word' }}>
                            {number_of_sessions === 1 ? (
                              "-"
                            ) : (
                              <Tooltip title={offer_description} arrow>
                                <span>
                                  {offer_description.length > 20 ? offer_description.slice(0, 20) + "..." : offer_description}
                                </span>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions > 1 ? offer_count : '-'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions > 1 ? offer_remaining : '-'}</TableCell> */}
                          {/* <TableCell align="left">
                            <Typography>{cancellation_request_date ? moment(cancellation_request_date).format('DD-MM-YYYY') : cancellation_request_date}</Typography>
                          </TableCell> */}
                          {/* <TableCell align="left" sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{reschedule_status}</TableCell> */}
                          <TableCell align="left" sx={{ textAlign: 'center', position: 'sticky', right: '5px', overflowX: 'hidden', overflowY: 'hidden' }}><Button variant={'contained'} size='small' sx={{ display: `${reschedule_status === 'rejected' ? 'flex' : 'none'}` }} onClick={() => { handleOpenModal(_id, row); sitterallocationpage(row, start_date, service_master_id); handleOpenCancelModal(_id, user_id, service_booking_id) }} disabled={is_admin_reschedule} >Reschedule</Button></TableCell>


                        </TableRow>
                      );

                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}

                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
            component="div"
            // count={data.number_of_records}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Modal for cancel confirmation */}

      {/* <Modal open={isCancelModalOpen} onClose={handleCloseCancelModal} className='modalDatePicker'>
        <div style={{ ...style, padding: '20px' }}>
          <Typography variant="h6">Do you want to cancel the session?</Typography>
          <div style={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" style={{ marginRight: '10px' }} onClick={() => handleCancelResponse('Yes')}>Yes</Button>
            <Button variant="contained" color="secondary" onClick={() => handleCancelResponse('No')}>No</Button>
          </div>
        </div>
      </Modal> */}

{/* Modal for cancel reason  */}
{/* <Modal open={isCancelreasonModalOpen} onClose={handleCloseCancelreasonModal}>
        <div style={{ ...style, padding: '20px' }}>
        <Typography variant="h6">Reason for cancellation</Typography>
          <TextField
            label="Enter reason"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            style={{ marginTop: '20px' }}
          />
          <div style={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" onClick={handleCancelConfirmation}>Confirm</Button>
            <Button variant="contained" color="secondary" onClick={handleCloseCancelReasonModal}>Cancel</Button>
          </div>
        </div>
      </Modal> */}

      <Modal open={isCancelModalOpen} onClose={handleCloseCancelModal} className='modalDatePicker'>
        <div style={style} >
          <div className='datePicker'>
          <Typography variant="h6">Reschedule Date & Time </Typography>
            <span><DatePicker onChange={handleRescheduleDate} allowClear /> </span>
            {/* <span><TimePicker onChange={handleRescheduleTime}/> </span>   */}
            {/* <span><TimePicker.RangePicker use12Hours format="h:mm a" onChange={handleRescheduleTime}/></span> */}
        
            <Select defaultValue="Select time"
              onChange={handleTimeSlotChange}
              
              MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
              style={{ height: 35 }}
            >
              <MenuItem value="Select time" disabled>
                Select time slot
              </MenuItem>
              {timeSlots.map((timeslot) => (
                timeslot.time_slot.map((timeRange) => (
                  <MenuItem
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                    key={timeRange.slot}
                    value={timeRange.slot}
                  >
                    {timeRange.slot}
                  </MenuItem>
                ))
              ))}
            </Select>

            {/* rescheduleDate selectedTimeSlot */}

            <Typography variant="h6">OR </Typography>
            <Typography variant="h6">Do you want to cancel the session</Typography>
          </div>
          <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",marginBottom:"1.5rem" }}>
            <Button disabled={rescheduleDate !=null  &&  selectedTimeSlot !=null?false:true } variant="contained" color="secondary" onClick={() => handleSubmit(rowDatas)} >No</Button>
            <Button variant="contained" color="primary" style={{ marginRight: '10px' }} onClick={() => handleCancelResponse('Yes')}>Yes</Button>
          </div>
          {/* <Button variant={'contained'} >Submit</Button> */}
        </div>
      </Modal>
    </Page>
     )}
    </>



  );
}