/* eslint-disable camelcase */

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@material-ui/core/Tooltip';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@mui/material/Box';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';

import { CouponsListHead, CouponsListToolbar, CouponsMoreMenu } from '../../sections/@dashboard/coupons';
// mock
import { couponStatusUpdateById, deleteNotificationById, getCouponsData, getNotificationList, sendnotification } from '../../common/api-service';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'send_to', label: 'Send To', alignRight: false },
//   { id: 'is_active', label: 'Is Active', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export default function NotificationList() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idget, setIdget] = useState('');


  const NotificationList = () => {
    setLoading(true);
    return getNotificationList().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    NotificationList();
    
  }, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const navigate = useNavigate();
  const editpage = (_id) => {
    const masterid = _id;
    localStorage.setItem('setMasterId', masterid);
    navigate('/dashboard/editnotification')
  }

  const Sendnotification = (_id) => {
    const masterid = _id;
    const payload = {
      _id: masterid    
    };
    return sendnotification(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.data,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          // navigate('/dashboard/notificationlist');
        } else {
          swal({
            title: res.data.data,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };
    
  
  

  const deletenotification = (id) =>{

    swal({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete the notification template?',
      icon: 'warning',
      buttons: ['Cancel', 'OK'], 
      // showCancelButton: false,
      // confirmButtonColor: '#d33',
      // cancelButtonColor: '#3085d6',
      // confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      
      // If the user clicks "Yes"
      if (result === true) {
        const payload = {
          _id: id,
        };
        return deleteNotificationById(payload).then((res) => {
          if (res.data.status === true) {
            const message = res.data.message;
            swal({
              title: message,
              icon: 'success',
              timer: 2000,
              // showConfirmButton: false,
              button: false,
            }).then(() => {
              // Reload the page after the swal is closed
              // window.location.reload();
              NotificationList();
            });;
            
          }})
      
      }
    });
    
  }

//   const handleToggle = (id, isactive) => {
//     // console.log('id',id);
//     // console.log('isactive',isactive);
//     setLoading(true);
//     // setIdget(id);
//     // const isActiveStatus = isactive; 
//     // setSwitchState(isActiveStatus);

    
//     const payload = {
//       _id: id,
//       is_active: !isactive,
      
//     };

//     console.log(payload);
//     return couponStatusUpdateById(payload).then(
//       (res) => {
//         if (res.data.status === true) {
//           setLoading(false);
//           setFilteredData((prevData) =>
//             prevData.map((row) =>
//               row._id === id ? { ...row, is_active: !isactive } : row
//             )
//           );

//           swal({
//             title: res.data.message,
//             icon: 'success',
//             timer: 2000,
//             button: false,
//           });
//           navigate('/dashboard/coupons');
//         } else {
//           setLoading(false);
//           swal({
//             title: res.data.message,
//             icon: 'danger',
//             timer: 2000,
//             button: false,
//           });
          
//         }
        
//       },
//       (err) => {
//         console.log('err', err);
//       }
//     );
//   };


  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notification List
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/notification"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Notification
          </Button>
        </Stack>

        <Card>
          {/* <CouponsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
            {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                      <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                    ) : (
                      <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                    )}
                </Typography>
              ) : (
              <Table>
                <CouponsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredData.map((row) => {
                    const {
                      _id,
                      title,
                      icon,
                      notification_for,
                      description,
                      
                    } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, coupon_name)} />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ textAlign: 'center'}}
                        >
                            <Typography variant="subtitle2" wrap  >
                              {title}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ textAlign: 'center' }}>
                          {description}
                        </TableCell>
                        <TableCell  sx={{ textAlign: 'center' }}>
                        <Avatar alt="Image" src={icon} sx={{ width: 70, height: 70, borderRadius: 0, margin: 'auto' }} />
                        </TableCell>
                        <TableCell align="left" sx={{ textAlign: 'center' }}>
                          {notification_for}
                        </TableCell>
                        <TableCell align="left" sx={{ textAlign: 'center' }}>
                        <Tooltip title="Send">
                        <IconButton color="success" onClick={() => Sendnotification(_id)}>
                            <SendIcon />
                          </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                          <IconButton color="primary" onClick={() => editpage(_id)}>
                            <EditIcon />
                          </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                          <IconButton color="error" onClick={() =>deletenotification(_id)}>
                            <DeleteIcon />
                          </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
