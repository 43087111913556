import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import Badge from '@mui/material/Badge';
//
import { getSessionCount } from '../common/api-service';
import Iconify from './Iconify';

import { ThemeContext } from '../App';


// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();


  const { sitterAllocationCount, setSitterAllocationCount } = useContext(ThemeContext);
  const { cancellationRequestCount, setCancellationRequestCount } = useContext(ThemeContext);
  const { rescheduleRequestCount, setRescheduleRequestCount } = useContext(ThemeContext);

  const { petBoardingCount, setPetBoardingCount } = useContext(ThemeContext);
  const { dogWalkingCount, setDogWalkingCount } = useContext(ThemeContext);
  const { petSittingCount, setPetSittingCount } = useContext(ThemeContext);
  const { dogTrainingCount, setDogTrainingCount } = useContext(ThemeContext);
  const { otherCount, setOtherCount } = useContext(ThemeContext);

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, subchildren } = item;



  const [open, setOpen] = useState(isActiveRoot);




  const handleOpen = () => {
    setOpen((prev) => !prev);
    if (title === "Pet Grooming") {
      return getSessionCount().then(
        (res) => {
          const [requestedSessionsCount, rescheduledRequestsCount, cancellationRequestsCount,
            petboardingcount, dogwalkingcount, petsittingcount, dogtrainingcount,
            othercount] = res.data.data;
          setSitterAllocationCount(requestedSessionsCount.requested_sessions_count);
          setRescheduleRequestCount(rescheduledRequestsCount.rescheduled_requests_count);
          setCancellationRequestCount(cancellationRequestsCount.cancellation_requests_count);
          setPetBoardingCount(petboardingcount.pet_boarding_count);
          setDogWalkingCount(dogwalkingcount.dog_walking_count);
          setPetSittingCount(petsittingcount.pet_sitting_count);
          setDogTrainingCount(dogtrainingcount.dog_training_count);
          setOtherCount(othercount.others_count);
        },
        (err) => {
          console.log('err data: ', err);
        }
      );
    }
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  if (children || subchildren) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        {/* Render children if present */}
        {children && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((child) => (

                <NavItem key={child.title} item={child} active={active} />
              ))}
            </List>
          </Collapse>
        )}

        {/* Render subchildren if present */}
        {subchildren && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subchildren.map((subchild) => (
                <ListItemStyle
                  key={subchild.title}
                  component={RouterLink}
                  to={subchild.path}
                  sx={{ ...(active(subchild.path) && activeSubStyle) }}
                >
                  <ListItemIconStyle />
                  <ListItemIconStyle sx={{marginLeft:'-25px'}}>{subchild.icon && subchild.icon}</ListItemIconStyle>
                  {/* <ListItemText disableTypography  primary={subchild.title} /> */}
                  <ListItemText
                    disableTypography
                    primary={
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        {subchild.title === 'Reschedule Request' ? (
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText disableTypography primary={subchild.title} style={{ marginRight: '20px' }} />
                            {rescheduleRequestCount ? (
                              <Badge badgeContent={rescheduleRequestCount} color="primary" />) : ('')}
                            {/* <Badge badgeContent={"3"} color="primary" /> */}
                          </div>
                        ) : (
                          ''
                        )}


                        {/* {subchild.title === "Cancellation Requests" ? (

                          <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <ListItemText disableTypography primary={subchild.title} style={{ marginRight: '20px' }} />
                              {cancellationRequestCount ? (
                                <Badge badgeContent={cancellationRequestCount} color="primary" />) : ('')}
                              
                            </div>
                          </>
                        ) : (
                          ''
                        )} */}

                        {subchild.title !== 'Reschedule Request' && subchild.title !== 'Sitter Allocations' &&
                          subchild.title !== 'Cancellation Requests' && subchild.title !== 'Pet Boarding' &&
                          subchild.title !== 'Dog Walking' && subchild.title !== 'Pet Sitting' && subchild.title !== 'Dog Training'
                          && subchild.title !== 'Others'
                          ? (
                            <ListItemText disableTypography primary={subchild.title} />
                          ) : (
                            ''
                          )}
                      </div>
                    }
                  />
                </ListItemStyle>
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

      {title === 'Sitter Allocations' ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {sitterAllocationCount ? (
              <Badge badgeContent={sitterAllocationCount} color="primary" />) : ('')}

          </div>
        </>
      ) : (
        ''
      )}

      {title === "Cancellation Requests" ? (

        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {cancellationRequestCount ? (
              <Badge badgeContent={cancellationRequestCount} color="primary" />) : ('')}

          </div>
        </>
      ) : (
        ''
      )}

      {/* pet boarding */}

      {title === "Pet Boarding" ? (

        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {petBoardingCount ? (
              <Badge badgeContent={petBoardingCount} color="primary" />) : ('')}

            {/* {!isActiveSub && <Badge badgeContent={"2"} color="primary" />} */}
          </div>
        </>
      ) : (
        ''
      )}

      {/* dog walking */}

      {title === "Dog Walking" ? (

        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {dogWalkingCount ? (
              <Badge badgeContent={dogWalkingCount} color="primary" />) : ('')}

            {/* {!isActiveSub && <Badge badgeContent={"2"} color="primary" />} */}
          </div>
        </>
      ) : (
        ''
      )}

      {/* pet setting */}

      {title === "Pet Sitting" ? (

        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {petSittingCount ? (
              <Badge badgeContent={petSittingCount} color="primary" />) : ('')}

            {/* {!isActiveSub && <Badge badgeContent={"2"} color="primary" />} */}
          </div>
        </>
      ) : (
        ''
      )}

      {/* dog training */}

      {title === "Dog Training" ? (

        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {dogTrainingCount ? (
              <Badge badgeContent={dogTrainingCount} color="primary" />) : ('')}

            {/* {!isActiveSub && <Badge badgeContent={"2"} color="primary" />} */}
          </div>
        </>
      ) : (
        ''
      )}

      {/* other count */}

      {title === "Others" ? (

        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText disableTypography primary={title} style={{ marginRight: '20px' }} />
            {otherCount ? (
              <Badge badgeContent={otherCount} color="primary" />) : ('')}

            {/* {!isActiveSub && <Badge badgeContent={"2"} color="primary" />} */}
          </div>
        </>
      ) : (
        ''
      )}

      {title !== 'Reschedule Request' && title !== 'Sitter Allocations' &&
        title !== 'Cancellation Requests' && title !== 'Pet Boarding' &&
        title !== 'Dog Walking' && title !== 'Pet Sitting' && title !== 'Dog Training'
        && title !== 'Others'
        ? (
          <ListItemText disableTypography primary={title} />
        ) : (
          ''
        )}


      {info && info}

    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
