/* eslint-disable camelcase */
/* eslint-disable */
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Box from '@mui/material/Box';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { BulkUploadListHead, TopPetCareMoreMenu } from '../sections/@dashboard/bulkUpload';
// mock
import { getBulkUploadData } from '../common/api-service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'pets_name', label: 'Pet Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'sub_area', label: 'Area', alignRight: false },
  { id: 'live_in', label: 'Live In', alignRight: false },
  { id: 'service_master', label: 'Service Name', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
export default function TopPetCare() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const topPetCare = () => {
    return getBulkUploadData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    topPetCare();
  }, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Tap Pet Care
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <BulkUploadListHead
                    order={order}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        _id,
                        name,
                        email,
                        pets_name,
                        phone,
                        gender,
                        sub_area,
                        live_in,
                        service_master,
                        is_top_pet_carers,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                              <Typography  wordWrap>
                                {name}
                              </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                              <Typography  noWrap>
                                {email}
                              </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {pets_name}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {phone}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {gender}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {sub_area}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {live_in}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {service_master?.map((a) => (
                              <Typography sx={{ fontSize: '10pt' }}>{a.title}</Typography>
                            ))}
                          </TableCell>

                          <TableCell align="right" sx={{ textAlign: 'center' }}>
                            <TopPetCareMoreMenu
                              data={_id}
                              currentStatus={is_top_pet_carers}
                              handleRefresh={setOffset}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
