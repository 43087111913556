import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import { addPetVaccines } from 'src/common/api-service';
import BackButton from 'src/components/BackButton';
import { useNavigate } from 'react-router-dom';

const petTypes = [
  { value: 'dog', label: 'Dog' },
  { value: 'cat', label: 'Cat' },
];

const AddPetVaccine = () => {
  const [vaccineName, setVaccineName] = useState('');
  const [selectedPetTypes, setSelectedPetTypes] = useState([]);

  const navigate = useNavigate();


  const handleVaccineNameChange = (event) => {
    setVaccineName(event.target.value);
  };

  const handlePetTypeChange = (event) => {
    setSelectedPetTypes(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    // Handle form submission here
    const payload = {
      vaccine: vaccineName,
      pet_type: selectedPetTypes,
    };
    return addPetVaccines(payload).then(
      (res) => {
        console.log('res :', res);
        navigate('/dashboard/vaccineList')

      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  return (
    <div>
        <BackButton/>
      <form onSubmit={handleSubmit} style={{ padding: '30px' }}>
        <h2>Add Pet Vaccine</h2>
        <TextField
          id="vaccine-name"
          label="Vaccine Name"
          variant="outlined"
          value={vaccineName}
          onChange={handleVaccineNameChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="pet-type-label">Pet Type(s)</InputLabel>
          <Select
            labelId="pet-type-label"
            id="pet-type"
            multiple
            value={selectedPetTypes}
            onChange={handlePetTypeChange}
            label="Pet Type(s)"
          >
            {petTypes.map((petType) => (
              <MenuItem key={petType.value} value={petType.value}>
                {petType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddPetVaccine;
