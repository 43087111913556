import React, { useState } from 'react';
import { Card, Stack, Container, Typography, Button } from '@mui/material';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GoogleMap, Polygon, DrawingManager } from '@react-google-maps/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addFences } from 'src/common/api-service';
import { useLocation } from 'react-router-dom';
const AddNewPolygon = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [polygons, setPolygons] = useState([]);
  const [cityName, setCityName] = useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const city = queryParams.get('city');
  console.log(city);

  const handlePolygonComplete = async (polygon) => {
    const paths = polygon.getPath().getArray();
    const polygonCoordinates = paths.map((path) => ({
      lat: path.lat(),
      lng: path.lng(),
    }));

    setPolygons(polygonCoordinates);

    // const center = getPolygonCenter(polygonCoordinates);
    // const city = await reverseGeocode(center);
    // setCityName(city);

    console.log('polygonCoordinates :', polygonCoordinates);
    addFenceData(polygonCoordinates, city);
  };

  const addFenceData = (polygonCoordinates, city) => {
    setLoading(true);
    const payload = {
      // "name": "Rajarampuri",
      name: city,
      polygon: polygonCoordinates,
    };
    return addFences(payload).then(
      (res) => {
        console.log('res :', res);
        setLoading(false);
        toast.success("GeoFence added successfully!");
        setTimeout(() => {
            navigate("/dashboard/geoFenceArea");
        }, 500);
    },
    (err) => {
        setLoading(false);
        toast.success("Failed to GeoFence!");
        console.log('err data: ', err);
      }
    );
  };

  const getPolygonCenter = (polygonCoords) => {
    const latSum = polygonCoords.reduce((acc, curr) => acc + curr.lat, 0);
    const lngSum = polygonCoords.reduce((acc, curr) => acc + curr.lng, 0);
    const center = {
      lat: latSum / polygonCoords.length,
      lng: lngSum / polygonCoords.length,
    };
    return center;
  };

  const reverseGeocode = async (location) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyBtNweJE_bDxUtdLyBbfDsJB7P7ap3OsCQ`
    );
    const data = await response.json();
    const city = data.results[0].address_components.find((component) => component.types.includes('locality'));
    return city ? city.long_name : '';
  };

  return (
    <>
      <Page title="User">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Geo Fence
            </Typography>
          </Stack>

          <Card sx={{ padding: '1rem' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h5" ml={3} gutterBottom>
                Add New Polygon for {city}
              </Typography>
              <Stack direction="row" gap={3}>
                <Button size="small" variant="contained" onClick={() => navigate(-1)}>
                  All Polygons
                </Button>
              </Stack>
            </Stack>

            {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
            <div style={{ height: '600px', width: '100%' }}>
              <GoogleMap
                center={{ lat: 16.705, lng: 74.2433 }}
                zoom={12}
                mapContainerStyle={{ height: '100%', width: '100%' }}
              >
                {polygons?.map((polygon, index) => (
                  <Polygon key={index} paths={polygon} onClick={() => handlePolygonClick(polygon)} />
                ))}
                <DrawingManager
                  onLoad={(drawingManager) => {
                    drawingManager.setOptions({
                      drawingMode: 'polygon',
                      drawingControl: true,
                      drawingControlOptions: {
                        drawingModes: ['polygon'],
                      },
                    });
                  }}
                  onPolygonComplete={handlePolygonComplete}
                />
              </GoogleMap>
            </div>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AddNewPolygon;
