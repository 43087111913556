import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="150.646" height="28.553" viewBox="0 0 150.646 28.553">
        <g id="pawspace_logo" transform="translate(16198.242 23520.563)">
          <g id="Group_37008" data-name="Group 37008" transform="translate(-16164.775 -23512.646)">
            <path
              id="Path_401"
              data-name="Path 401"
              d="M3478.982,631.808V616.077a7.706,7.706,0,0,1,.039-.916,2.271,2.271,0,0,1,.222-.673q.363-.726,1.743-.727a3.305,3.305,0,0,1,1.86.364,1.47,1.47,0,0,1,.56,1.064,3.546,3.546,0,0,1,3.11-1.4,5.471,5.471,0,0,1,4.021,2.074,7.088,7.088,0,0,1,.013,9.644,5.447,5.447,0,0,1-4.021,2.074,3.688,3.688,0,0,1-3.1-1.239v5.442a7.863,7.863,0,0,1-.039.916,2.229,2.229,0,0,1-.231.687,1.1,1.1,0,0,1-.662.553,4.041,4.041,0,0,1-1.276.162,4.17,4.17,0,0,1-1.288-.162,1.122,1.122,0,0,1-.677-.536,2.092,2.092,0,0,1-.234-.674A7.746,7.746,0,0,1,3478.982,631.808Zm5.153-12.687a2.452,2.452,0,0,0-.6,1.589,2.366,2.366,0,0,0,.611,1.576,1.968,1.968,0,0,0,1.562.715,2.141,2.141,0,0,0,1.615-.7,2.249,2.249,0,0,0,.663-1.59,2.369,2.369,0,0,0-.625-1.6,2.057,2.057,0,0,0-1.627-.715,1.968,1.968,0,0,0-1.6.731Z"
              transform="translate(-3478.981 -613.464)"
              fill="#ffaf00"
            />
            <path
              id="Path_402"
              data-name="Path 402"
              d="M3727.081,615.268q.156-1.508,2.056-1.508a5.229,5.229,0,0,1,1.509.161,1.1,1.1,0,0,1,.69.566,2.37,2.37,0,0,1,.234.715,8.2,8.2,0,0,1,.039.929v9.427a8.211,8.211,0,0,1-.039.93,2.586,2.586,0,0,1-.221.715q-.365.7-1.783.7a3.416,3.416,0,0,1-1.9-.364,1.468,1.468,0,0,1-.559-1.064,3.582,3.582,0,0,1-3.214,1.427,5.619,5.619,0,0,1-4.177-2.128,7.272,7.272,0,0,1,.013-9.872,5.714,5.714,0,0,1,4.255-2.115,3.939,3.939,0,0,1,1.613.323,3.539,3.539,0,0,1,1.014.606A3.769,3.769,0,0,1,3727.081,615.268Zm-4.736,5.6a2.276,2.276,0,0,0,.676,1.6,2.176,2.176,0,0,0,1.639.715,2.038,2.038,0,0,0,1.6-.727,2.379,2.379,0,0,0,.638-1.6,2.539,2.539,0,0,0-.612-1.63,2,2,0,0,0-1.639-.755,2.077,2.077,0,0,0-1.666.755,2.483,2.483,0,0,0-.637,1.642Z"
              transform="translate(-3703.556 -613.464)"
              fill="#ffaf00"
            />
            <path
              id="Path_403"
              data-name="Path 403"
              d="M3983.039,611.3q1.668.753,1.666,1.859a4.488,4.488,0,0,1-.418,1.468q-.417,1.01-.7,1.766l-2.861,7.22a2.759,2.759,0,0,1-1.3,1.669,2.274,2.274,0,0,1-.99.189,1.779,1.779,0,0,1-1.158-.471,2.407,2.407,0,0,1-.741-.954l-.209-.458q-.207-.536-1.535-4.2-.261.647-.769,2.115t-.65,1.832c-.1.242-.156.4-.182.485a3.132,3.132,0,0,1-.221.444,2.752,2.752,0,0,1-.417.566,1.938,1.938,0,0,1-1.431.647,2,2,0,0,1-1.353-.472,2.444,2.444,0,0,1-.729-.954l-3.933-9.921a4.654,4.654,0,0,1-.259-1.051q0-1.076,1.483-1.7a3.737,3.737,0,0,1,1.353-.35,1.438,1.438,0,0,1,.846.229,1.5,1.5,0,0,1,.455.458,2.518,2.518,0,0,1,.208.471q.442,1.078,1.848,5.066l1.536-4.364a2.114,2.114,0,0,1,.572-.943,2.167,2.167,0,0,1,1.535-.781,2.127,2.127,0,0,1,1.341.432,2.547,2.547,0,0,1,.767.862l.208.458q1.3,3.774,1.535,4.31l1.588-4.47q.623-1.724,1.873-1.724A2.36,2.36,0,0,1,3983.039,611.3Z"
              transform="translate(-3935.89 -610.896)"
              fill="#ffaf00"
            />
            <path
              id="Path_404"
              data-name="Path 404"
              d="M4302.764,622.44a1.13,1.13,0,0,1-.494-1.023,4.51,4.51,0,0,1,.989-2.128,1.007,1.007,0,0,1,.924-.458,3.7,3.7,0,0,1,1.728.66,3.917,3.917,0,0,0,1.979.66q1.587,0,1.587-.647,0-.511-1.744-.755a8.258,8.258,0,0,1-3.253-1.266,3.564,3.564,0,0,1-1.249-1.522,5.694,5.694,0,0,1-.495-2.465q0-4.256,5.647-4.256a8.921,8.921,0,0,1,3.8.916q.912.431.911,1.078a3.252,3.252,0,0,1-.573,1.59q-.573.942-1.171.943a3.455,3.455,0,0,1-1.158-.4,4.2,4.2,0,0,0-1.834-.4q-1.534,0-1.536.592,0,.808,1.77,1.051a9.445,9.445,0,0,1,3.331,1.023,3,3,0,0,1,1.262,1.307,4.828,4.828,0,0,1,.508,2.33,5.153,5.153,0,0,1-.508,2.384,3.361,3.361,0,0,1-1.417,1.469,9.287,9.287,0,0,1-4.176.834A8.724,8.724,0,0,1,4302.764,622.44Z"
              transform="translate(-4253.198 -609.213)"
              fill="#ffaf00"
            />
            <path
              id="Path_405"
              data-name="Path 405"
              d="M4513.521,631.808V616.077a8.084,8.084,0,0,1,.039-.916,2.279,2.279,0,0,1,.222-.673q.363-.726,1.744-.727a3.3,3.3,0,0,1,1.86.364,1.471,1.471,0,0,1,.561,1.064,3.544,3.544,0,0,1,3.109-1.4,5.471,5.471,0,0,1,4.021,2.074,7.087,7.087,0,0,1,.013,9.644,5.438,5.438,0,0,1-4.021,2.074,3.685,3.685,0,0,1-3.1-1.239v5.442a7.654,7.654,0,0,1-.038.916,2.238,2.238,0,0,1-.235.687,1.1,1.1,0,0,1-.664.553,4.046,4.046,0,0,1-1.272.163,4.166,4.166,0,0,1-1.289-.161,1.123,1.123,0,0,1-.677-.536,2.129,2.129,0,0,1-.234-.674A8.034,8.034,0,0,1,4513.521,631.808Zm5.154-12.687a2.452,2.452,0,0,0-.6,1.589,2.368,2.368,0,0,0,.611,1.576,1.969,1.969,0,0,0,1.562.715,2.136,2.136,0,0,0,1.61-.7,2.247,2.247,0,0,0,.664-1.59,2.367,2.367,0,0,0-.625-1.6,2.057,2.057,0,0,0-1.626-.715A1.961,1.961,0,0,0,4518.675,619.121Z"
              transform="translate(-4451.856 -613.464)"
              fill="#ffaf00"
            />
            <path
              id="Path_406"
              data-name="Path 406"
              d="M4765.652,615.268q.157-1.508,2.056-1.508a5.227,5.227,0,0,1,1.509.161,1.094,1.094,0,0,1,.69.566,2.37,2.37,0,0,1,.234.715,8.2,8.2,0,0,1,.039.929v9.427a8.21,8.21,0,0,1-.039.93,2.6,2.6,0,0,1-.22.715q-.365.7-1.784.7a3.411,3.411,0,0,1-1.9-.364,1.462,1.462,0,0,1-.559-1.064,3.584,3.584,0,0,1-3.215,1.427,5.62,5.62,0,0,1-4.176-2.128,7.27,7.27,0,0,1,.013-9.872,5.715,5.715,0,0,1,4.255-2.115,3.937,3.937,0,0,1,1.613.323,3.539,3.539,0,0,1,1.014.606A3.786,3.786,0,0,1,4765.652,615.268Zm-4.737,5.6a2.278,2.278,0,0,0,.677,1.6,2.178,2.178,0,0,0,1.64.715,2.04,2.04,0,0,0,1.6-.727,2.379,2.379,0,0,0,.636-1.6,2.529,2.529,0,0,0-.61-1.63,2,2,0,0,0-1.64-.755,2.077,2.077,0,0,0-1.666.755,2.49,2.49,0,0,0-.638,1.642Z"
              transform="translate(-4680.222 -613.464)"
              fill="#ffaf00"
            />
            <path
              id="Path_407"
              data-name="Path 407"
              d="M5012.466,618.31a2.231,2.231,0,0,0,.728,1.777,2.331,2.331,0,0,0,1.55.633,2.638,2.638,0,0,0,1.536-.5,2.246,2.246,0,0,1,1.158-.5q.781,0,1.9,1.428a2.412,2.412,0,0,1,.6,1.4q0,1.025-1.43,1.913a6.029,6.029,0,0,1-3.644,1.078,7.339,7.339,0,0,1-4.658-1.8,6.374,6.374,0,0,1-1.744-2.3,7.378,7.378,0,0,1-.677-3.179,7.2,7.2,0,0,1,.677-3.152,6.427,6.427,0,0,1,1.744-2.276,7.132,7.132,0,0,1,4.607-1.777,6.616,6.616,0,0,1,1.925.283,6.325,6.325,0,0,1,1.379.552l.417.269q.468.324.729.512a1.55,1.55,0,0,1,.676,1.226,2.975,2.975,0,0,1-.545,1.55q-.937,1.481-1.8,1.481a2.951,2.951,0,0,1-1.406-.592,1.843,1.843,0,0,0-1.341-.458,2.464,2.464,0,0,0-1.63.646A2.236,2.236,0,0,0,5012.466,618.31Z"
              transform="translate(-4916.657 -610.915)"
              fill="#ffaf00"
            />
            <path
              id="Path_408"
              data-name="Path 408"
              d="M5230.152,614.487a3.345,3.345,0,0,1-.793,2.452,2.443,2.443,0,0,1-1.757.795h-6.167a1.477,1.477,0,0,0,.859,1.266,3.336,3.336,0,0,0,1.718.512,6.926,6.926,0,0,0,2.368-.324l.287-.107a2.544,2.544,0,0,1,1.041-.3q.835,0,1.43,1.212a3.049,3.049,0,0,1,.338,1.239q0,2.4-5.543,2.4a8.118,8.118,0,0,1-3.422-.687,5.831,5.831,0,0,1-2.329-1.819,7.914,7.914,0,0,1-1.64-4.876,7.173,7.173,0,0,1,2.121-5.428,7.344,7.344,0,0,1,5.3-2.032,5.848,5.848,0,0,1,5.309,2.64A5.6,5.6,0,0,1,5230.152,614.487Zm-5.491.794q.989,0,.99-.862a1.232,1.232,0,0,0-.456-1,1.986,1.986,0,0,0-1.3-.377,2.577,2.577,0,0,0-1.679.715,2.026,2.026,0,0,0-.835,1.522Z"
              transform="translate(-5112.973 -608.79)"
              fill="#ffaf00"
            />
          </g>
          <g id="Group_37007" data-name="Group 37007" transform="translate(-16198.242 -23520.563)">
            <path
              id="Path_409"
              data-name="Path 409"
              d="M3015.336,670.363a5.2,5.2,0,0,1-3.481,5.214,3.207,3.207,0,0,1-2.936-.435c-1.039-.712-.817-2.219-.817-3.452,0-1.511.353-3.551-.823-4.727a3.2,3.2,0,0,0-5.287,1.532,24.563,24.563,0,0,0-.037,4.365c0,1.427.06,2-1.206,2.594-2.186,1.018-4.792-.7-5.653-2.812a5.612,5.612,0,0,1,1.347-6.071,18.854,18.854,0,0,0,2.765-2.942c.647-.954,1.021-2.066,1.634-3.044a4.89,4.89,0,0,1,5.885-2.132c2.553.964,3.081,4.069,4.612,6.03C3012.979,666.581,3015.156,667.428,3015.336,670.363Z"
              transform="translate(-2990.107 -647.29)"
              fill="#894aed"
            />
            <ellipse
              id="Ellipse_509"
              data-name="Ellipse 509"
              cx="4.811"
              cy="3.374"
              rx="4.811"
              ry="3.374"
              transform="matrix(0.105, -0.994, 0.994, 0.105, 15.398, 9.587)"
              fill="#894aed"
            />
            <ellipse
              id="Ellipse_510"
              data-name="Ellipse 510"
              cx="3.374"
              cy="4.811"
              rx="3.374"
              ry="4.811"
              transform="matrix(0.994, -0.105, 0.105, 0.994, 6.719, 0.71)"
              fill="#894aed"
            />
            <ellipse
              id="Ellipse_511"
              data-name="Ellipse 511"
              cx="4.779"
              cy="3.374"
              rx="4.779"
              ry="3.374"
              transform="matrix(0.217, -0.976, 0.976, 0.217, 21.186, 16.545)"
              fill="#894aed"
            />
            <ellipse
              id="Ellipse_512"
              data-name="Ellipse 512"
              cx="3.374"
              cy="4.779"
              rx="3.374"
              ry="4.779"
              transform="matrix(0.976, -0.217, 0.217, 0.976, 0, 8.665)"
              fill="#894aed"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/dashboard/serviceMaster">{logo}</RouterLink>;
}
