/* eslint-disable */
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import swal from 'sweetalert';
// component
import Iconify from '../../../components/Iconify';
import { TrainingReschduleApprove } from '../../../common/api-service';

// ----------------------------------------------------------------------
const TrainingReshduleMoreMenu = ({ data, handleRefresh, currentStatus }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(data);
  const handleApprove = () => {
    const payload = {
      booking_id: id,
      reschedule_status: 'approved',
    };
    return TrainingReschduleApprove(payload).then((res) => {
      if (res.data.status === true) {
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        handleRefresh((preval) => {
          return !preval;
        });
      } else {
        swal({
          title: res.data.message,
          icon: 'danger',
          timer: 2000,
          button: false,
        });
      }
    });
  };

  const handleReject = () => {
    const payload = {
      session_id: id,
      reschedule_status: 'rejected',
    };
    return TrainingReschduleApprove(payload).then((res) => {
      if (res.data.status === true) {
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        handleRefresh((preval) => {
          return !preval;
        });
      } else {
        swal({
          title: res.data.message,
          icon: 'danger',
          timer: 2000,
          button: false,
        });
      }
    });
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem disabled={currentStatus === 'approved' ? true : false} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon={'clarity:success-standard-solid'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={currentStatus === 'approved' ? 'Approved' : 'Approve'}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={handleApprove}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={currentStatus === 'rejected' ? 'Rejected' : 'Reject'}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={handleReject}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default TrainingReshduleMoreMenu;
