/* eslint-disable camelcase */
import { useState, useEffect } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';

import Collapse from '@mui/material/Collapse';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment
} from '@mui/material';
// components
import Page from '../../components/Page';
import SearchIcon from '@material-ui/icons/Search';

import Scrollbar from '../../components/Scrollbar';
import { PetListListHead } from '../../sections/@dashboard/petList';
// mock
import { getPetData, getPetListData } from '../../common/api-service';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'user_name', label: 'User Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'breed', label: 'Pet Breed', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

export default function PetList() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [collapsed, setCollapsed] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [open, setOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // const offer = () => {
  //   return getPetListData(searchQuery).then(
  //     (res) => {
  //       setData(res.data.data);
  //       setFilteredData(res.data.data);
  //     },
  //     (err) => {
  //       console.log('err data: ', err);
  //     }
  //   );
  // };

  const petData = () => {
    return getPetData(searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    // offer();
    petData();
  }, [offset , searchQuery]);

  const handlePetListSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCollapsed = (event, _id) => {
    const selectedIndex = collapsed.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(collapsed, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(collapsed.slice(1));
    } else if (selectedIndex === collapsed.length - 1) {
      newSelected = newSelected.concat(collapsed.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(collapsed.slice(0, selectedIndex), collapsed.slice(selectedIndex + 1));
    }
    setCollapsed(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePetDetailsById  = (_id) => {
    localStorage.setItem('petIdForPetDetail' , _id)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Pet List
          </Typography>

          <TextField
                label="Enter name/contact number"
                id="fullWidth"
                InputProps={{
                  style: { margin: '1px 18px', height: '34px', marginRight: '30px' },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <SearchIcon style={{ position: 'relative', left: '-5px' }} />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: '14px', marginTop: '-8px', marginLeft: '18px' },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              // onKeyDown={handleKeyPress}
              />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table size='small'>
                  <PetListListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onPetListSort={handlePetListSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { _id, name, user_name , pet_type , breed } = row;

                      const isItemCollapsed = collapsed.indexOf(_id) !== -1;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <>
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell  sx={{ textAlign: 'center' , fontWeight:'600' , cursor:'pointer'}} onClick={() => handlePetDetailsById(_id)}>
                              <Link style={{textDecoration:'none'}} to='/dashboard/petDetails'>
                                  {name}
                                  </Link>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{user_name}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{pet_type}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{breed}</TableCell>


                            {/* <TableCell sx={{ textAlign: 'center' }}>
                              Show Details
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(event) => {
                                  setOpen(!open);
                                  return handleCollapsed(event, _id);
                                }}
                              >
                                {isItemCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell> */}
                            {/* {pets.map((a) => (
                              <TableRow
                                hover
                                key={_id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              ></TableRow>
                            ))} */}
                          </TableRow>
                          {/* <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={isItemCollapsed} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  {pets?.length === 0 ? (
                                    <Typography variant="h6" gutterBottom component="div">
                                      No pets available
                                    </Typography>
                                  ) : (
                                    <>
                                      <Typography variant="h6" gutterBottom component="div">
                                        Pet Details
                                      </Typography>
                                      <Table size="small" aria-label="purchases">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Pet Name</TableCell>
                                            <TableCell>Pet Type</TableCell>
                                            <TableCell>Breed</TableCell>
                                            <TableCell>Gender</TableCell>
                                          </TableRow>
                                        </TableHead>

                                        {pets.map((pet) => {
                                          return (
                                            <TableRow>
                                              <TableCell>{pet.name}</TableCell>
                                              <TableCell>{pet.pet_type}</TableCell>
                                              <TableCell>{pet.breed}</TableCell>
                                              <TableCell>{pet.gender}</TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </Table>
                                    </>
                                  )}
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow> */}
                        </>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
