import React, { useEffect, useState } from 'react';
import {
  Typography,
  Chip,
  Container,
  Box,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  createTheme,
  ThemeProvider,
  Tooltip,
  Button,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MapIcon from '@mui/icons-material/Map';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getPetDetailsById } from 'src/common/api-service';
import BackButton from 'src/components/BackButton';
import RescheduleReqActionBookingDetails from '../PetGrooming/RescheduleReqActionBookingDetail';
import CustomerOrderDetails from '../PetGrooming/CustomerOrderDetails';


const UserManagementDetails = ({ rowDetails, setShowUserManagementDetails }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [show, setShow] = useState(false);
  const [rescheduleShow, setRescheduleShow] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleReferenceID = (booking_ref_id, _id, reschedule_status, request_for_rescheduled, session_status, session_ref_id) => {
    setShow(true);
    setRescheduleShow(session_status === 'cancelled' ? false : reschedule_status === 'pending' && request_for_rescheduled === true ? true : false)
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", _id);
    localStorage.setItem("s1", "Ongoing")
    localStorage.setItem("sessionRefId", session_ref_id);

  }



  return (
    <>
      {
        show ? (
          rescheduleShow ? <RescheduleReqActionBookingDetails setShow={setShow} /> : <CustomerOrderDetails setShow={setShow} />
        ) : (
          <div>
            <BackButton setShow={setShowUserManagementDetails} />
            <Typography sx={{ ml: '20px' }} variant="h4">Pet Details </Typography>

            {rowDetails?.pet_details.map((petDetail, key) => (
              <div key={key}>

                <Box mt={3}>

                  <Grid container >
                    <Grid item sm={6} xs={12} md={2} lg={2}>
                      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <img src={petDetail.profile_pic} height={100} width={100} style={{ border: '1px solid #884AED', borderRadius: '10px' }} />
                      </div>
                      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <span className='typo-bold-book-order' style={{ textAlign: 'center', marginTop: '2%' }}>{petDetail.name}</span>
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12} md={10} lg={10}>
                      <Box sx={{ border: "1px solid #e0e4e8", padding: " 0px 10px" }}>
                        {/* <Divider orientation="horizontal" /> */}
                        <Grid container spacing={2} mt={0.01}>
                          <Grid sx={{ padding: '10px', }} item sm={6} xs={12} md={6} lg={6}>
                            <Typography>
                              <span className='typo-bold-book-order'>User Name :</span> {rowDetails.name}
                            </Typography>
                            <Typography>
                              <span className='typo-bold-book-order'>Pet Type :</span> {petDetail.pet_type}
                            </Typography>
                            <Typography>
                              <span className='typo-bold-book-order'>Pet Gender :</span> {petDetail.gender}
                            </Typography>
                            <Typography>
                              <span className='typo-bold-book-order'>Pet DOB :</span>{moment.utc(petDetail.dob).format('DD-MM-YYYY')}
                            </Typography>
                            <Typography>
                              <span className='typo-bold-book-order'>Pet Breed :</span> {petDetail.breed}
                            </Typography>
                          </Grid>

                          <Grid sx={{ borderLeft: '1px solid #e0e4e8' }} item sm={6} xs={12} md={6} lg={6}>
                            <Typography>
                              <span className='typo-bold-book-order'>Pet Aggressiveness :</span> {petDetail.aggressive}
                            </Typography>

                            <Typography>
                              <span className='typo-bold-book-order'>Pet Vaccinated :</span> {`${petDetail.is_vaccinated === 'true' ? 'Yes' : 'No'}`}
                            </Typography>
                            <Typography>
                              <span className='typo-bold-book-order'>Vaccination Dose :</span>
                              {
                                petDetail.vaccination?.map((vaccine, index) =>
                                  <span key={index}>
                                    {index > 0 && ' , '}
                                    {vaccine}
                                  </span>
                                )
                              }
                            </Typography>
                            <Typography>
                              <span className='typo-bold-book-order'>Pet Weight :</span> {petDetail.weight}
                            </Typography>

                            <Typography>
                              <span className='typo-bold-book-order'>Pet Friendly with :</span>
                              {
                                petDetail.friendly_with?.map((friendlyPet, index) =>
                                  <span key={index}>
                                    {index > 0 && ' , '}
                                    {friendlyPet}
                                  </span>

                                )
                              }
                            </Typography>
                          </Grid>

                        </Grid>
                        {/* <Divider orientation="horizontal" /> */}
                      </Box>
                    </Grid>
                  </Grid>


                </Box>

              </div>
            ))}

            <Box sx={{ mt: '20px' }}>
              <Typography sx={{ ml: '20px' }} variant="h4">Booking Details</Typography>

              <Box ml={2} mt={4}>
                <Divider orientation="horizontal" />
                <TableContainer>
                  {rowDetails?.sessions.length === 0 ? (
                    <Typography component="div">
                      <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                    </Typography>
                  ) : (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Booking Id</TableCell>
                          <TableCell align="center">Booking Date</TableCell>
                          <TableCell align="center">Start Date</TableCell>
                          <TableCell align="center"> Time Slot</TableCell>
                          <TableCell align="center"> Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          rowDetails?.sessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ele, key) => {
                            return (
                              <>
                                <TableRow >
                                  {/* <TableCell align="center">{ele.session_ref_id}</TableCell> */}

                                  <TableCell align="center" onClick={() => handleReferenceID(ele.session_ref_id.endsWith("-1"), ele._id, ele.reschedule_status, ele.request_for_rescheduled, ele.session_status, ele.session_ref_id)}>
                                    <Typography variant="subtitle2" noWrap className='booking-ref-ids'>
                                      {ele.session_ref_id.includes("S") ? ele.session_ref_id : (ele.session_ref_id.endsWith("-1") ? ele.session_ref_id.slice(0, -2) : ele.session_ref_id)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center"> {moment(ele.created_at).format('DD-MM-YYYY HH:mm A')}</TableCell>
                                  <TableCell align="center">{moment.utc(ele.date).format('DD-MM-YYYY')}</TableCell>
                                  <TableCell align="center">{ele.time_slot}</TableCell>
                                  <TableCell align="center">{ele.session_status}</TableCell>
                                </TableRow>
                              </>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  )
                  }

                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={rowDetails?.sessions?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <Divider orientation="horizontal" />
              </Box>

            </Box>

          </div>
        )
      }
    </>

  );
};

export default UserManagementDetails;
