import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import {  getVaccineDataByID, updateVaccineData } from 'src/common/api-service';
import BackButton from 'src/components/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const petTypes = [
  { value: 'dog', label: 'Dog' },
  { value: 'cat', label: 'Cat' },
];

const EditPetVaccine = () => {
  const { id } = useParams();
  const [vaccineID , setVaccineID] = useState();
  const [vaccineData, setVaccineData] = useState({
    vaccineName: '',
    selectedPetTypes: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch vaccine data by ID and update state
    const vaccineID = localStorage.getItem('petVaccineID')
    setVaccineID(vaccineID);
    getVaccineDataByID(vaccineID)
      .then((res) => {
        setVaccineData({
          vaccineName: res.data.data.vaccine,
          selectedPetTypes: res.data.data.pet_type,
        });
      })
      .catch((error) => {
        console.error('Error fetching vaccine data:', error);
      });
  }, [id]);

  const handleVaccineNameChange = (event) => {
    setVaccineData((prevData) => ({
      ...prevData,
      vaccineName: event.target.value,
    }));
  };

  const handlePetTypeChange = (event) => {
    setVaccineData((prevData) => ({
      ...prevData,
      selectedPetTypes: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    // Handle form submission here
    const payload = {
      _id: vaccineID,
      vaccine: vaccineData.vaccineName,
      pet_type: vaccineData.selectedPetTypes,
    };
    return updateVaccineData(payload).then(
      (res) => {
        console.log('Update successful:', res);
        Swal.fire({
            // position: "top-end",
            icon: "success",
            title: `${res.data.message}`,
            showConfirmButton: false,
            timer: 1500
          });
        navigate('/dashboard/vaccineList');
      },
      (err) => {
        console.log('Update failed:', err);
      }
    );
  };

  return (
    <div>
      <BackButton />
      <form onSubmit={handleSubmit} style={{ padding: '30px' }}>
        <h2>Edit Pet Vaccine</h2>
        <TextField
          id="vaccine-name"
          label="Vaccine Name"
          variant="outlined"
          value={vaccineData.vaccineName}
          onChange={handleVaccineNameChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="pet-type-label">Pet Type(s)</InputLabel>
          <Select
            labelId="pet-type-label"
            id="pet-type"
            multiple
            value={vaccineData.selectedPetTypes}
            onChange={handlePetTypeChange}
            label="Pet Type(s)"
          >
            {petTypes.map((petType) => (
              <MenuItem key={petType.value} value={petType.value}>
                {petType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" type="submit">
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export default EditPetVaccine;
