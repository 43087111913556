/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, Stack, Link, TextareaAutosize, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';

import { addCoupons, addNotification, dropDownServiceMaster } from '../../common/api-service';
import Icon from '../../components/Iconify';

function AddNotification() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [notificationDiscription, setNotificationDiscription] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [data, setData] = useState([]);

  //   const serviceMaster = (payload) => {
  //     return dropDownServiceMaster(payload).then(
  //       (res) => {
  //         setData(res.data.data);
  //       },
  //       (err) => {
  //         console.log('err data: ', err);
  //       }
  //     );
  //   };

  //   useEffect(() => {
  //     serviceMaster();
  //   }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Use this effect to update isActive based on couponStatus

  const onSubmit = () => {
    
    setLoading(true);
    const payload = {
      title: userName,
      description: notificationDiscription,
      notification_for: sendTo,
      icon: imageData,
    };
    return addNotification(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.data,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/notificationlist');
        } else {
          swal({
            title: res.data.data,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };
  const [sendTo, setSendTo] = useState('');
  const [imageData, setImageData] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const userTypes = ['User', 'Sitter', 'Both'];
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result); 
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '15px' }}>
        <Icon icon="gg:add" style={{ fontSize: '1.5em' }} /> 
        <Typography
          variant="h4"
          component="div"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          display="flex"
          justifyContent="start"
          alignItems="center"
          marginLeft="8px" 
        >
          Add Notification
        </Typography>
      </div>

      <Box
        component="form"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <form > */}
        <Stack spacing={3} style={{ width: '100%' }}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={6} lg={6}>
              <div className="form-group">
                <div className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    value={userName}
                    name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                    onChange={(e) => setUserName(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
            </Grid>

            {/* choose file */}
            <Grid item xs={6}>
              <div className="form-group">
                <div
                  className="form-control"
                  style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}
                >
                  <TextField
                    variant="outlined"
                    value={selectedFile ? selectedFile.name : ''}
                    name="file"
                      required
                      {...register('file', {
                        required: true,
                      })}
                    label="Choose File"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <label htmlFor="file-upload">
                            <input
                              accept=".jpg, .png, .jpeg"
                              id="file-upload"
                              type="file"
                              onChange={handleFileChange}
                              style={{ display: 'none' }}
                            />
                            <Button variant="contained" component="span">
                              Browse
                            </Button>
                          </label>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              {errors.file && errors.file.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
            </Grid>

            {/* Description */}
            <Grid item xs={6}>
              <div className="form-group">
                <div className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    multiline
                    placeholder="Description"
                    value={notificationDiscription}
                    name="description"
                    required
                    {...register('description', {
                      required: true,
                    })}
                    onChange={(e) => setNotificationDiscription(e.target.value)}
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              </div>
              {errors.description && errors.description.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
            </Grid>

             {/* choose send to */}
            <Grid item xs={6}>
              <div className="form-group">
                <div className="form-control">
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="user-type-label">Send To</InputLabel>
                    <Select
                      labelId="user-type-label"
                      id="user-type"
                      value={sendTo}
                      name="userType"
                      required
                      {...register('userType', {
                        required: true,
                      })}
                      onChange={(e) => setSendTo(e.target.value)}
                      label="Send To"
                    >
                      {userTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {errors.userType && errors.userType.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
            </Grid>

            {/* Coupon validity in days */}
          </Grid>

          <Button
            variant="contained"
            type="submit"
            style={{ width: '200px', textAlign: 'end', marginLeft: 'auto', marginTop: '50px' }}
          >
            Add Notification
          </Button>
        </Stack>
      </Box>
    </div>
  );
}

export default AddNotification;
