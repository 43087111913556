import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Button } from '@mui/material';
import { deleteVaccine, getVaccineList } from 'src/common/api-service';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const VaccineList = () => {
    const [vaccineData, setVaccineData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleEditVaccine = (_id)=>{
        localStorage.setItem('petVaccineID',_id)
        navigate('/dashboard/editPetVaccine')
    }

    const handleDeleteVaccine = (_id) => {
        console.log('_id :', _id);
        Swal.fire({
            title: "Do you want to delete the vaccine?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `Don't delete`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const payload = {
                    _id: _id,
                };
                return deleteVaccine(payload).then((res) => {
                    if (res.data.status === true) {
                        Swal.fire(`${res.data.message}`, "", "success");
                        getVaccineData();

                    }
                });
            } else if (result.isDenied) {
                Swal.fire("Vaccine is not deleted", "", "info");
            }
        });
    }
    const getVaccineData = () => {
        getVaccineList()
            .then(response => {
                setVaccineData(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getVaccineData();

    }, []);

    if (loading) {
        return <CircularProgress style={{ textAlign: 'center', marginLeft: '50%', marginTop: '10%' }} />;
    }

    if (error) {
        return <Typography variant="body1">Error: {error.message}</Typography>;
    }

    const handleAddVaccine = () => {
        navigate('/dashboard/addPetVaccine');
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '30px' }}>
                <h2>Vaccine List</h2>
                <Button variant='contained' onClick={handleAddVaccine}>Add Vaccine</Button>
            </div>
            <TableContainer component={Paper} sx={{ padding: '30px' }}>
                <Table size='small' >
                    <TableHead>
                        <TableRow>
                            <TableCell>Serial No</TableCell>
                            <TableCell>Vaccine Name</TableCell>
                            <TableCell>Pet Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vaccineData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.vaccine}</TableCell>
                                <TableCell>
                                    {row.pet_type.map((pet, index) => (
                                        <div style={{ display: 'flex' }}>
                                            <Typography style={{ display: 'flex' }} key={index}>{pet}</Typography>
                                            {index !== row.pet_type.length - 1 && <span>,&nbsp;</span>}
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <div>
                                    <Button variant='contained' color='error' size='small' sx={{m:0.5}} onClick={() => { handleDeleteVaccine(row._id) }}>Delete</Button>
                                    <Button variant='contained' size='small' sx={{m:0.5}} onClick={() => { handleEditVaccine(row._id) }}>Edit</Button>



                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default VaccineList;
