import React, { useEffect, useState } from 'react';
import { TextField, Typography, Grid, Paper, IconButton, Button } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon, Edit as EditIcon } from '@mui/icons-material';
import { addRazorPayWebCredential, getRazorPayWebCredentials, updateRazorPayKeySetting } from 'src/common/api-service';
import swal from 'sweetalert2';

const RazorPaySetting = () => {
    const [formData, setFormData] = useState({
        keyId: '',
        keySecret: '',
        id:''
    });
    

    const [isEditing, setIsEditing] = useState({
        keyId: false,
        keySecret: false
    });

    const getCredentialsData = () => {
        getRazorPayWebCredentials()
            .then((res) => {
                setFormData({
                    keyId: res.data.data.razorpay_api_key,
                    keySecret: res.data.data.razorpay_secret_key,
                    id: res.data.data._id,
                });
            });
    };

    useEffect(() => {
        getCredentialsData();
    }, []);

    const updateRazorPaySetting = () => {
        const payload = {
            _id: formData.id,
            razorpay_secret_key: formData.keySecret,
            razorpay_api_key: formData.keyId,
        };

        // Check if keyId is empty, if so, it means we are adding new credentials
        if (!formData.keyId) {
            const payload = {
                razorpay_secret_key: formData.keySecret,
                razorpay_api_key: formData.keyId,
            };
            addRazorPayWebCredential(payload)
                .then((response) => {
                    if (response.status === 200) {
                        swal.fire({
                            title: `${response.data.message}`,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1200
                        });
                        setIsEditing({
                            keyId: false,
                            keySecret: false
                        });
                    } else {
                        console.log("Error in adding credentials");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            // If keyId is not empty, update the existing credentials
            updateRazorPayKeySetting(payload)
                .then((response) => {
                    if (response.status === 200) {
                        swal.fire({
                            title: `${response.data.message}`,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1200
                        });
                        setIsEditing({
                            keyId: false,
                            keySecret: false
                        });
                    } else {
                        console.log("Error in updating credentials");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    const handleEditClick = (field) => {
        setIsEditing((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Display a confirmation dialog using SweetAlert
        swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to update the RazorPay Credential?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'update'
        }).then((result) => {
            if (result.isConfirmed) {
                // If the user confirms, proceed with the update
                updateRazorPaySetting();
            }
        });
    };

    return (
        <div style={{ padding: '20px' }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography variant="h4" gutterBottom align="center">
                            RazorPay Setting
                        </Typography>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <TextField
                                    label="Key ID"
                                    name="keyId"
                                    value={formData.keyId}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    disabled={!isEditing.keyId}
                                    InputProps={{ sx: { height: '40px' } }} // Adjust height
                                    style={{ marginRight: '1rem' }}
                                />
                                <IconButton
                                    onClick={() => handleEditClick('keyId')}
                                    disabled={isEditing.keySecret} // Disable if the other field is being edited
                                >
                                    <EditIcon color={isEditing.keyId ? 'primary' : 'inherit'} />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <TextField
                                    label="Key Secret"
                                    name="keySecret"
                                    value={formData.keySecret}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    disabled={!isEditing.keySecret}
                                    InputProps={{ sx: { height: '40px' } }} // Adjust height
                                    style={{ marginRight: '1rem' }}
                                />
                                <IconButton
                                    onClick={() => handleEditClick('keySecret')}
                                    disabled={isEditing.keyId} // Disable if the other field is being edited
                                >
                                    <EditIcon color={isEditing.keySecret ? 'primary' : 'inherit'} />
                                </IconButton>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                {isEditing.keyId || isEditing.keySecret ? (
                                    <Button type="submit" variant="contained" color="primary">
                                        Update
                                    </Button>
                                ) : (
                                    <Button type="submit" variant="contained" color="primary" disabled>
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default RazorPaySetting;
