/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link, TextareaAutosize, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import swal from 'sweetalert';
import { DatePicker } from 'antd';
import { number } from 'prop-types';
import moment from 'moment';
import dayjs from 'dayjs';

import { addCoupons, couponsUpdateById, dropDownServiceMaster, getCouponsDataById } from '../../common/api-service';
import Icon from '../../components/Iconify';

function AddCoupons() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const discount_units = ['INR', 'USD', 'percentage'];
  const coupon_status = ['Active', 'Inactive'];

  const customers_list = ['rohit', 'vivek', 'pravin'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [AdminNotes, setAdminNotes] = useState('');
  const [couponDiscription, setCouponDiscription] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [couponDiscount, setCouponDiscount] = useState();

  const [minPurchase, setMinPurchase] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [userLimit, setUserLimit] = useState('');
  const [couponCode, setCouponCode] = useState('');

  const [discountUnit, setDiscountUnit] = React.useState('');
  const [couponStatus, setStatus] = React.useState('');
  const [isActive, setIsActive] = useState(false);
  const [serviceMasterId, setServiceMaster] = React.useState([]);
  const [data, setData] = useState([]);

  const [fromDate, setFromDate] = useState('');

  const [toDate, setToDate] = useState('');

  const [validatyDays, setValiDatyDays] = useState(0);
  const [idget, setIdget] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [isPromotedCoupon, setIsPromotedCoupon] = useState(false); // Default value is false
  const [noOfUserSelected, setNoOfUserSelected] = useState(20);

  const NoOfUser = [20, 50, 100, 200, 'All'];

  const { RangePicker } = DatePicker;

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    serviceMaster();
    getCouponID();
    // if (couponStatus === "Active") {
    //   setIsActive(true);
    // } else {
    //   setIsActive(false);
    // }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Use this effect to update isActive based on couponStatus

  const getCouponID = () => {
    const getCouponID = localStorage.getItem('setMasterId');

    setIdget(getCouponID);

    const payload = {
      _id: getCouponID,
    };

    return getCouponsDataById(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;

        const startDate = moment(data.valid_from).format('YYYY-MM-DD');
        const endDate = moment(data.valid_till).format('YYYY-MM-DD');

        setUserName(data.coupon_name);
        setCouponDiscription(data.description);
        setPetName(data.pet_type);
        setCouponDiscount(data.discount);
        setMinPurchase(data.minimum_amount);
        setUserLimit(data.max_times_to_be_availed);
        setCouponCode(data.coupon_code);
        setDiscountUnit(data.discount_type);
        setIsActive(data.is_active);
        setServiceMaster(data.service_master_id);
        setFromDate(data.valid_from);
        setToDate(data.valid_till);
        setValiDatyDays(data.validity_days);
        setSelectedDates([dayjs(startDate), dayjs(endDate)]);
        setIsPromotedCoupon(data.is_promoted_coupon);
        setNoOfUserSelected(data.user_coupon_limit);
      } else {
        console.log('error');
      }
    });
  };

  const onSubmit = () => {
    
    setLoading(true);
    const payload = {
      _id: idget,
      coupon_name: userName === '' ? '' : userName,
      coupon_code: couponCode,
      service_master_id: serviceMasterId,
      description: couponDiscription,
      pet_type: petName,
      minimum_amount: Math.ceil(minPurchase),
      discount: Math.ceil(couponDiscount),
      discount_type: discountUnit,
      max_times_to_be_availed: Math.ceil(userLimit),
      valid_from: fromDate,
      valid_till: toDate,
      is_active: isActive,
      is_promoted_coupon: isPromotedCoupon,
      user_coupon_limit: isPromotedCoupon ? noOfUserSelected : 'All',
      validity_days: validatyDays,
    };

    console.log('payload', payload);
    return couponsUpdateById(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/coupons');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  const datefilterhandleChange = (props) => {
    if (props) {
      const [start, end] = props;
      setFromDate(start);
      setToDate(end);
      setSelectedDates([dayjs(start), dayjs(end)]);

      const fromDate1 = new Date(start);
      const toDate1 = new Date(end);

      const timeDifference = toDate1.getTime() - fromDate1.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      setValiDatyDays(daysDifference);
    } else {
      setFromDate('');
      setToDate('');
      setValiDatyDays('');
    }
  };

  const manualgenerateCouponCode = (e) => {
    setCouponCode(
      e.target.value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '')
        .slice(0, 8)
    );
  };

  const generateCouponCode = () => {
    // Function to generate a random alphanumeric coupon code
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 8;
    let code = '';

    for (let i = 0; i < length; i += 1) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex); // Use += for string concatenation
    }

    setCouponCode(code);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '15px' }}>
        <Icon icon="gg:add" style={{ fontSize: '1.5em' }} /> {/* Adjust the fontSize as needed */}
        <Typography
          variant="h4"
          component="div"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          display="flex"
          justifyContent="start"
          alignItems="center"
          marginLeft="8px" // Adjust the margin as needed
        >
          Edit Coupon
        </Typography>
      </div>

      <Box
        // component="form"
        // sx={{
        //   '& > :not(style)': { m: 1, width: '50ch' },
        // }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
        noValidate
        autoComplete="off"
        // onSubmit={handleSubmit(onSubmit)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Grid container spacing={3}>
              {/* Title */}
              <Grid item xs={6} lg={6}>
                <div className="form-group">
                  <div className="form-control">
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      required
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </Grid>
              {/* Service Master Dropdown */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Service Master</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        required
                        // multiple
                        value={serviceMasterId}
                        onChange={(e) => {
                          setServiceMaster(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                        MenuProps={MenuProps}
                      >
                        {data.map((service_master_id) => (
                          <MenuItem
                            key={service_master_id._id}
                            value={service_master_id._id}
                            style={getStyles(service_master_id, serviceMasterId, theme)}
                          >
                            {service_master_id.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              {/* Discription */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <TextareaAutosize
                      id="outlined-basic"
                      label="Discription"
                      variant="outlined"
                      required
                      minRows={3}
                      placeholder="Discription"
                      value={couponDiscription}
                      onChange={(e) => setCouponDiscription(e.target.value)}
                      style={{ width: '100%', backgroundColor: 'transparent', borderRadius: '5px' }}
                    />
                  </div>
                </div>
              </Grid>
              {/* Admin Notes */}
              {/* <Grid item xs={6}>
              <div className="form-group">
                <div className="form-control">
                  <TextareaAutosize
                    id="outlined-basic"
                    label="Admin Notes"
                    variant="outlined"
                    minRows={3}
                    placeholder="Admin Notes"
                    value={AdminNotes}
                    onChange={(e) => setAdminNotes(e.target.value)}
                    style={{ width: '100%', backgroundColor: 'transparent', borderRadius: '5px' }}
                  />
                </div>
              </div>
            </Grid> */}
              {/* minimum purchase */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <TextField
                      id="outlined-basic"
                      label="Minimum Purchase"
                      variant="outlined"
                      value={minPurchase}
                      required
                      onChange={(e) => setMinPurchase(e.target.value)}
                      style={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </Grid>
              {/* Discount */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <TextField
                      id="outlined-basic"
                      label="Discount"
                      variant="outlined"
                      required
                      value={couponDiscount}
                      onChange={(e) => setCouponDiscount(e.target.value)}
                      style={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </Grid>
              {/* Discount Unit  */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Discount Unit</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={discountUnit}
                        required
                        onChange={(e) => {
                          setDiscountUnit(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Discount Unit" />}
                        MenuProps={MenuProps}
                      >
                        {discount_units.map((discount_unit) => (
                          <MenuItem
                            key={discount_unit}
                            value={discount_unit}
                            style={getStyles(discount_unit, discountUnit, theme)}
                          >
                            {discount_unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              {/* choose customer  */}
              {/* <Grid item xs={6}>
              <div className="form-group">
                <div className="form-control">
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label">Choose Customer</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={selectedCustomer}
                      onChange={(e) => {
                        setSelectedCustomer(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Choose Customer" />}
                      MenuProps={MenuProps}
                    >
                      {customers_list.map((discount_unit) => (
                        <MenuItem
                          key={discount_unit}
                          value={discount_unit}
                          style={getStyles(discount_unit, discountUnit, theme)}
                        >
                          {discount_unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid> */}
              {/* limit for same user */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <TextField
                      id="outlined-basic"
                      label="Limit for same User"
                      variant="outlined"
                      required
                      value={userLimit}
                      onChange={(e) => setUserLimit(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </Grid>{' '}
              {/* generate coupon code */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <TextField
                      id="outlined-basic"
                      label="Coupon Code"
                      variant="outlined"
                      required
                      title="This field is required!"
                      onChange={manualgenerateCouponCode}
                      InputProps={{
                        endAdornment: (
                          <Button
                            position="end"
                            style={{ cursor: 'pointer', color: '#5d5e5c', backgroundColor: 'transparent' }}
                            onClick={generateCouponCode}
                          >
                            Generate Code
                          </Button>
                        ),
                      }}
                      value={couponCode}
                      // onChange={(e) => setCouponCode(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </Grid>
              {/* Pet Type */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={petName}
                        required
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((pet_type) => (
                          <MenuItem key={pet_type} value={pet_type} style={getStyles(pet_type, petName, theme)}>
                            {pet_type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              {/* Status */}
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        required
                        value={isActive === true ? 'Active' : 'Inactive'}
                        onChange={(e) => {
                          const status1 = e.target.value === 'Active';
                          setIsActive(status1);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {coupon_status.map((coupon_status) => (
                          <MenuItem
                            key={coupon_status}
                            value={coupon_status}
                            style={getStyles(coupon_status, couponStatus, theme)}
                          >
                            {coupon_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              {/* coupon validaty */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <RangePicker
                  placeholder={['Start Date', ' End Date']}
                  onChange={datefilterhandleChange}
                  required
                  value={selectedDates}
                  style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
                />
              </Grid>
              {/* Coupon validity in days */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={validatyDays}
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">days</InputAdornment>,
                  }}
                  readOnly
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <div className="form-control">
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Is Promoted Coupon?</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        value={isPromotedCoupon ? 'true' : 'false'}
                        required
                        onChange={(e) => {
                          setIsPromotedCoupon(e.target.value === 'true');
                          // Convert string value to boolean
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Is Promoted Coupon?" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              {isPromotedCoupon && (
                <Grid item xs={6}>
                  <div className="form-group">
                    <div className="form-control">
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-multiple-chip-label">No. of User Coupon Limited to </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          value={noOfUserSelected}
                          required
                          onChange={(e) => {
                            setNoOfUserSelected(e.target.value);
                          }}
                          input={<OutlinedInput id="select-multiple-chip" label="No. of User Coupon Limited to" />}
                          MenuProps={MenuProps}
                        >
                          {NoOfUser?.map((ele, key) => {
                            return (
                              <MenuItem value={ele} key={key}>
                                {ele}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>

            <LoadingButton variant="contained" type="submit">
              Update Coupon
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </div>
  );
}

export default AddCoupons;
