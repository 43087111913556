// import * as Yup from 'yup';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// // form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// // @mui
// import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // components
// import Iconify from '../../../components/Iconify';
// import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// // ----------------------------------------------------------------------

// export default function LoginForm() {
//   const navigate = useNavigate();

//   const [showPassword, setShowPassword] = useState(false);
//   const [userName, setUserName] = useState("");
//   const [userPassword, setUserPassword] = useState("");

//   const LoginSchema = Yup.object().shape({
//     email: Yup.string().email('Email must be a valid email address').required('Email is required'),
//     password: Yup.string().required('Password is required'),
//   });

//   const defaultValues = {
//     email: '',
//     password: '',
//     remember: true,
//   };

//   const methods = useForm({
//     resolver: yupResolver(LoginSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     register,
//     formState: { isSubmitting, errors },
//   } = methods;

//   const onSubmit = async () => {
//     navigate('/dashboard', { replace: true });
//   };

//   return (
//     <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//       <Stack spacing={3}>
//         <RHFTextField
//           name="email"
//           label="Email address"
//           value={userName}
//           onChange={(e) =>
//             setUserName(e.target.value)
//           }
//           refs={register('email')}
//         />
//         <p>{errors.email?.message}</p>

//         <RHFTextField
//           name="password"
//           label="Password"
//           value={userPassword}
//           onChange={(e) =>
//             setUserPassword(e.target.value)
//           }
//           refs={register('password')}
//           type={showPassword ? 'text' : 'password'}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
//                   <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//         />
//         <p>{errors.password?.message}</p>
//       </Stack>

//       <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
//         <RHFCheckbox name="remember" label="Remember me" />
//         <Link variant="subtitle2" underline="hover">
//           Forgot password?
//         </Link>
//       </Stack>

//       <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
//         Login
//       </LoadingButton>
//     </FormProvider>
//   );
// }
import './Login.css';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
// import Alert from '@mui/material/Alert';
import { Audio } from 'react-loader-spinner';
import swal from 'sweetalert';
import Checkbox from '@mui/material/Checkbox';
import Iconify from '../../../components/Iconify';
import { login } from '../../../common/api-service';
import { ThemeContext } from '../../../App';

function LoginForm() {
  const navigate = useNavigate();


  const notificationToken = useContext(ThemeContext);
  // console.log("isFCMTokenset",notificationToken.fcmMessageFirebaseToken);
 
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      email: userName,
      password: userPassword,
      fcm_token: notificationToken.fcmMessageFirebaseToken
    };
    return login(payload).then(
      (res) => {
        if (res.data.status === true) {
          const token = res.data.token;
          localStorage.setItem('token', token);
          setLoading(false);
          if (token) {
            navigate('/dashboard/serviceMaster', { replace: true });
            swal({
              title: res.data.message,
              icon: 'success',
              timer: 2000,
              // showConfirmButton: false,
              button: false,
            });
          } else {
            swal({
              title: res.data.message,
              icon: 'error',
              timer: 2000,
              // showConfirmButton: false,
              button: false,
            });
          }
        } else {
          swal({
            title: res.data.message,
            icon: 'error',
            timer: 2000,
            // showConfirmButton: false,
            button: false,
          });
          setUserName('');
          setUserPassword('');
          setLoading(false);
          navigate('/', { replace: true });
        }
      },
      (err) => {
        console.log('err', err);
        swal({
          title: 'Invalid Email or Password',
          icon: 'error',
          timer: 2000,
          // showConfirmButton: false,
          button: false,
        });
        navigate('/', { replace: true });
        setLoading(false);

      }
    );
  };

  const isValidPassword = (input) => /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/.test(input);

  return (
    <div className="App">
      <>
        {loading ? (
          <div className="audio">
            <Audio
              height="80"
              width="80"
              radius="9"
              color="grey"
              margin="auto"
              marginTop="4rem"
              ariaLabel="three-dots-loading"
              visible={loading}
              wrapperStyle
              wrapperClass
            />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={userName}
                    {...register('email', { required: true, pattern: /\S+@\S+\.\S+/ })}
                    onInput={(e) => setUserName(e.target.value.replace(/[^a-z0-9@.]/g, ''))}
                    style={{ width: '100%' }}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <p className="text-danger" style={{ color: 'red' }}>
                      Email is required
                    </p>
                  )}
                  {errors.email && errors.email.type === 'pattern' && (
                    <p className="text-danger" style={{ color: 'red' }}>
                      Please enter valid email
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <TextField
                    label="Password"
                    variant="outlined"
                    name="email"
                    value={userPassword}
                    {...register('password', {
                      required: 'Password is required.',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters long.',
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*\d)[^\u0900-\u097F]+$/,
                        message:
                          'Password only contains lowercase letters, and digits.',
                      },
                    })}                    onInput={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue || inputValue === '') {
                        setUserPassword(inputValue);
                      }
                    }}
                    style={{ width: '100%' }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && (
    <p className="text-danger" style={{ color: 'red' }}>
      {errors.password.message}
    </p>
  )}
                  {/* {errors.password && errors.password.type === 'minLength' && (
                    <p className="text-danger" style={{ color: 'red' }}>
                      Password length at least 6
                    </p>
                  )}
                  {errors.password && errors.password.type === 'required' && (
                    <p className="text-danger" style={{ color: 'red' }}>
                      Password is required
                    </p>
                  )} */}
                </div>

                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Remember Me" />
                  </FormGroup>
                  <Link variant="subtitle2" underline="hover">
                    Forgot password?
                  </Link>
                </Stack> */}
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  Login
                </LoadingButton>
              </Stack>
            </form>
          </>
        )}
      </>
    </div>
  );
}

export default LoginForm;
