/* eslint-disable camelcase */
/* eslint-disable */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import {
  GroomingCancellationListHead,
  GroomingCancellationMoreMenu,
} from '../../../sections/@dashboard/groomingCancellation';
// mock
import { getDogTrainingCancellationData } from '../../../common/api-service';
import { TrainingCancellationListHead, TrainingCancellationMoreMenu } from 'src/sections/@dashboard/trainingCancellation';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_name', label: 'Name', alignRight: false },
  { id: 'user_contact_no', label: 'Phone No', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'reason_by_user', label: 'Reason', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'package_name', label: 'Package', alignRight: false },
  { id: 'date_of_booking', label: 'Booking Date', alignRight: false },
  { id: 'cancellation_request_date', label: 'Cancellation Request Date', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'status', label: 'Action', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function TrainingCancellation() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const offer = () => {
    const payload = page;
    return getDogTrainingCancellationData(payload).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    offer();
  }, [offset, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Training Cancellation Request
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                </Typography>
              ) : (
                <Table>
                  <TrainingCancellationListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      {
                        console.log('filtereddata', row);
                      }

                      const {
                        _id,
                        user_name,
                        service_name,
                        pet_type,
                        date_of_booking,
                        cancellation_request_date,
                        amount,
                        package_name,
                        reason_by_user,
                        start_date,
                        user_contact_no,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {user_name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{user_contact_no}</TableCell>
                          <TableCell align="left">
                            <Typography>{moment(start_date).format('YYYY-MM-DD')}</Typography>
                          </TableCell>
                          <TableCell align="left">{service_name}</TableCell>
                          <TableCell align="left">{reason_by_user}</TableCell>
                          <TableCell align="left">{pet_type}</TableCell>
                          <TableCell align="left">{package_name}</TableCell>
                          <TableCell align="left">
                            <Typography>{moment(date_of_booking).format('YYYY-MM-DD')}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{moment(cancellation_request_date).format('YYYY-MM-DD')}</Typography>
                          </TableCell>
                          <TableCell align="left">{amount}</TableCell>

                          <TableCell align="right">
                            <TrainingCancellationMoreMenu data={_id} handleRefresh={setOffset} currentStatus={status} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            count={data.number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}
