import  {useContext } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken,onMessage } from 'firebase/messaging';
import { ThemeContext } from "./App";

const firebaseConfig = {
  apiKey: "AIzaSyChSUTY_NyUGZJF39EBFbGSViQ_6-TWGn0",
  authDomain: "pawspace-10391.firebaseapp.com",
  projectId: "pawspace-10391",
  storageBucket: "pawspace-10391.appspot.com",
  messagingSenderId: "499687881515",
  appId: "1:499687881515:web:ec795a431ab64899d7e36e",
  measurementId: "G-CQ68B9CP7W"
};

  const app = initializeApp(firebaseConfig);
  export const messaging = getMessaging(app);

  export const generateToken = async () => {

    
    const permission = await Notification.requestPermission();
    // console.log("permission".permission);

    if(permission === 'granted'){
        const token = await getToken(messaging, { vapidKey: 'BDSxATqG-s9g8J8K2OU8JE0VNc-VWzcYT_GtIreBHLF2nEqw_NOJ8KmHW-U6ReXeXfv4ZUiVIUTJm_5yhPdl7Xg'})
            return token;
    }
    

  }