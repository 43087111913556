import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function BasicBreadcrumbs() {
   const  p1 = localStorage.getItem("s1")
  const location = useLocation(); 
  const pathnames = location.pathname.split('/').filter((x) => x[1]);
  
  const p2 = "BookingOrderDetails";
  let p3 = pathnames[1] === "groomingAllocationPage" ? "Grooming Sitter Allocation" : '';
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to={''}>
          {p1}
        </Link>
        <Link underline="hover" color="inherit" to={''}>
          {p2}
        </Link>
        {/* <Link underline="hover" color="inherit" to={'/dashboard/serviceMaster'}>
          {p3}
        </Link> */}
        {/* {pathnames.map((path, index) => { // Map over pathnames array to generate breadcrumbs dynamically
          const routeTo = `/${pathnames}`; // Get the route to navigate to
          const isLast = index === pathnames.length - 1; // Check if it's the last breadcrumb
          return (
            <Link
              key={path}
              underline="hover"
              color="inherit"
              href={routeTo}
              onClick={(event) => {
                event.preventDefault();
                console.info(`Navigating to: ${routeTo}`);
                // Add navigation logic here if needed
              }}
            >
              {isLast ? path.charAt(0).toUpperCase() + path.slice(1) : path.charAt(0).toUpperCase() + path.slice(1)}
            </Link>
          );
        })} */}
      </Breadcrumbs>
    </div>
  );
}
