import React, { useState } from 'react';
import { GoogleMap, Polygon } from '@react-google-maps/api';

const EditableMapWithPolygon = ({ polygons }) => {
  console.log('polygons:', polygons);

  const [polygon, setPolygon] = useState(polygons);

  // Calculate the center of the polygon
  const calculateCenter = (polygon) => {
    if (polygon.length === 0) return { lat: 0, lng: 0 };

    let latSum = 0;
    let lngSum = 0;

    polygon.forEach((point) => {
      latSum += point.lat;
      lngSum += point.lng;
    });

    const latCenter = latSum / polygon.length;
    const lngCenter = lngSum / polygon.length;

    return { lat: latCenter, lng: lngCenter };
  };

  const center = calculateCenter(polygons);

  return (
    <>
      <div style={{ height: '600px', width: '100%' }}>
        <GoogleMap
          center={center}
          zoom={12}
          mapContainerStyle={{ height: '100%', width: '100%' }}
        >
          <Polygon
            paths={polygons}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
          />
        </GoogleMap>
      </div>
    </>
  );
};

export default EditableMapWithPolygon;
