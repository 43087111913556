/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { addGrommingAddons, dropDownServiceMaster } from '../../../../common/api-service';
import BackButton from '../../../../components/BackButton';

function GroomingAddAddons() {
  const [addonsPrice, setAddonsPrice] = useState('');
  const [AdminNotes, setAdminNotes] = useState('');
  const [offerDiscription, setOfferDiscription] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [addonsStatus, setStatus] = React.useState([]);
  const [displayAfterSession, setDisplayAfterSession] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const addons_status = ['active', 'disabled'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = () => {
    setChecked(!checked);
  };

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    serviceMaster();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      title: userName,
      description: offerDiscription,
      admin_notes: AdminNotes,
      target_pet: petName,
      display_after_session: displayAfterSession,
      price: addonsPrice,
      status: addonsStatus,
      is_checked: checked,
    };
    return addGrommingAddons(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);
          navigate('/dashboard/groomingAddons', { replace: true });
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Add Add-Ons
      </Typography>
      <BackButton/>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid>
                    {/* Title */}
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Discription */}
                  {/* <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Discription"
                      variant="outlined"
                      value={offerDiscription}
                      required
                      {...register('offerDiscription', {
                        required: true,
                      })}
                      onChange={(e) => setOfferDiscription(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.offerDiscription && errors.offerDiscription.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid> */}

                  {/* Admin Notes */}
                  {/* <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      {...register('AdminNotes', {
                        required: true,
                      })}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.AdminNotes && errors.AdminNotes.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid> */}
                  {/* Pet Type */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={petName}
                        {...register('petName', {
                          required: true,
                        })}
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((target_pet) => (
                          <MenuItem key={target_pet} value={target_pet} style={getStyles(target_pet, petName, theme)}>
                            {target_pet}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.petName && errors.petName.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Price (Actual Cost) */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Price"
                      variant="outlined"
                      type="number"
                      value={addonsPrice}
                      name="price"
                      required
                      {...register('price', {
                        required: true,
                      })}
                      onChange={(e) => setAddonsPrice(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.price && errors.price.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Display After Session */}
                  {/* <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Display After Sesssion"
                      variant="outlined"
                      type="number"
                      value={displayAfterSession}
                      required
                      {...register('displayAfterSession', {
                        required: true,
                      })}
                      onChange={(e) => setDisplayAfterSession(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.displayAfterSession && errors.displayAfterSession.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid> */}

                  {/* Status */}
                  <Grid item xs={12} sm={12}>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={addonsStatus}
                        required
                      {...register('addonsStatus', {
                        required: true,
                      })}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {addons_status.map((addons_status) => (
                          <MenuItem
                            key={addons_status}
                            value={addons_status}
                            style={getStyles(addons_status, addonsStatus, theme)}
                          >
                            {addons_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.addonsStatus && errors.addonsStatus.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* isChecked */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Checkbox onChange={handleChange} />
                    isChecked
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Add Addons
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default GroomingAddAddons;
