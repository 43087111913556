// routes
import { createContext, useEffect, useState ,useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { onMessage } from 'firebase/messaging';
import { generateToken, messaging ,} from './Firebase';
import notificationSoundFile from './Assets/audio/bell-notification.mp4';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

import "./css/Main.css"
import { getSessionCount } from './common/api-service';


// ----------------------------------------------------------------------
export const ThemeContext = createContext();
// export const NotificationTokenContext = createContext();
export default function App() {
  const [sitterAllocationCount, setSitterAllocationCount] = useState("");
  const [cancellationRequestCount, setCancellationRequestCount] = useState("");
  const [rescheduleRequestCount, setRescheduleRequestCount] = useState("");

  const [petBoardingCount, setPetBoardingCount] = useState("");
  const [dogWalkingCount, setDogWalkingCount] = useState("");
  const [petSittingCount, setPetSittingCount] = useState("");
  const [dogTrainingCount, setDogTrainingCount] = useState("");
  const [otherCount, setOtherCount] = useState("");
  const [fcmMessageFirebaseToken, setFcmMessageFirebaseToken] = useState('');

  // const [notificationMsg,setNotificationMsg]=useState(false);
  const audioRef = useRef(null);

const notify = (title) => toast(title);


  const contextValues = {
    sitterAllocationCount,
    setSitterAllocationCount,
    cancellationRequestCount,
    setCancellationRequestCount,
    rescheduleRequestCount,
    setRescheduleRequestCount,
    fcmMessageFirebaseToken,
    setFcmMessageFirebaseToken,
    petBoardingCount, 
    setPetBoardingCount,
    dogWalkingCount,
    setDogWalkingCount,
    petSittingCount, 
    setPetSittingCount,
    dogTrainingCount, 
    setDogTrainingCount,
    otherCount, 
    setOtherCount,
    // setNotificationMsg,
    // notificationMsg
  }

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const token = await generateToken();
      
      setFcmMessageFirebaseToken(token);

      
      
       onMessage(messaging, (payload) => {

        playAudio();
        notify(payload.notification.title);
        if (payload) {
          getSessionCount().then(
            (res) => {
              const [requestedSessionsCount, rescheduledRequestsCount, cancellationRequestsCount,
              petboardingcount, dogwalkingcount, petsittingcount, dogtrainingcount, 
              othercount] = res.data.data;
              setSitterAllocationCount(requestedSessionsCount.requested_sessions_count);
              setRescheduleRequestCount(rescheduledRequestsCount.rescheduled_requests_count);
              setCancellationRequestCount(cancellationRequestsCount.cancellation_requests_count);
              setPetBoardingCount(petboardingcount.pet_boarding_count);
              setDogWalkingCount(dogwalkingcount.dog_walking_count);
              setPetSittingCount(petsittingcount.pet_sitting_count);
              setDogTrainingCount(dogtrainingcount.dog_training_count);
              setOtherCount(othercount.others_count);
            },
            (err) => {
              console.log('err data: ', err);
            });
        }
      });
    };
    fetchData();
  }, []);


  return (

    <ThemeContext.Provider value={contextValues}>

      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
        <ToastContainer />
         <audio ref={audioRef} id="myAudio">
            <source src={notificationSoundFile} type="audio/mpeg" />
          </audio>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
