/* eslint-disable */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import Box from '@mui/material/Box';
import Scrollbar from '../../../components/Scrollbar';
import { SitterGroomingListHead, SitterGroomingMoreMenu } from '../../../sections/@dashboard/sitterAllocationGrooming';
// mock
import { getGroomingSitterAllocationData, getGroomingSitterAllocationSearchData } from '../../../common/api-service';
import BackButton from '../../../components/BackButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import SitterAllocationAllData from '../CommonApi/SitterAllocationAllData';
import CustomerOrderDetails from '../CustomerOrderDetails';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Booking_id', label: 'Booking Id', alignRight: false },
  { id: 'user_name', label: 'User Name', alignRight: false },
  // { id: 'grooming_book_services_id', label: 'Booking No', alignRight: false },
  // { id: 'pet_name', label: 'Pet Name', alignRight: false },
  // { id: 'number_of_pets', label: 'Number of Pets', alignRight: false },
  { id: 'target_pet', label: 'Pet Type', alignRight: false },
  // { id: 'service_name', label: 'Service', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  // { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  // { id: 'reschedule_date', label: 'Reschedule Date', alignRight: false },
  // { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  // { id: 'reschedule_time_slot', label: 'Reschedule Time Slot', alignRight: false },
  // { id: 'parent_phone_no', label: 'Contact Number', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  // { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  // { id: 'number_of_sessions', label: 'Sessions', alignRight: false },
  // { id: 'session_completed', label: 'Session Completed', alignRight: false },
  // { id: 'current_session_ongoing', label: 'Session Ongoing', alignRight: false },
  // { id: 'session remaining', label: 'Session Remaining', alignRight: false },
  // { id: 'offer_description', label: 'Offer Description', alignRight: false },
  // { id: 'offer_count', label: 'Offer Count', alignRight: false },
  // { id: 'offer_remaining', label: 'Offer Remaining', alignRight: false },
  { id: 'subscription', label: 'Subscription', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function GroomingSitterAllocation() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  
  const [loading, setLoading] = useState(false);

  const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('week'));
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs());
  const [popupOpen, setPopupOpen] = useState(false);

  const [show,setShow]=useState(false);


  const { RangePicker } = DatePicker;

  const sitter = () => {

    setLoading(true);
    const payload = page;

    return getGroomingSitterAllocationData(payload, searchQuery, defaultStartDate.add(1, 'day'), defaultEndDate.add(1, 'day'), selectedDate, rowsPerPage, defaultStartDate, defaultEndDate).then(
      (res) => {

        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {

    setPage(newPage);
    setFilteredData([])
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


  const [selectedDate, setSelectedDate] = useState('date')
  // console.log("selectedDate", selectedDate);
  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
    // setAllocationValue(event.target.value === 'booking_date' ? 'true' : 'false');

  };
  useEffect(() => {
    sitter();
  }, [offset, page, searchQuery, selectedDate, rowsPerPage]);

  // const handleChange = (props) => {
  //   if (props) {
  //     const [start, end] = props;
  //     setFromDate(start);
  //     setToDate(end);
  //   } else {
  //     setFromDate('');
  //     setToDate('');
  //   }
  // };

  const datefilterhandleChange = (props) => {

    const [start, end] = props;

    setDefaultStartDate(start);
    setDefaultEndDate(end)

    // setDefaultEndDate(end.add(1, 'day'))

  }

  // const handleReferenceID = (booking_ref_id, _id, status) => {
  //   localStorage.setItem("bookingReferenceId", booking_ref_id);
  //   localStorage.setItem("statusOfSitterAllocation", status)
  //   localStorage.setItem("s1", "Sitter Allocations")
  //   localStorage.setItem("cancellationBookingReferenceId",_id)

  // }

  const handleReferenceID = (booking_ref_id, _id , status) => {
    setShow(true);
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", _id);
        localStorage.setItem("statusOfSitterAllocation", status)
    localStorage.setItem("s1", "Sitter Allocations")

  }


  return (
    <>
    { show ?     
    ( <CustomerOrderDetails setShow={setShow} />) : 
    (    
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Sitter Allocation
          </Typography>
          <BackButton />
        </Stack>
        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField fullWidth label="Search user name" id="fullWidth"
              InputProps={{
                style: { margin: '6px 2px', height: '34px' },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer' }}
                  // onClick={handleSearch}
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: { fontSize: '16px', marginTop: '-4px' },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
            // onKeyDown={handleKeyPress}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={4}>

              {/* <RangePicker onChange={datefilterhandleChange} 
                  defaultValue={[defaultStartDate, defaultEndDate]}
              /> */}

              <RangePicker
                defaultValue={[defaultStartDate, defaultEndDate]}
                open={popupOpen}
                onOpenChange={(open) => {
                  if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                    setPopupOpen(true);
                  } else {
                    setPopupOpen(open);
                  }
                }}
                onChange={datefilterhandleChange}
                renderExtraFooter={() => (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                    <Button onClick={() => { setPopupOpen(false); sitter(); }} variant='contained' size='small' >Submit</Button>
                  </Box>
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={2} md={2} lg={2}>
                <SitterAllocationAllData setData={setData}/>
            </Grid> */}

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Select
                style={{ margin: '0px 0px  0px 2px', height: '34px', }}
                labelId="allocation-label"
                id="allocation"
                value={selectedDate}
                onChange={handleChangeDate}
                inputProps={{ 'aria-label': 'Select' }}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="created_at" selected>Booking Date</MenuItem>
                <MenuItem value="date">Start Date</MenuItem>
                {/* <MenuItem value="reschedule_date">Reschedule Date</MenuItem> */}

              </Select>
            </Grid>
          </Grid>


        </Stack>

        <Card>
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                  )}
                </Typography>
              ) : (
                <Table size='small'>
                  <SitterGroomingListHead
                    order={order}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData?.map((row) => {
                      const {
                        _id,
                        user_name,
                        grooming_book_services_id,
                        pet_name,
                        number_of_pets,
                        target_pet,
                        service_name,
                        booking_date,
                        date,
                        time_slot,
                        payment_method,
                        price_paid,
                        add_ons,
                        package_title,
                        reschedule_date,
                        reschedule_status,
                        reschedule_time_slot,
                        user_contact,
                        location,
                        number_of_sessions,
                        completed_sessions,
                        ongoing_session_number,
                        remaining_sessions,
                        offer_description,
                        offer_count,
                        offer_remaining,
                        subscription,
                        service_master_id,
                        pay_on_service,
                        address,
                        booking_ref_id,
                        status,
                        session_ref_id,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;


                      return (
                        <TableRow
                          hover
                          key={grooming_book_services_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id, status)}>
                              {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}
                            </Link>
                          </TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }} onClick={() => handleReferenceID(booking_ref_id, _id)} >
                              {/* <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}> */}
                                {/* {booking_ref_id} */}
                               <span className='booking-ref-ids'> {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}</span>
                              {/* </Link> */}
                            </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{user_name}</TableCell>
                          {/* <TableCell sx={{ textAlign: 'center' }}>{grooming_book_services_id.slice(-4)}</TableCell> */}
                          {/* <TableCell sx={{ textAlign: 'center' }} >{pet_name.join(' , ')}</TableCell> */}
                          {/* <TableCell sx={{ textAlign: 'center' }}>{number_of_pets}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{target_pet.join(' , ')}</TableCell>
                          {/* <TableCell>
                            <Typography sx={{ textAlign: 'center' }} wordWrap>{service_name}</Typography>
                          </TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{booking_date ? moment(booking_date).format('DD-MM-YYYY') : booking_date}</TableCell>
                          {/* <TableCell sx={{ fontSize: '10pt' }}>{moment(date).format('YYYY-MM-DD')}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{moment(date != null ? date : "").format('DD-MM-YYYY')}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <Typography wordWrap>
                              {time_slot}
                            </Typography>
                          </TableCell>
                          {/* <TableCell sx={{  textAlign: 'center' }}>{payment_method === '' ? 'Pay after service' : payment_method}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{price_paid}</TableCell>
                          {/* <TableCell sx={{  textAlign: 'center' }}>{payment_method === '' ? 'Not Paid' : 'Paid'}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}</TableCell>
                          {/* <TableCell component="th" scope="row" padding="none" sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {add_ons.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </Stack>
                          </TableCell> */}
                          <TableCell>
                            <Typography sx={{ textAlign: 'center' }} wordWrap>
                              {package_title}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_date ? moment(reschedule_date).format('DD-MM-YYYY') : reschedule_date}</TableCell> */}
                          {/* <TableCell align="left" sx={{  textAlign: 'center' }}>{reschedule_status === "approved" || "pending" ? moment(reschedule_date).format('DD-MM-YYYY') : reschedule_date}</TableCell> */}
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_status}</TableCell> */}
                          {/* <TableCell align="left" sx={{  textAlign: 'center' }}>{reschedule_time_slot}</TableCell> */}
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_status === "approved" ? time_slot : reschedule_time_slot}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{user_contact}</TableCell> */}
                          {/* <TableCell sx={{ textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location?.coordinates[0]},${location?.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell> */}
                          {/* <TableCell sx={{ textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{completed_sessions}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{ongoing_session_number}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{remaining_sessions}</TableCell> */}
                          {/* <TableCell sx={{ textAlign: 'center', maxWidth: '100px', maxHeight: '50px', wordWrap: 'break-word' }}>
                            {number_of_sessions === 1 ? (
                              "-"
                            ) : (
                              <Tooltip title={offer_description} arrow>
                                <span>
                                  {offer_description.length > 20 ? offer_description.slice(0, 20) + "..." : offer_description}
                                </span>
                              </Tooltip>
                            )}
                          </TableCell> */}
                          {/* <TableCell sx={{  textAlign: 'center', maxWidth: '100px', maxHeight: '50px', wordWrap: 'break-word' }}>{number_of_sessions === 1 ? "-" : offer_description}</TableCell> */}
                          {/* <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions === 1 ? "-" : offer_count}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions === 1 ? "-" : offer_remaining}</TableCell> */}
                          <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions > 1 ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="right" sx={{ textAlign: 'center', position: 'sticky', right: '5px', overflowX: 'hidden', overflowY: 'hidden' }}>
                            <SitterGroomingMoreMenu
                              rowData={row}
                              data={service_master_id}
                              // dataDate={moment(date !=null?date:"2022-11-26T00:00:00.000Z").format('YYYY-MM-DD')}
                              dataDate={moment(date != null ? date : "").format('DD-MM-YYYY')}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
            component="div"
            // count={data.length}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
    )}
    </>
  );
}
