/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { ServiceMasterListHead, ServiceMasterMoreMenu } from '../../sections/@dashboard/serviceMaster';
// mock
import { getGroomingReschduleData, getServiceMasterData, getSessionCount} from '../../common/api-service';
import BackButton from '../../components/BackButton';
import { ThemeContext } from '../../App';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function ServiceMaster() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const service = () => {
    setLoading(true);
    return getServiceMasterData().then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const { sitterAllocationCount, setSitterAllocationCount } = useContext(ThemeContext);
  const { cancellationRequestCount, setCancellationRequestCount } = useContext(ThemeContext);
  const { rescheduleRequestCount, setRescheduleRequestCount } = useContext(ThemeContext);
  // const { notificationMsg,setNotificationMsg } = useContext(ThemeContext);
  const sessionscount = () => {
    return getSessionCount().then(
      (res) => {
        const [requested_sessions_count, rescheduled_requests_count, cancellation_requests_count] = res.data.data;
        setSitterAllocationCount(requested_sessions_count.requested_sessions_count);
        setRescheduleRequestCount(rescheduled_requests_count.rescheduled_requests_count);
        setCancellationRequestCount(cancellation_requests_count.cancellation_requests_count);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    service();
    sessionscount();
  // }, [offset,notificationMsg]);
}, [offset]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Page title="User">
      <Container>
        <BackButton />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h5" gutterBottom>
            Service Master
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/addserviceMaster"
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{ marginTop: '1rem', float: 'right' }}
          >
            Add Service Master
          </Button>
        </Stack>

        <Card>
        {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                      <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                    ) : (
                      <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                    )}
                </Typography>
              ) : (
                <Table stickyHeader aria-label="sticky table">
                  <ServiceMasterListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { _id, title, status } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row" sx={{ textAlign: 'center', padding:'1px' }}>
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center', padding:'1px' }}>
                            <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || status === 'active' ? 'success' : 'error'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>

                          <TableCell align="right" sx={{ textAlign: 'center', padding:'1px' }}>
                            <ServiceMasterMoreMenu data={_id} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
