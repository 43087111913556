/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Box from '@mui/material/Box';
import { getCalendarDataForAllocate, groomingSitterAllocate } from '../../../common/api-service';

const headerOptions = {
  left: 'prev,next',
  center: 'title',
  right: 'dayGridMonth,timeGridWeek',
};

const GroomingEventCalender = ({ eventsCalenderData, allocateData }) => {
  const [event, setEvent] = useState(null);
  const [data, setData] = useState(eventsCalenderData);
  const [eData, setEData] = useState(allocateData);
  const [open, setOpen] = React.useState(false);
  const [allocationData, setAllocateData] = useState([]);

  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80vh',
    width: '80vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };

  const handleOpen = (arg) => {
    setEvent(arg.event);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const setDate = (day, hour) => {
    const date = new Date();

    date.setDate(date.getDate() + day);
    date.setHours(date.getHours() + hour);

    return date;
  };

  useEffect(() => {}, []);

  const handleAllocate = (card, index) => {

    const payload = {
      type: eData.service_name,
      sitter_id: eData._id,
      _id: eData._id,
      book_service_id: eData.book_services_id,
    };
    return groomingSitterAllocate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setAllocateData(data);
        localStorage.removeItem('getGroomingAllocationID');
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        navigate('/dashboard/groomingSitterAllocation', { replace: true });
      } else {
        console.log('error');
      }
    });
  };

  function getTwentyFourHourTime(abc) {
    var timeSlotDate = abc;
    var myDate = timeSlotDate.split('-');

    for (let i = 0; i < myDate.length; i++) {
      var obj = {
        start: moment(myDate[0], ['h:mm A']).format('HH:mm:ss'),
        end: moment(myDate[1], ['h:mm A']).format('HH:mm:ss'),
      };
    }
    return obj;
  }
  var events = eventsCalenderData?.map((e) => {
    var convertTime24Hour = e && e?.time_slot ? getTwentyFourHourTime(e?.time_slot) : '00:00:00';

    let dtconvert = moment(e.date).format('YYYY-MM-DD');

    return {
      title: e.service_name,
      start: dtconvert + 'T' + convertTime24Hour.start,
      end: dtconvert + 'T' + convertTime24Hour.end,
      color: '#5cb85c',
    };
  });

  let modalBody, modalTitle, modalFooter;

  if (event) {
    modalBody = (
      <div className="d-flex flex-column">
        <div className="event-timeSlot flex-column mb-4">
          <h5 className="event-title m-0">Time</h5>
          <span>{event.timeSlot}</span>
        </div>
        <div className="event-time flex-column mb-4">
          <h5 className="event-title m-0">Date</h5>
          <span>{moment(event.start).format('YYYY-MM-DD')}</span>
        </div>

        <div className="event-desc flex-column">
          <h5 className="event-title m-0"> reason</h5>
          <span>{event.extendedProps.desc}</span>
        </div>
      </div>
    );

    modalTitle = (
      <div className="title-block p-0 m-0">
        <h3 style={{ color: event.backgroundColor }} className="modal-title m-0">
          Appointment
        </h3>
        <hr />
      </div>
    );
    {
      /* {/ <Button type='primary'>Change event</Button> /} */
    }
    //   </div>
    // );
  }

  return (
    <>
      <Box sx={style}>
        <FullCalendar
          eventClick={handleOpen}
          events={events}
          eventBackgroundColor="#5cb85c"
          headerToolbar={headerOptions}
          initialView="timeGridWeek"
          plugins={[timeGridPlugin, dayGridPlugin]}
          dayMaxEvents={true}
          height= '100%'
          weekends
          scrollToTime
        />
        <Button variant="contained" onClick={(e) => handleAllocate()} sx={{ mt: 2 }}>
          Allocate
        </Button>
      </Box>
    </>
  );
};

export default GroomingEventCalender;
