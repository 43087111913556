import React, { useEffect, useState } from 'react';
import {
  Typography,
  Chip,
  Container,
  Box,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
  ThemeProvider,
  Tooltip,
  Button,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MapIcon from '@mui/icons-material/Map';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SitterGroomingMoreMenu } from '../../sections/@dashboard/sitterAllocationGrooming';

import BackButton from '../../components/BackButton';
import { getOrderDetailsData, getAllSessionsByBookingId, getSessionData } from '../../common/api-service';
import Scrollbar from '../../components/Scrollbar'
import { BookingHistoryListHead } from '../../sections/@dashboard/bookingHistory'
import BasicBreadcrumbs from './BreadCrumb';
import SessionsStatus from './SessionsStatus';




//------------------------------------------------------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'session_id', label: 'Session No.', alignRight: true },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },

  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'sitter_contact', label: 'Sitter Contact', alignRight: false },

  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'reschedule_date', label: 'Reschedule date', alignRight: false },



  { id: 'session_status', label: 'Status', alignRight: false },

  // { id: 'pet_type', label: 'Sitter Name', alignRight: false },



  { id: '' },
];


const CustomerOrderDetails = () => {
  const [orderDetailsdata, setOrderDetailsdata] = useState([]);
  const [addonPrice, setAdddonPrice] = useState();


  const upcomingrefID = localStorage.getItem('upcomingRefId');

  const getOrderDetails = () => {
    const payload = localStorage.getItem('bookingReferenceId');
    return getOrderDetailsData(payload).then((res) => {
      setOrderDetailsdata(res.data.booking_details);
    });
  };

  const [sessionDetailsdata, setSessionDetailsdata] = useState([]);

  const getSessionsData = () => {
    // const payload = localStorage.getItem('bookingReferenceId');
    {
      orderDetailsdata.map((bookId) => {
        const payload = `${bookId.grooming_book_services_id}`
        return getAllSessionsByBookingId(payload).then((res) => {
          setSessionDetailsdata(res.data.data);
        });
      })
    }

  };

  const [filteredOrders, setFilteredOrders] = useState({});

  const filterGroomingSessions = () => {
    const filteredOrders = orderDetailsdata.map(order => ({
      ...order,
      grooming_sessions: order.grooming_sessions.filter(session => session._id === upcomingrefID)
    }));
    setFilteredOrders(filteredOrders);
  };

  useEffect(() => {
    filterGroomingSessions();
    getSessionsData();

  }, [orderDetailsdata]); // Trigger filtering whenever orderDetailsdata changes

  const [cancelOrederData, setCancelOrederData] = useState({});

  const getSessionsDetails = () => {
    const payload = localStorage.getItem('cancellationBookingReferenceId');
    return getSessionData(payload).then((res) => {
      setCancelOrederData(res.data.data);
    });
  };

  useEffect(() => {
    getOrderDetails();
    getSessionsDetails();
  }, []);

  const theme = createTheme({
    palette: {
      divider: '#000000',
    },
  });


  return (
    <>
      <BasicBreadcrumbs />
      <BackButton />
      {orderDetailsdata.map((order) => {
        const truncatedDescription =
          order?.offer_description?.length > 20 ? `${order?.offer_description?.slice(0, 20)}...` : order?.offer_description;
        return (
          <>
            {/* {cancelOrederData?.map((cancelOrder) => { */}
            {Array.isArray(cancelOrederData) && cancelOrederData.map((cancelOrder) => {
              return (
                <>
                  <Container sx={{ display: 'flex' }}>
                    <Typography variant="h4">Booking ID #
                      {/* {order.booking_ref_id} */}
                      {/* {order.grooming_sessions[order.ongoing_session_number - 1].session_ref_id} */}
                      {/* {cancelOrder.session_ref_id} */}
                      {cancelOrder.number_of_sessions === 1 ? cancelOrder.session_ref_id.split('-')[0] : cancelOrder.session_ref_id}



                    </Typography>
                    {cancelOrder.payment_method === '' ? (
                      <Chip
                        icon={<FiberManualRecordIcon style={{ color: '#d50000' }} />}
                        label="Not Paid"
                        size="small"
                        style={{
                          color: '#d50000',
                          backgroundColor: '#ef9a9a',
                          marginTop: '1%',
                          height: 'fit-content',
                          marginLeft: '2%',
                        }}
                      />
                    ) : (
                      <Chip
                        icon={<FiberManualRecordIcon style={{ color: '#00a152' }} />}
                        label="Paid"
                        size="small"
                        style={{
                          color: '#00a152',
                          backgroundColor: '#a5d6a7',
                          marginTop: '1%',
                          height: 'fit-content',
                          marginLeft: '2%',
                        }}
                      />
                    )}

                    <span style={{

                      marginTop: '0.6%',
                      height: 'fit-content',
                      marginLeft: '1%',
                    }}>
                      <SessionsStatus sessStatus={cancelOrder.session_status} />
                    </span>


                    <CalendarMonthIcon fontSize="small" sx={{ marginTop: '1%', marginLeft: '2%' }} />
                    <Typography sx={{ marginTop: '1%', marginRight: '4%' }}>
                      {/* {moment(order.start_date).format('DD-MM-YYYY')} */}
                      {moment(cancelOrder.date).format('DD-MM-YYYY')}
                      <span style={{ marginLeft: '15px', fontSize: '15px' }}>
                        {cancelOrder.reschedule_status === 'approved' ? cancelOrder.reschedule_date : cancelOrder.time_slot}
                      </span>


                    </Typography>

                    {/* {/ <span style={{ display: `${order.grooming_sessions[order.ongoing_session_number - 1].on_the_way === true || order.request_for_rescheduled === true || order.grooming_sessions[order.ongoing_session_number - 1].status === 'cancelled' ? 'none' : 'flex'}` }}> /} */}
                    <SitterGroomingMoreMenu
                      // rowData={order}
                      // rowData={sessionDetailsdata[order.ongoing_session_number - 1]}
                      rowData={cancelOrder}
                      // rowData={sessionDetailsdata[order.ongoing_session_number - 1]}
                      // rowData1={filteredOrders}
                      data={cancelOrder.service_master_id}
                      // dataDate={moment(date !=null?date:"2022-11-26T00:00:00.000Z").format('YYYY-MM-DD')}
                      dataDate={moment(order.start_date != null ? order.start_date : '').format('DD-MM-YYYY')}
                    />
                    {/* {/ </span> /} */}




                  </Container>

                  <Box
                    sx={{
                      display: 'flex',
                      color: 'white',
                      fontSize: 'small',
                      backgroundColor: '#006064',
                      maxWidth: 'fit-content',
                      padding: '3px 10px',
                      margin: '10px 20px',
                      borderRadius: '5px',
                    }}
                  >
                    <MapIcon />
                    <a
                      style={{ textDecoration: 'none', color: 'white', marginTop: '1.5%' }}
                      href={`https://www.google.com/maps?q=${cancelOrder.location?.coordinates[0]},${cancelOrder.location?.coordinates[1]}`}
                      // href={`https://www.google.com/maps?q=3,5`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View location on Map
                    </a>
                  </Box>



                  <Box ml={2} mt={3}>
                    <Typography variant="h4">Booking Details</Typography>
                    <Divider orientation="horizantal" />
                    <Grid container spacing={2} mt={0.01}>
                      <Grid item sm={6} xs={12} md={6} lg={6} sx={{ borderRight: '1px solid #b0bec5' }}>
                        <Typography><span className='typo-bold-book-order'>Customer Name :</span> {cancelOrder.parent_name} </Typography>
                        <Typography><span className='typo-bold-book-order'>Pet Type : </span>{cancelOrder?.target_pet[0]} </Typography>
                        <Typography><span className='typo-bold-book-order'>Pet Name : </span>{cancelOrder.pet_name}</Typography>
                        <Typography><span className='typo-bold-book-order'>Customer Contact Number :</span> {order.parent_phone_no} </Typography>
                        <Typography><span className='typo-bold-book-order'>Booking Date :</span>
                          {/* {moment(order.booking_date).format('DD-MM-YYYY')}  */}
                          {moment(cancelOrder.created_at).format('DD-MM-YYYY')}

                        </Typography>


                      </Grid>
                      <Grid item sm={6} xs={12} md={6} lg={6}>
                        <Typography><span className='typo-bold-book-order'>Address :</span>
                          {cancelOrder.address}
                        </Typography>
                        <Typography><span className='typo-bold-book-order'>
                          Method of Payment :</span> {cancelOrder.pay_on_service === true ? `Pay after service ${cancelOrder.payment_method === '' ? ' ' : `(${cancelOrder.payment_method})`}` : 'Online'}{' '}
                        </Typography>
                        <Typography><span className='typo-bold-book-order'>Sitter Name :</span>
                          {cancelOrder.sitter_name}
                        </Typography>
                        <Typography><span className='typo-bold-book-order'>Sitter Contact Number : </span>{cancelOrder.sitter_contact} </Typography>
                        <Typography><span className='typo-bold-book-order'>
                          Booking Type :</span>{' '}
                          {cancelOrder.number_of_sessions === 1
                            ? 'One Time'
                            : `Subscription`}{' '}
                        </Typography>

                      </Grid>
                    </Grid>
                    <Divider orientation="horizantal" />
                  </Box>


                  {/* ------------------------------------------------------------------------------------------------------------------------------ */}

                  <Box ml={2} mt={4}>
                    <Divider orientation="horizontal" />
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Service Name</TableCell>
                            <TableCell align="center">Package Name</TableCell>
                            <TableCell align="center">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">{cancelOrder.service_name}</TableCell>
                            <TableCell align="center">{cancelOrder.package_title}</TableCell>
                            <TableCell align="center">
                              {cancelOrder.number_of_sessions === 1
                                ? cancelOrder.package_price
                                : cancelOrder.srno === 1 ? cancelOrder.package.subscription_sessions_for_grooming[0].subscription_price : '-'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">{"Add on's"}</TableCell>
                            <TableCell align="center">
                              {order.add_ons.length === 0
                                ? ' - '
                                : order.add_ons.map((d, i) => (
                                  <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                                    {d.title}
                                  </Typography>
                                ))}
                            </TableCell>
                            <TableCell align="center">
                              {/* {order.add_ons.length === 0
                          ? '0'
                          : order.add_ons
                            .map((d, i) => (
                              <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                                {d.price}
                              </Typography>
                            ))
                            .reduce((total, current) => total + parseInt(current.props.children, 10), 0)} */}
                              {cancelOrder.addon_total}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">{'Free Services'}</TableCell>
                            {/* <TableCell align="center">{order.offer_description}</TableCell> */}
                            <TableCell align="center">
                              {order.number_of_sessions === 1 ? (
                                '-'
                              ) : (
                                <Tooltip title={order.offer_description} arrow>
                                  <span>{truncatedDescription}</span>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="center">{'0'}</TableCell>
                          </TableRow>
                          <ThemeProvider theme={theme}>
                            <TableRow>
                              {/* <TableCell colSpan={3} align="center">
                          <Divider variant="middle" />
                        </TableCell> */}
                            </TableRow>
                          </ThemeProvider>
                          <TableRow>
                            <TableCell align="center"> </TableCell>
                            <TableCell align="right">{'Total'}</TableCell>
                            <TableCell align="center">
                              {cancelOrder.srno === 1 ?
                                parseInt(
                                  (cancelOrder.number_of_sessions === 1
                                    ? parseInt(cancelOrder.package_price, 10)
                                    : parseInt(cancelOrder.package.subscription_sessions_for_grooming[0].subscription_price, 10)) +
                                  (cancelOrder.addon_total === '0'
                                    ? 0
                                    : cancelOrder.add_ons?.reduce((total, current) => total + parseInt(current.price, 10), 0))
                                )

                                :
                                cancelOrder.addon_total

                              }



                              {/* {cancelOrder.addon_total === '0' ?
                        cancelOrder.package_price
                      :
                      parseInt(cancelOrder.package_price, 10) + parseInt(cancelOrder.addon_total, 10)
                      } */}

                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center"> </TableCell>
                            <TableCell align="right">{'Coupon discount'}</TableCell>
                            <TableCell align="center">
                              {/* {order.coupon_discount ? `${order.coupon_discount} ${order.coupon_discount_type === 'percentage' ? ' %' : ' Rs'}` : '0'} */}

                              {cancelOrder?.coupon?.couponId !== null ? `${cancelOrder?.coupon?.discountAmount} Rs`
                                //  ${order?.coupon_discount_type === 'percentage' ? ' %' : ' Rs'}
                                : '0'}

                            </TableCell>
                          </TableRow>
                          {/* <ThemeProvider theme={theme}>
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Divider variant="middle" />
                        </TableCell>
                      </TableRow>
                    </ThemeProvider> */}
                          <TableRow>
                            <TableCell align="center"> </TableCell>
                            <TableCell align="right" sx={{ fontWeight: '700' }}>Grand Total</TableCell>
                            <TableCell align="center">

                              {/* {order.amount_paid} Rs */}
                              {cancelOrder.srno === 1 ?
                                `${cancelOrder.price_paid}Rs`
                                :
                                cancelOrder?.addon_total === '0' ? '-' : `${cancelOrder.addon_total} Rs`
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Divider orientation="horizontal" />
                  </Box>


                </>




              )

            })}

            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* <Box ml={2} mt={4}>
              <Divider orientation="horizontal" />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Service Name</TableCell>
                      <TableCell align="center">Package Name</TableCell>
                      <TableCell align="center">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{order.service_name}</TableCell>
                      <TableCell align="center">{order.package_title}</TableCell>
                      <TableCell align="center">
                        {order.number_of_sessions === 1
                          ? order.package_offer_price
                          : order.package.subscription_sessions_for_grooming[0].subscription_price}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">{"Add on's"}</TableCell>
                      <TableCell align="center">
                        {order.add_ons.length === 0
                          ? ' - '
                          : order.add_ons.map((d, i) => (
                            <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                              {d.title}
                            </Typography>
                          ))}
                      </TableCell>
                      <TableCell align="center">
                        {order.add_ons.length === 0
                          ? '0'
                          : order.add_ons
                            .map((d, i) => (
                              <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                                {d.price}
                              </Typography>
                            ))
                            .reduce((total, current) => total + parseInt(current.props.children, 10), 0)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">{'Free Services'}</TableCell>
                      <TableCell align="center">
                        {order.number_of_sessions === 1 ? (
                          '-'
                        ) : (
                          <Tooltip title={order.offer_description} arrow>
                            <span>{truncatedDescription}</span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">{'0'}</TableCell>
                    </TableRow>
                    <ThemeProvider theme={theme}>
                      <TableRow>
                      
                      </TableRow>
                    </ThemeProvider>
                    <TableRow>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="right">{'Total'}</TableCell>
                      <TableCell align="center">
                       
                        {parseInt(
                          (order.number_of_sessions === 1
                            ? parseInt(order.package_offer_price, 10)
                            : parseInt(order.package.subscription_sessions_for_grooming[0].subscription_price, 10)) +
                          (order.add_ons.length === 0
                            ? 0
                            : order.add_ons.reduce((total, current) => total + parseInt(current.price, 10), 0))
                        )}

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="right">{'Coupon discount'}</TableCell>
                      <TableCell align="center">{order.coupon_discount ? `${order.coupon_discount} ${order.coupon_discount_type === 'percentage' ? ' %' : ' Rs'}` : '0'}</TableCell>
                    </TableRow>
                  
                    <TableRow>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="right" sx={{ fontWeight: '700' }}>Grand Total</TableCell>
                      <TableCell align="center">{order.amount_paid} Rs</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider orientation="horizontal" />
            </Box> */}
          </>
        );
      })}





    </>
  );
};

export default CustomerOrderDetails;