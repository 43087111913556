import React from 'react';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const SessionsStatus = ({ sessStatus }) => {
    let chipBColor, chipColor;
    let chipLabel = sessStatus;
    console.log("chipLabel", chipLabel)
    switch (chipLabel) {
        case 'cancelled':
            chipBColor = '#ED4A4A';
            chipColor = 'white';
            break;
        case 'upcoming':
            chipBColor = '#FFAF00';
            chipColor = 'black';
            break;
        case 'completed':
            chipBColor = '#884AED';
            chipColor = 'white';
            break;
        case 'ongoing':
            chipBColor = '#359508';
            chipColor = 'white';
            break;
        default:
            chipBColor = 'default';
            chipColor = 'default';
    }

    return (
        <div>
            <Chip
                // icon={<FiberManualRecordIcon style={{ color: `${chipColor}` }} />}
                label={chipLabel}
                size='small'
                //   color={chipColor}
                style={{ backgroundColor: `${chipBColor}`, color: `${chipColor}`, textTransform: 'capitalize',height: 'fit-content',
                 }}
            />
        </div>
    );
};

export default SessionsStatus;