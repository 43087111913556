/* eslint-disable */
/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import moment from 'moment';
// material
import { Table, TableRow, TableBody, TableCell, TableContainer, Stack, Breadcrumbs } from '@mui/material';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import BackButton from '../../../components/BackButton';
import GroomingEventCalender from './GroomingEventCalender';
import { EventCalendarListHead, EventCalendarListToolbar } from '../../../sections/@dashboard/eventCalendar';
import {
  getGroomingSitterAllocationDataForAllocate,
  getCalendarDataForAllocate,
  groomingSitterAllocate,
  getSessionCount,
} from '../../../common/api-service';
import { useContext } from 'react';
import { ThemeContext } from 'src/App';
import '../../../css/swal.css';
import BasicBreadcrumbs from '../BreadCrumb';

function GroomingAllocationPage() {
  const { rowData } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  // console.log('phonedata :', data.sitters.phone);
  const [eventsData, setEventsData] = useState([]);
  const [showAllTimeSlots, setShowAllTimeSlots] = useState(false);

  const getGroomingCalendarAllocationData = JSON.parse(localStorage.getItem('groomingCalenderData'));

  const [eData, setEData] = useState(getGroomingCalendarAllocationData);
  const [allocateData, setAllocateData] = useState([]);

  const { sitterAllocationCount, setSitterAllocationCount } = useContext(ThemeContext);
  const { cancellationRequestCount, setCancellationRequestCount } = useContext(ThemeContext);
  const { rescheduleRequestCount, setRescheduleRequestCount } = useContext(ThemeContext);

  const { petBoardingCount, setPetBoardingCount } = useContext(ThemeContext);
  const { dogWalkingCount, setDogWalkingCount } = useContext(ThemeContext);
  const { petSittingCount, setPetSittingCount } = useContext(ThemeContext);
  const { dogTrainingCount, setDogTrainingCount } = useContext(ThemeContext);
  const { otherCount, setOtherCount } = useContext(ThemeContext);

  const TABLE_HEAD = [
    { id: 'user_name', label: 'User Name', alignRight: false },
    { id: 'book_services_id', label: 'Booking No', alignRight: false },
    { id: 'service_name', label: 'Service', alignRight: false },
    { id: 'date', label: 'Start Date', alignRight: false },
    { id: 'time_slot', label: 'Time Slot', alignRight: false },
    // { id: 'number_of_pets', label: 'Number of Pets', alignRight: false },
    { id: 'number_of_sessions', label: 'Sessions', alignRight: false },
    { id: 'package_title', label: 'Package', alignRight: false },
    { id: 'target_pet', label: 'Pet Type', alignRight: false },
    { id: 'subscription', label: 'Subscription', alignRight: false },
    { id: '' },
  ];

  const updatecount = () => {

    getSessionCount().then(
      (res) => {
        const [requestedSessionsCount, rescheduledRequestsCount, cancellationRequestsCount,
          petboardingcount, dogwalkingcount, petsittingcount, dogtrainingcount,
          othercount] = res.data.data;
        setSitterAllocationCount(requestedSessionsCount.requested_sessions_count);
        setRescheduleRequestCount(rescheduledRequestsCount.rescheduled_requests_count);
        setCancellationRequestCount(cancellationRequestsCount.cancellation_requests_count);
        setPetBoardingCount(petboardingcount.pet_boarding_count);
        setDogWalkingCount(dogwalkingcount.dog_walking_count);
        setPetSittingCount(petsittingcount.pet_sitting_count);
        setDogTrainingCount(dogtrainingcount.dog_training_count);
        setOtherCount(othercount.others_count);
      },
      (err) => {
        console.log('err data: ', err);
      });

  }

  // const handleAllocate = (card, index) => {
  //   const payload = {
  //     type: eData.service_name,
  //     sitter_id: card.sitters._id,
  //     _id: eData._id,
  //     book_service_id: eData.grooming_book_services_id,
  //   };
  //   return groomingSitterAllocate(payload).then((res) => {
  //     if (res.data.status === true) {
  //       const data = res.data.data;
  //       setAllocateData(data);
  //       localStorage.removeItem('getGroomingAllocationID');
  //       swal({
  //         title: res.data.message,
  //         icon: 'success',
  //         timer: 2000,
  //         button: false,
  //       });
  //       navigate('/dashboard/groomingSitterAllocation', { replace: true });
  //       updatecount();
  //     } else {
  //       console.log('error');
  //     }
  //   });
  // };

  const p1 = localStorage.getItem('s1');

  const handleAllocate = (card, index) => {

    const id = eData._id;
    // const id = eData._id === eData.grooming_book_services_id ? eData.grooming_sessions[0]._id : eData._id;

    const payload = {
      type: eData.service_name,
      sitter_id: card.sitters._id,
      _id: id,
      book_service_id: eData.grooming_book_services_id,
    };

    // Function to show confirmation dialog
    const showConfirmation = () => {
      return Swal.fire({
        title: "Are you sure you want to allocate " + `${card.sitters.name}` + " as a sitter?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: 'red',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });
    };


    // Call confirmation dialog
    showConfirmation().then((confirmed) => {
      if (confirmed === true) { // User clicked Yes
        return groomingSitterAllocate(payload).then((res) => {
          if (res.data.status === true) {
            const data = res.data.data;
            setAllocateData(data);
            localStorage.removeItem('getGroomingAllocationID');
            swal({
              title: res.data.message,
              icon: 'success',
              timer: 2000,
              button: false,
            });
            navigate('/dashboard/groomingSitterAllocation', { replace: true });
            updatecount();
          } else {
            console.log('error');
          }
        });
      } else { // User clicked No or closed the dialog
        console.log("Allocation canceled.");
      }
    });
  };


  const getGroomingAllocationID = () => {
    const getGroomingAllocationID = localStorage.getItem('getGroomingAllocationID');
    const getGroomingAllocationDate = localStorage.getItem('getGroomingAllocationDate');
    // console.log('getGroomingAllocationDate', getGroomingAllocationDate)

    const selectedGroomingAllocationRow = localStorage.getItem('selectedGroomingAllocationRow');

    const payload = {
      service_master_id: getGroomingAllocationID,
      start_date: getGroomingAllocationDate.split('-').reverse().join('-'),
    };
    return getGroomingSitterAllocationDataForAllocate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setData(data);
      } else {
        console.log('error');
      }
    });
  };
  useEffect(() => {
    getGroomingAllocationID();
  }, []);

  const {
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };
  const [open, setOpen] = React.useState(false);

  const getGroomingCalendarID = () => {
    const getGroomingCalendarID = localStorage.getItem('getGroomingCalendarID');
    const payload = {
      sitter_id: getGroomingCalendarID,
    };
    if (getGroomingCalendarID) {
      return getCalendarDataForAllocate(payload).then((res) => {
        if (res.data.status === true) {
          const data = res.data.data;
          setEventsData(data);
        } else {
          console.log('error');
        }
      });
    }
  };

  const handleClick = (sitter_id) => {
    localStorage.setItem('getGroomingCalendarID', sitter_id);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* <BasicBreadcrumbs/> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to={''}>
          {p1}
        </Link>
        <Link underline="hover" color="inherit" to={''}>
          Grooming Sitter Allocation
        </Link>
      </Breadcrumbs>
      <Page title="User">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Grooming Sitter Allocation
            </Typography>
            <BackButton />
          </Stack>
          <Card style={{ height: '210px' }}>
            <EventCalendarListToolbar />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <EventCalendarListHead headLabel={TABLE_HEAD} rowCount={eData.length} />
                  <TableBody>
                    <TableRow hover key={eData.book_services_id} tabIndex={-1}>
                      {/* <TableCell align="center">{eData.user_name}</TableCell> */}
                      <TableCell align="center">{eData.user_name ? eData.user_name : eData.customer_name}</TableCell>
                      <TableCell align="center" component="th" scope="row" padding="none">
                        {/* {eData.grooming_book_services_id.slice(-4)} */}
                        {eData.number_of_sessions || eData.no_of_sessions <= 1 ? eData.booking_ref_id || (eData.session_ref_id).split('-') : eData.session_ref_id}
                      </TableCell>
                      <TableCell align="center">
                        <Typography sx={{ fontSize: '10pt' }} wordWrap>
                          {eData.service_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {/* <Typography sx={{ fontSize: '10pt' }}>{moment(eData.date).format('YYYY-MM-DD')}</Typography> */}
                        <Typography sx={{ fontSize: '10pt' }}>{eData.request_for_rescheduled && eData.reschedule_date ? moment(eData.reschedule_date).format('YYYY-MM-DD') : moment(eData.date).format('YYYY-MM-DD')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography sx={{ fontSize: '10pt' }} wordWrap>
                          {/* {eData.time_slot} */}
                          {eData.request_for_rescheduled && eData.reschedule_time_slot ? eData.reschedule_time_slot : eData.time_slot}
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="left">
                        <Typography sx={{ fontSize: '10pt' }}>{eData.number_of_pets}</Typography>
                      </TableCell> */}
                      <TableCell align="center">
                        {/* <Typography sx={{ fontSize: '10pt' }}>{eData.number_of_sessions}</Typography> */}
                        <Typography sx={{ fontSize: '10pt' }}>{eData.number_of_sessions ? eData.number_of_sessions : eData.no_of_sessions}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography sx={{ fontSize: '10pt' }}>{eData.package_title}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography sx={{ fontSize: '10pt' }}>{eData.pet_type ? eData.pet_type : eData.target_pet[0]}</Typography>
                        {/* <Typography sx={{ fontSize: '10pt' }}>{eData.target_pet[0]}</Typography> */}
                      </TableCell>
                      <TableCell align="center">
                        <Typography sx={{ fontSize: '10pt' }}>{eData.number_of_sessions ? eData.number_of_sessions > 1 ? 'Yes' : 'No' : eData.no_of_sessions === 1 ? 'No' : 'Yes'}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
      <Box sx={{ width: '100%' }}>
        <Grid container direction="row" spacing={2} sx={{ overflow: 'auto', minHeight: '80vh' }}>
          {data?.map((card, index) => (
            <Grid item xs={6}>
              <Card
                sx={{
                  margin: 2,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid>
                  <CardContent>
                    <Grid>
                      <Typography sx={{ fontWeight: 'bold' }}>{card.sitters.name}</Typography>
                    </Grid>
                    <Grid xs={1} sx={{ textAlign: 'justify' }}>
                      <Typography sx={{ textAlign: 'right', fontWeight: 'bold', mb: 1 }}>{card.city}</Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        {/* <Typography>Booked Time Slot</Typography>
                        {card.sessions.map((sessions) => (
                          <Typography sx={{marginTop:'10px'}}>{sessions.time_slot}</Typography>
                        ))} */}
                        <Typography>Booked Time Slot</Typography>
                        {card.sessions.slice(0, showAllTimeSlots ? card.sessions.length : 2).map((sessions, i) => (
                          <Typography key={i} sx={{ marginTop: '10px' }}>{sessions.time_slot}</Typography>
                        ))}
                        {card.sessions.length > 2 && (
                          <Button
                            onClick={() => setShowAllTimeSlots(!showAllTimeSlots)}
                            sx={{ marginTop: '10px' }}
                            size="small"
                            color="primary"
                          >
                            {showAllTimeSlots ? 'Show Less' : 'Show More'}
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {card.service_master?.map((service) => (
                          <Typography>{service}</Typography>
                        ))}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid sx={{ m: 1 }}>
                  <CardActions>
                    <Button variant="contained" onClick={() => handleAllocate(card, index)} size="small" sx={{ mr: 2 }}>
                      Allocate
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        handleClick(card.sitters._id);
                        getGroomingCalendarID();
                        return handleOpen();
                      }}
                    >
                      View Calender
                    </Button>
                  </CardActions>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Grid container>
          <Grid item xs={12}>
            <Button onClick={handleClose} color="error" sx={{ float: 'right' }}>
              X
            </Button>
          </Grid>
          <Grid item sx={{ mt: 1 }} xs={12}>
            <GroomingEventCalender eventsCalenderData={eventsData} allocateData={eData} />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default GroomingAllocationPage;
