import React, { useEffect, useState } from 'react';
import { GoogleMap, Polygon, DrawingManager } from '@react-google-maps/api';
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Backdrop,
  TextField
} from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Page from '../../../components/Page';
import Page from '../components/Page';
import EditableMapWithPolygon from './EditableMapWithPolygon';
import { checkPolygon, deleteGeoFence, editFences, getPolygonList } from 'src/common/api-service';
import Scrollbar from '../components/Scrollbar';
import { useNavigate } from 'react-router-dom';
import { message, Popconfirm } from 'antd';
import { toast } from 'react-toastify';
const MapWithPolygon = () => {
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isAvaiable, setIsAvaiable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [cityName, setCityName] = useState('');
  const [allPolygons, setAllPolygons] = useState([]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [openPolygonData, setOpenPolygonData] = useState({
    name: '',
    id: '',
  });

  const [addCityName, setaddCityName] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log('citynamw', cityName);
  const navigate = useNavigate();

  const handlePolygonComplete = async (polygon) => {
    const paths = polygon.getPath().getArray();
    const polygonCoordinates = paths.map((path) => ({
      lat: path.lat(),
      lng: path.lng(),
    }));

    setPolygons(polygonCoordinates);

    const center = getPolygonCenter(polygonCoordinates);
    const city = await reverseGeocode(center);
    setCityName(city);

    console.log('polygonCoordinates :', polygonCoordinates);
    editExistingPolygon(polygonCoordinates, city, openPolygonData.id);
  };

  const getPolygonCenter = (polygonCoords) => {
    const latSum = polygonCoords.reduce((acc, curr) => acc + curr.lat, 0);
    const lngSum = polygonCoords.reduce((acc, curr) => acc + curr.lng, 0);
    const center = {
      lat: latSum / polygonCoords.length,
      lng: lngSum / polygonCoords.length,
    };
    return center;
  };

  const handlePolygonClick = (polygon) => {
    setSelectedPolygon(polygon);
  };

  //   checkPolygon

  const checkPolygonIsAvailable = () => {
    setLoading(true);

    return checkPolygon().then(
      (res) => {
        console.log('res :', res);
        setIsAvaiable(res.data.is_available);
        if (res.data.is_available == true) {
          fetchPolygonList();
          // getGeoFenceData();
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };
  // const getGeoFenceData = () => {
  //   setLoading(true);

  //   return getGeoFence().then(
  //     (res) => {
  //       console.log('res :', res);
  //       setPolygons(res.data.data.polygon);
  //       setLoading(false);
  //     },
  //     (err) => {
  //       setLoading(false);
  //       console.log('err data: ', err);
  //     }
  //   );
  // };

  const reverseGeocode = async (location) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyBtNweJE_bDxUtdLyBbfDsJB7P7ap3OsCQ`
    );
    const data = await response.json();
    const city = data.results[0].address_components.find((component) => component.types.includes('locality'));
    return city ? city.long_name : '';
  };

  // const addFenceData = (polygonCoordinates, city) => {
  //   setLoading(true);
  //   const payload = {
  //     // "name": "Rajarampuri",
  //     name: city,
  //     polygon: polygonCoordinates,
  //   };
  //   return addFences(payload).then(
  //     (res) => {
  //       console.log('res :', res);
  //       // getGeoFenceData();
  //       fetchPolygonList();
  //       setLoading(false);
  //       setIsAvaiable(true);
  //     },
  //     (err) => {
  //       setLoading(false);
  //       console.log('err data: ', err);
  //     }
  //   );
  // };

  const editExistingPolygon = (polygonCoordinates, city, id) => {
    setLoading(true);
    const payload = {
      // "name": "Rajarampuri",
      name: city,
      polygon: polygonCoordinates,
      id: id,
    };
    return editFences(payload).then(
      (res) => {
        console.log('res :', res);
        // getGeoFenceData();
        fetchPolygonList();
        setLoading(false);
        setIsAvaiable(true);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  const fetchPolygonList = async () => {
    try {
      setLoading(true);
      return await getPolygonList()
        .then((res) => {
          // console.log(res.data.data);
          setAllPolygons(res.data.data);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleViewPolygon = (index) => {
    setOpenPolygonData({
      name: allPolygons[index]?.name,
      id: allPolygons[index]?._id,
    });
    setPolygons(allPolygons[index]?.polygon);
    setIsViewOpen(true);
  };

  const confirmDelete = async (id) => {
    console.log(id);
    try {
      setLoading(true);
      const payload = { id: id };
      return await deleteGeoFence(payload)
        .then((res) => {
          toast.success(res.data.message);
          fetchPolygonList();
        })
        .catch((err) => toast.error(err.message));
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(true);
    }
  };

  const cancelDelete = (e) => {
    toast.success('Delete action cancelled!');
  };

  useEffect(() => {
    checkPolygonIsAvailable();
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  };


  const handleCityNameSubmit = async()=>{
      if(addCityName !== ""){
          // console.log(addCityName)
          navigate(`/dashboard/addGeoFenceArea?city=${addCityName}`);
        }else{
          toast.error("City name required!");
      }
  }

  return (
    <>
      {/* <Button variant="contained"  onClick={()=>{setIsEditable(true)}}>Edit</Button>

     
            <div style={{ height: '600px', width: '100%' }}>
            <GoogleMap
              center={{ lat: 16.7050, lng: 74.2433 }} // Initial map center (New York City coordinates)
              zoom={12} // Initial zoom level
              mapContainerStyle={{ height: '100%', width: '100%' }}
            >
              {polygons.map((polygon, index) => (
                <Polygon
                  key={index}
                  paths={polygon}
                  onClick={() => handlePolygonClick(polygon)}
                />
              ))}
              <DrawingManager
                onLoad={(drawingManager) => {
                  drawingManager.setOptions({
                    drawingMode: 'polygon',
                    drawingControl: true,
                    drawingControlOptions: {
                      drawingModes: ['polygon'],
                    },
                  });
                }}
                onPolygonComplete={handlePolygonComplete}
              />
            </GoogleMap>
         
              </div> */}

      {/* {isEditable ? (<EditableMapWithPolygon />) : ( */}
      <Page title="User">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Geo Fence
            </Typography>
          </Stack>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
          <Box sx={style}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Enter City Name
            </Typography>
            <TextField id="outlined-basic" label="City Name"  size="small" sx={{mt: 2}} variant="outlined" onChange={(e)=> setaddCityName(e.target.value)} />
            <Stack direction="row" gap={3} mt={3}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={handleCityNameSubmit}
                        >
                          Submit
                        </Button>
                      </Stack>
          </Box>
      </Modal>

          {/* {
              isAvaiable &&
              <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
                <Button variant="contained" onClick={() => { setIsEditable(true) }}>Edit</Button>
              </Stack>
            } */}

          <Card sx={{ padding: '1rem' }}>
            {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}

            {isViewOpen ? (
              <>
                {isAvaiable ? (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                      {/* <Button variant="contained" onClick={() => { setIsEditable(true) }}>Edit</Button> */}

                      <Typography variant="h5" ml={3} gutterBottom>
                        {openPolygonData && openPolygonData.name}
                      </Typography>
                      <Stack direction="row" gap={3}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setIsViewOpen(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setIsAvaiable(false);
                          }}
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                    <EditableMapWithPolygon polygons={polygons} />
                  </>
                ) : (
                  <div style={{ height: '600px', width: '100%' }}>
                    <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
                      <Button size="small" variant="contained" onClick={() => setIsAvaiable(true)}>
                        Back To Map
                      </Button>
                    </Stack>
                    <GoogleMap
                      center={{ lat: 16.705, lng: 74.2433 }}
                      zoom={12}
                      mapContainerStyle={{ height: '100%', width: '100%' }}
                    >
                      {polygons.map((polygon, index) => (
                        <Polygon key={index} paths={polygon} onClick={() => handlePolygonClick(polygon)} />
                      ))}
                      <DrawingManager
                        onLoad={(drawingManager) => {
                          drawingManager.setOptions({
                            drawingMode: 'polygon',
                            drawingControl: true,
                            drawingControlOptions: {
                              drawingModes: ['polygon'],
                            },
                          });
                        }}
                        onPolygonComplete={handlePolygonComplete}
                      />
                    </GoogleMap>
                  </div>
                )}
              </>
            ) : (
              <>
                <Scrollbar>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h5" gutterBottom>
                      All Regions
                    </Typography>
                    <Button size="small" variant="contained" onClick={handleOpen} >
                      Add New
                    </Button>
                  </Stack>
                  <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Regine Name</TableCell>
                          <TableCell align="center">Polygon</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allPolygons?.map((poly, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="center">{poly.name}</TableCell>
                              <TableCell align="center">
                                <Stack direction="row" alignItems="center" justifyContent="center" mb={0}>
                                  {/* <Button variant="contained" onClick={() => { setIsEditable(true) }}>Edit</Button> */}
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      handleViewPolygon(index);
                                    }}
                                  >
                                    View
                                  </Button>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Stack direction="row" alignItems="center" justifyContent="center" mb={0}>
                                  <Popconfirm
                                    title="Delete the Geo Fence"
                                    description="Are you sure to delete this Geo Fence?"
                                    onConfirm={() => confirmDelete(poly._id)}
                                    onCancel={cancelDelete}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button size="small" variant="contained" color="error">
                                      Delete
                                    </Button>
                                  </Popconfirm>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </>
            )}
          </Card>
        </Container>
      </Page>
      {/* )} */}
    </>
  );
};

export default MapWithPolygon;
