/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { addServiceMaster } from '../../common/api-service';

function AddServiceMaster() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const serviceMaster_status = ['active', 'disabled'];

  const [AdminNotes, setAdminNotes] = useState('');
  const [serviceMasterDiscription, setServiceMasterDiscription] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [masterIcon, setIcon] = useState('');
  const [serviceMasterStatus, setStatus] = React.useState([]);
  const [data, setData] = useState([]);
  const [baseImage, setBaseImage] = useState('');

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      title: userName,
      description: serviceMasterDiscription,
      admin_notes: AdminNotes,
      icon: baseImage,
      status: serviceMasterStatus,
    };
    return addServiceMaster(payload).then(
      (res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/serviceMaster', { replace: true });
        } else {
          swal({
            title: res.data.message,
            icon: 'error',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        swal({
          title: err,
          icon: 'danger',
          timer: 2000,
          button: false,
        });
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Add Service Master
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Title */}
                  <Grid>
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Discription */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Discription"
                      variant="outlined"
                      value={serviceMasterDiscription}
                      onChange={(e) => setServiceMasterDiscription(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Admin Notes */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={serviceMasterStatus}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {serviceMaster_status.map((serviceMaster_status) => (
                          <MenuItem
                            key={serviceMaster_status}
                            value={serviceMaster_status}
                            style={(serviceMaster_status, serviceMasterStatus, theme)}
                          >
                            {serviceMaster_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Image upload */}
                  <Grid>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography component="div" display="flex" justifyContent="center" alignItems="center">
                        Icon
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <br />
                      <img src={baseImage} alt="" height="200px" />
                    </Stack>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Add Service Master
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default AddServiceMaster;
