import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import Iconify from "./Iconify";
import { useState } from "react";

export default function BackButton({ setShow , setPetShow})
{  const navigate = useNavigate();

  const goToBack=()=>{
    setShow ? setShow(false) :setPetShow?setPetShow(false): navigate(-1)
  //  setPetShow ? setPetShow(false) : null
  }

    return(
        <Box
        m={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={1}
      >
        <Button variant="contained" startIcon={<Iconify icon="eva:arrow-back-outline" />} onClick={ () => goToBack()}>
          Back
        </Button>
      </Box>
    );
}