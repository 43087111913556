import React, { useEffect, useState } from 'react'
import GroomingJourneyListHead from './GroomingJourneyListHead';
import { Card, Table, TableRow, TableBody, TableCell, Container, Typography, TableContainer, TablePagination, TextField, Box, Stack, InputAdornment, Grid, Select, MenuItem, Button } from '@mui/material';
import Page from 'src/components/Page';
import { getGroomingJourneyData } from 'src/common/api-service';
import { CircularProgress } from '@material-ui/core';
import Scrollbar from 'src/components/Scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import CustomerOrderDetails from '../PetGrooming/CustomerOrderDetails';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import CachedIcon from '@mui/icons-material/Cached';
const TABLE_HEAD = [
    { id: 'booking_id', label: 'Booking ID', alignRight: false },
    { id: 'parent_name', label: 'Customer Name', alignRight: false },
    { id: 'pet_type', label: 'Pet Type', alignRight: false },
    { id: 'booking_date', label: 'Booking Date', alignRight: false },
    { id: 'start_date', label: 'Start Date', alignRight: false },
    { id: 'sitter_name', label: 'Sitter name', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'Groomer_slot_time', label: 'Groomer Slot Time', alignRight: false },
    { id: 'navigation_start_time', label: 'Navigation Start Time', alignRight: false },
    { id: 'navigation_end_time', label: 'Navigation End Time', alignRight: false },
    { id: 'navigation_duration', label: 'Navigation Duration', alignRight: false },
    { id: 'grooming_start_time', label: 'Grooming Start Time', alignRight: false },
    { id: 'grooming_end_time', label: 'Grooming End Time', alignRight: false },
    { id: 'grooming_duration', label: 'Grooming Duration', alignRight: false },
]

const GroomingJourneyTable = () => {
    const [page, setPage] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('date');
    const [popupOpen, setPopupOpen] = useState(false);
    const { RangePicker } = DatePicker;

    const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('month'));
    const [defaultEndDate, setDefaultEndDate] = useState(dayjs().endOf('month').endOf('day'));

    const handleChangeDate = (event) => {
        setSelectedDate(event.target.value);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setFilteredData([]);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setFilteredData([])
    };

    const groomingJourney = () => {
        try {
            setLoading(true);
            const payload = page;
            return getGroomingJourneyData(
                payload,
                searchQuery,
                selectedDate,
                rowsPerPage,
                defaultStartDate.add(1, 'day'),
                defaultEndDate.add(1, 'day'),
            ).then(
                (res) => {
                    setData(res.data);
                    setFilteredData(res?.data?.data?.get_paginated)
                    //                     console.log(res?.data);
                    // console.log(res.data);
                },
                (err) => {
                    console.log('err data: ', err);
                }
            );
        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        groomingJourney();
    }, [page, searchQuery, rowsPerPage, selectedDate])


    const handleReferenceID = (_id) => {
        setShow(true);
        localStorage.setItem("cancellationBookingReferenceId", _id);
    }

    const reverseGeocode = async (lat, lng) => {
        // console.log(lat, lng)
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBtNweJE_bDxUtdLyBbfDsJB7P7ap3OsCQ`
        );
        const data = await response.json();
        const city = data.results[0].address_components.find((component) => component.types.includes('locality'));
        console.log(city ? city.long_name : '')
        return city ? city.long_name : '';
    };

    const tableCellStyle = {
        padding: "10px",
        textAlign: "start",
        textWrap: "nowrap"
    }

    const datefilterhandleChange = (props) => {
        // setPropsData(props);
        const [start, end] = props;
        setDefaultStartDate(start);
        // setDefaultEndDate(end.add(1, 'day'))
        setDefaultEndDate(end)
    }


    return (
        <>
            {
                show ? <CustomerOrderDetails setShow={setShow} /> : <>

                    <Page title="Grooming Journey">
                        <Container>
                            <Stack direction="row" alignItems="start" justifyContent="start" mb={2}>

                                <Typography variant="h4" >
                                    Grooming Journey
                                </Typography>
                            </Stack>

                            <Stack
                                style={{ marginBottom: '10px' }}
                                spacing={2}
                                direction="row-reverse"
                                alignItems="center"
                                sx={{ display: 'flex' }}
                            >
                                <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Grid item xs={8} sm={8} md={8} lg={6} style={{ display: 'flex', alignItems: 'center', paddingTop: '5px', gap: "2px" }}>
                                        <RangePicker
                                            defaultValue={[defaultStartDate, defaultEndDate]}
                                            open={popupOpen}
                                            onOpenChange={(open) => {
                                                if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                                                    setPopupOpen(true);
                                                } else {
                                                    setPopupOpen(open);
                                                }
                                            }}
                                            onChange={datefilterhandleChange}
                                            renderExtraFooter={() => (
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                                                    <Button onClick={() => { setPopupOpen(false); groomingJourney(); }} variant='contained' size='small' >Submit</Button>
                                                </Box>
                                            )}
                                        />
                                        <span className="" onClick={groomingJourney}>
                                            <CachedIcon sx={{ marginTop: 1, marginLeft: 2, cursor: 'pointer' }} />
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={6} style={{ display: 'flex', alignItems: "center", gap: "2px" }}>
                                        <Select
                                            style={{ margin: '0px 0px  0px 6px', height: '34px' }}
                                            labelId="allocation-label"
                                            id="allocation"
                                            value={selectedDate}
                                            onChange={handleChangeDate}
                                        >
                                            <MenuItem value="All" disabled>
                                                Select
                                            </MenuItem>
                                            <MenuItem value="date">Start Date</MenuItem>
                                            <MenuItem value="created_at" selected>Booking Date</MenuItem>
                                            {/* <MenuItem value="completed_on">Completed Date</MenuItem>
                                            <MenuItem value="cancelled_on">Cancelled Date</MenuItem> */}

                                        </Select>

                                        <TextField fullWidth label="Search sitter name" id="fullWidth"
                                            InputProps={{
                                                style: { margin: '6px 2px', height: '34px' },
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        style={{ cursor: 'pointer' }}
                                                    // onClick={handleSearch}
                                                    >
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: '16px', marginTop: '-4px' },
                                            }}
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Card>

                                {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800 }}>
                                        {filteredData?.length === 0 ? (
                                            <Typography component="div">
                                                {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                                                {loading ? (
                                                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                                                ) : (
                                                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                                                )}
                                            </Typography>
                                        ) : (
                                            <Table size='small'>
                                                <GroomingJourneyListHead
                                                    headLabel={TABLE_HEAD}
                                                />
                                                <TableBody>
                                                    {filteredData?.length >= 1 && filteredData?.map((row) => {
                                                        const {
                                                            _id,
                                                            Booking_id,
                                                            pet_name,
                                                            user_name,
                                                            sitter_name,
                                                            Groomer_end_time,
                                                            Groomer_start_time,
                                                            slot_start_time,
                                                            Navigation_end_time,
                                                            Navigation_start_time,
                                                            location,
                                                            start_date,
                                                            booking_date
                                                        } = row;

                                                        const LocationCell = () => {
                                                            const [address, setAddress] = useState('Fetching location...');

                                                            useEffect(() => {
                                                                if (location?.coordinates?.length === 2) {
                                                                    reverseGeocode(location.coordinates[0], location.coordinates[1])
                                                                        .then((result) => setAddress(result))
                                                                        .catch((err) => setAddress('Error fetching location'));
                                                                }
                                                            }, [location]);

                                                            return (
                                                                <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                    {address}
                                                                </TableCell>
                                                            );
                                                        };


                                                        return (
                                                            <TableRow
                                                                sx={{ paddingY: "20px" }}
                                                                hover
                                                                key={_id}
                                                                tabIndex={-1}
                                                            >
                                                                <TableCell onClick={() => handleReferenceID(_id)} style={{ cursor: 'pointer' }} align="left" sx={tableCellStyle}>
                                                                    <span className='booking-ref-ids'>  {Booking_id}</span>
                                                                </TableCell>
                                                                <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                    {user_name}
                                                                </TableCell>
                                                                <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                    {pet_name}
                                                                </TableCell>
                                                                <TableCell align="left" sx={{ textAlign: 'center' }}>{moment(booking_date).format('DD-MM-YYYY')}</TableCell>
                                                                <TableCell align="left" sx={{ textAlign: 'center', textWrap: 'noWrap' }} style={{ width: '100px' }}>
                                                                    {/* {moment(start_date).format('DD-MM-YYYY')}  */}
                                                                    {/* <span style={{ display: `${session_status === 'cancelled' ? 'none' : request_for_rescheduled === true && reschedule_status === 'pending' ? 'flex' : 'none'}` }}>
                                  <Chip size='small' style={{ height: 'fit-content', fontSize: '12px' }} label="Reschedule Requested" variant="outlined" color='error' />
                                </span> */}
                                                                    {/* <span className='text-nowrap'> {moment(start_date).format('DD-MM-YYYY')}</span> */}
                                                                    {moment(start_date).format('DD-MM-YYYY')}
                                                                </TableCell>
                                                                <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                    {sitter_name}
                                                                </TableCell>
                                                                {/* <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                    {reverseGeocode(location?.coordinates[0], location?.coordinates[1])}
                                                                </TableCell> */}
                                                                <LocationCell />
                                                                <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                    {`${slot_start_time.split("-")[0]}`}
                                                                </TableCell>

                                                                {
                                                                    Navigation_start_time ? <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        {moment.utc(Navigation_start_time).format('hh:mm A')}
                                                                    </TableCell> : <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        -
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    Navigation_end_time ? <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        {moment.utc(Navigation_end_time).format('hh:mm A')}
                                                                    </TableCell> : <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        -
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    Navigation_start_time && Navigation_end_time ? <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        {
                                                                            `${Math.floor(moment(Navigation_end_time).diff(moment(Navigation_start_time), 'minutes') / 60).toString().padStart(2, '0')}:${(moment(Navigation_end_time).diff(moment(Navigation_start_time), 'minutes') % 60).toString().padStart(2, '0')}`
                                                                        }
                                                                    </TableCell> : <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        -
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    Groomer_start_time ? <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        {moment.utc(Groomer_start_time).format('hh:mm A')}
                                                                    </TableCell> : <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        -
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    Groomer_end_time ? <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        {moment.utc(Groomer_end_time).format('hh:mm A')}
                                                                    </TableCell> : <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        -
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    Groomer_end_time && Groomer_start_time ? <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        {
                                                                            `${Math.floor(moment(Groomer_end_time).diff(moment(Groomer_start_time), 'minutes') / 60).toString().padStart(2, '0')}:${(moment(Groomer_end_time).diff(moment(Groomer_start_time), 'minutes') % 60).toString().padStart(2, '0')}`
                                                                        }
                                                                    </TableCell> : <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                                                        -
                                                                    </TableCell>
                                                                }
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </TableContainer>
                                </Scrollbar>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
                                    component="div"
                                    count={data?.data?.total_number_of_records}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Card>
                        </Container>
                    </Page>
                </>
            }
        </>
    )
}

export default GroomingJourneyTable