/* eslint-disable camelcase */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, Button } from '@mui/material';
// component
import Grid from '@mui/material/Grid';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------
TimeSlotListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function TimeSlotListToolbar({ numSelected }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/groomingTimeSlot"
                // to="/dashboard/addTimeSlot"
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{ ml: 3 }}
              >
                Add Time Slot
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </RootStyle>
  );
}
