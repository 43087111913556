/* eslint-disable camelcase */

import { useState, useEffect } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  Grid,
  Tooltip
} from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';


import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { getGroomingBookingSubscription } from '../../common/api-service';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { BookingHistoryListHead } from '../../sections/@dashboard/bookingHistory';
import AllData from './CommonApi/AllData';




const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'parent_name', label: 'Customer Name', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'booking_date', label: 'Booking Date', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount_paid', label: 'Amount', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_title', label: 'Packages', alignRight: false },
  { id: 'reschedule_date', label: 'Reschedule Date', alignRight: false },
  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'reschedule_time_slot', label: 'Reschedule Time Slot', alignRight: false },
  { id: 'parent_phone_no', label: 'Contact Number', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  // { id: 'offer_name', label: 'Offer Name', alignRight: false },
  { id: 'sessions', label: 'Number of Session', alignRight: false },
  { id: 'sessions_remaining', label: 'Session Remaining', alignRight: false },

  { id: 'offer_description', label: 'Offer Description', alignRight: false },
  { id: 'offer_count', label: 'Offer Count', alignRight: false },
  { id: 'offer_remaining', label: 'Offer Remaining', alignRight: false },


  { id: '' },
];

export default function GroomingSubscription() {

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [loading, setLoading] = useState(false);

  const bookingHistory = () => {
    setLoading(true);
    const payload = page;
    return getGroomingBookingSubscription(payload, searchQuery, rowsPerPage).then(
      (res) => {

        setData(res.data.booking_details);
        setFilteredData(res.data.booking_details.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    bookingHistory();
  }, [offset, page, searchQuery, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleReferenceID = (e) => {
    localStorage.setItem("bookingReferenceId", e);
    console.log('ref id', e);
    localStorage.setItem("s1", "Subscription");
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilteredData([])
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      bookingHistory();
    }
  };

  const [clicked, setClicked] = useState(false);

  // Function to handle click event
  const handleClick = () => {
    // Toggle the clicked state when the component is clicked
    setClicked(!clicked);
  };

  return (
    <>
      <Page>
        {/* <h1>Grooming Subscription</h1> */}
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom sx={{display:'flex'}}>
              Grooming Subscription
              {/* <Button onClick={bookingHistory}> CLICK</Button> */}
              {/* <div onClick={handleClick}>
              {clicked && <AllData name={'subs'} />}
              {!clicked && <div><AllData/></div>}
            </div> */}
            </Typography>
           

            <Grid Container>
              <Grid xs={12} lg={4} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Search"
                  id="fullWidth"
                  InputProps={{
                    style: { margin: '6px 2px', height: '34px' },
                    endAdornment: (
                      <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={bookingHistory}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: '16px', marginTop: '-4px' },
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                  onKeyDown={handleKeyPress}
                />
              </Grid>
            </Grid>

          </Stack>

          <Card>
            {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {filteredData.length === 0 ? (
                  <Typography component="div">
                    {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                    {loading ? (
                      <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                    ) : (
                      <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                    )}
                  </Typography>
                ) : (
                  <Table size='small'>
                    <BookingHistoryListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={data.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredData.map((row) => {
                        const {
                          _id,
                          pet_name,
                          pet_type,
                          service_name,
                          start_date,
                          time_slot,
                          parent_phone_no,
                          parent_name,
                          amount_paid,
                          add_ons,
                          package_title,
                          reschedule_date,
                          reschedule_status,
                          reschedule_time_slot,
                          sitter_name,
                          sitter_phone_no,
                          subscription,
                          number_of_sessions,
                          location,
                          coordinates,
                          payment_method,
                          offer_name,
                          remaining_sessions,
                          booking_date,
                          offer_description,
                          offer_count,
                          offer_remaining,
                          pay_on_service,
                          booking_ref_id,
                          grooming_sessions,
                          ongoing_session_number,
                          session_ref_id,
                        } = row;
                        const isItemSelected = selected.indexOf(_id) !== -1;
                        const truncatedDescription = offer_description?.length > 20 ? `${offer_description?.slice(0, 20)}...` : offer_description;
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          // onClick={() => openModal(row)}
                          >

                            <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                              <Link className='details-using-ref-id' to={'/dashboard/subscriptionOrderDetails'} onClick={() => handleReferenceID(booking_ref_id)}>
                                {booking_ref_id}
                              </Link>
                            </TableCell>
                            {/* {grooming_sessions
                              .filter(res =>  res.session_status === "upcoming") 
                              .map((res, index) => (
                                <TableCell key={index} style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                                  <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id)}>
                                    {res.session_ref_id} 
                                  </Link>
                                </TableCell>
                              ))} */}

                            <TableCell style={{ cursor: 'pointer' }} align="left" sx={{ textAlign: 'center' }}>
                              {parent_name}
                            </TableCell>
                            <TableCell component="th" sx={{ textAlign: 'center' }}>
                              {pet_name.join(' , ')}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                              {pet_type.join(' , ')}
                            </TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{service_name}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{booking_date ? moment(booking_date).format('DD-MM-YYYY') : booking_date}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{start_date ? moment(start_date).format('DD-MM-YYYY') : start_date}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{time_slot}</TableCell>
                            {/* <TableCell align="center">{payment_method === ''  ? 'Pay after service' : 'Online'}</TableCell> */}
                            <TableCell sx={{ textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{amount_paid}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>
                              {/* {payment_method === '' ? 'Not Paid' : 'Paid'} */}
                              {payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                              {add_ons?.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{package_title}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_date ? moment(reschedule_date).format('DD-MM-YYYY') : reschedule_date}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_status}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_time_slot}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{parent_phone_no}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>
                              <a
                                style={{ textDecoration: 'none', color: '#32a852' }}
                                href={`https://www.google.com/maps?q=${location?.coordinates[0]},${location?.coordinates[1]}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View on Google Map
                              </a>
                            </TableCell>

                            <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_name}</TableCell>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_phone_no}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                            </TableCell>
                            {/* <TableCell align="center">{offer_name}</TableCell> */}
                            <TableCell sx={{ textAlign: 'center' }}>{number_of_sessions}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{remaining_sessions}</TableCell>

                            {/* offer columns */}
                            {/* <TableCell  sx={{maxWidth: '100px', wordWrap: 'break-word', textAlign: 'center'}}>{offer_description}</TableCell> */}
                            <TableCell sx={{ textAlign: 'center', maxWidth: '100px', maxHeight: '50px', wordWrap: 'break-word' }}>
                              {number_of_sessions === 1 ? (
                                "-"
                              ) : (
                                <Tooltip title={offer_description} arrow>
                                  <span>
                                    {truncatedDescription}
                                  </span>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{offer_count}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{offer_remaining}</TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
              component="div"
              // count={data.number_of_records}
              count={data.total_number_of_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  )
}