/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@mui/material/Box';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import { OfferingGroomingListHead, OfferingGroomingMoreMenu } from '../../../sections/@dashboard/offeringGrooming';
// mock
import { getGroomingOfferingData } from '../../../common/api-service';
import BackButton from '../../../components/BackButton';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '' },
  { id: 'title', label: 'Name', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'target_pet', label: 'Pet Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function Offering() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const offer = () => {
    setLoading(true);
    const payload = page;
    return getGroomingOfferingData(payload).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    offer();
  }, [offset, page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Grooming Offering
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/addGroomingOffering"
            startIcon={<Iconify icon="eva:plus-fill" />}
            ml={2}
          >
            Add Grooming Offering
          </Button>
          <BackButton/>
        </Stack>

        <Card>
        {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                      <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                    ) : (
                      <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                    )}
                </Typography>
              ) : (
                <Table>
                  <OfferingGroomingListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      const { _id, title, service_name, status, target_pet } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left" style={{padding:'1px'}}> </TableCell>
                          <TableCell component="th" scope="row" style={{padding:'1px'}}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left" style={{padding:'1px'}}>{service_name}</TableCell>
                          <TableCell align="left" style={{padding:'1px'}}>{target_pet}</TableCell>
                          <TableCell align="left" style={{padding:'1px'}}>
                            <Label variant="ghost" color={(status === 'banned' && 'error') || status==='active' ? 'success':'error'}>
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>

                          <TableCell align="right" style={{padding:'1px'}}>
                            <OfferingGroomingMoreMenu data={_id} handleRefresh={setOffset} currentStatus={status} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            count={data.number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}
