/* eslint-disable */
/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Checkbox from '@mui/material/Checkbox';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Iconify from '../../components/Iconify';

import {
  addPetGroomingPackage,
  dropDownOffering,
  dropDownServiceMaster,
  getAddonsDropDownData,
  dropDownLesson,
  addIcon,
} from '../../common/api-service';
import { GroomingContext } from 'src/layouts/dashboard';
import BackButton from '../../components/BackButton';

function AddPetGrooming() {
  const navigate = useNavigate();
  const groomingData = useContext(GroomingContext);
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const Packages_status = ['active', 'disabled'];

  function getStyles(type, Name, theme) {
    return {
      fontWeight: Name.indexOf(type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
  function getStyles1(type, Name, theme) {
    return {
      fontWeight: theme.typography.fontWeightRegular,
    };
  }

  const [AdminNotes, setAdminNotes] = useState('');
  const [packagesDiscription, setPackagesDiscription] = useState('');
  const [loading, setLoading] = useState(false);
  const [packagefieldCount, setPackageFieldCount] = useState([]);
  const [packageAddOnsfieldCount, setPackageAddOnsFieldCount] = useState([]);
  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [PackagesStatus, setStatus] = React.useState('');
  const [offeringId, setOfferingID] = React.useState([]);
  const [offeringdata, setOfferData] = React.useState([]);
  const [addOnsdata, setAddOnsData] = React.useState([]);
  const [data, setData] = useState([]);
  const [packagePrice, setPackagePrice] = React.useState('');
  const [NumberOfPets, setNumberOfPets] = React.useState('1');
  const [validityDay, setValidityDay] = React.useState(999);
  const [checked, setChecked] = React.useState(true);
  const [offerPrice, setOfferPrice] = React.useState('');
  const [numberOfWalks, setNumberOfWalks] = React.useState('');
  const [numberOfSession, setNumberOfSession] = React.useState('1');
  const [subscriptionSession, setSubscriptionSessions] = React.useState([]);
  const [lessonsdata, setLessonData] = React.useState([]);
  const [subNoOfSession, setsubNoOfSession] = React.useState([]);
  const [subPrice, setSubPrice] = React.useState([]);
  const [subOfferPrice, setSubOfferPrice] = React.useState([]);
  const [subDiscription, setsubDiscription] = React.useState([]);
  const [subs, setSubs] = React.useState([]);

  const [file, setFile] = useState(null);

  const handleAdd = () => {
    const abc = [...packagefieldCount, []];
    setPackageFieldCount(abc);
  };

  const addNewhandleChange = (onChangevalue, i) => {
    const inputData = [...packagefieldCount];
    inputData[i] = onChangevalue.target.value;
    setPackageFieldCount(inputData);
  };

  const handleDelete = (i) => {
    const deleteVal = [...packagefieldCount];
    deleteVal.splice(i, 1);
    setPackageFieldCount(deleteVal);
  };

  // const handleAddSession = () => {
  //   // const abc = [...subscriptionSession, []];
  //   // setSubscriptionSessions(abc);
  //   const newSession = {
  //     subscription_sessions: "",
  //     subscription_price: "",
  //     subscription_offer_price: "",
  //     subscription_description: "",
  //   };

  //   setSubs((prevSubs) => [...prevSubs, newSession]);

  // };

  // const addNewhandleChangeSession = (onChangevalue, i) => {
  //   const inputData = [...subscriptionSession];
  //   inputData[i] = onChangevalue.target.value;
  //   setSubscriptionSessions(inputData);
  // };

  // const addNewhandleChange1 = (e, i, fieldName) => {
  //   const updatedSessions = [...subs];
  //   updatedSessions[i][fieldName] = e.target.value;
  //   setSubs(updatedSessions);
  //   // const inputValue = e.target.value;
  //   // if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
  //   //   setSubs(updatedSessions);
  //   // }
  //   console.log("setSubs", subs)

  // };

  // const handleDeleteSession = (i) => {
  //   const deleteVal = [...subs];
  //   deleteVal.splice(i, 1);
  //   setSubs(deleteVal);
  // };

  // const handleChange = () => {
  //   setChecked(!checked);
  // };

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const offeringDropDown = (payload) => {
    return dropDownOffering(payload).then(
      (res) => {
        setOfferData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const addonsDropDown = (payload) => {
    return getAddonsDropDownData(payload).then(
      (res) => {
        setAddOnsData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const lessonDropDown = (payload) => {
    return dropDownLesson(payload).then(
      (res) => {
        setLessonData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    offeringDropDown();
    serviceMaster();
    addonsDropDown();
    lessonDropDown();
  }, []);

  const handleClearClick = () => {
    setAdminNotes('');
    setUserName('');
    setPackagesDiscription('');
    setStatus('');
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const addIconApi = async (id) => {

    const formData = new FormData();
    formData.append('icon', file);
    formData.append("package_id", id)



    addIcon(formData)
      .then((res) => {


        navigate('/dashboard/petGrooming', { replace: true });
        swal({
          title: res.data.status ? res.data.message : 'Error',
          icon: res.data.status ? 'success' : 'danger',
          timer: 2000,
          button: false,
        });
      })
      .catch((err) => {
        console.log('err:', err);
      });
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      offerings: offeringId,
      service_master_id: groomingData.serviceId,
      add_ons: packageAddOnsfieldCount,
      title: userName,
      description: packagefieldCount,
      admin_notes: AdminNotes,
      target_pet: petName,
      price: packagePrice,
      number_of_pets: NumberOfPets === '' ? 0 : NumberOfPets,
      validity_in_days: validityDay === '' ? 0 : validityDay,
      offer_price: offerPrice,
      no_of_walks_per_day: numberOfWalks === '' ? 0 : numberOfWalks,
      number_of_sessions: numberOfSession === '' ? 0 : numberOfSession,
      subscription: checked,
      // subscription_sessions_for_grooming: subscriptionSession === '' ? 0 : subscriptionSession,
      status: PackagesStatus,

      subscription_sessions_for_grooming: subs,
      // icon: base64String,
      // extension: contentTypeWithoutXml,
    };

    // Pass formData directly to your function
    addPetGroomingPackage(payload)
      .then((res) => {
        // Handle response
        setLoading(false);

        addIconApi(res.data.data._id);

        // navigate('/dashboard/petGrooming', { replace: true });
        // swal({
        //   title: res.data.status ? res.data.message : 'Error',
        //   icon: res.data.status ? 'success' : 'danger',
        //   timer: 2000,
        //   button: false,
        // });
      })
      .catch((err) => {
        console.log('Error:', err);
        setLoading(false);
        swal({
          title: 'Error',
          icon: 'danger',
          timer: 2000,
          button: false,
        });
      });
  };

  //   return addPetGroomingPackage(payload).then(
  //     (res) => {
  //       if (res.data.status === true) {
  //         setLoading(false);

  //         addIconApi(res.data.data._id); //for svg file

  //         navigate('/dashboard/petGrooming', { replace: true });
  //         swal({
  //           title: res.data.message,
  //           icon: 'success',
  //           timer: 2000,
  //           button: false,
  //         });
  //       } else {
  //         swal({
  //           title: res.data.message,
  //           icon: 'danger',
  //           timer: 2000,
  //           button: false,
  //         });
  //       }
  //     },
  //     (err) => {
  //       console.log('err', err);
  //     }
  //   );
  // };

  // const handleNumberOfSessions = (e) => {
  //   const inputValue = e.target.value;
  //   if (/^[1-9][0-9]*$/.test(inputValue) || inputValue === '') {
  //     setNumberOfSession(inputValue);
  //   }
  // };

  const handlespace = (e) => {
    if (e.key === ' ') {
      e.preventDefault(); // Prevent the space character from being entered
    }
  };

  //conversion of img to base64
  const [imageData, setImageData] = useState('');
  const [extensionType, setImageExtension] = useState('');


  const [selectedFile, setSelectedFile] = useState(null);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    const file = e.target.files[0];
    setImageData(file);
    setImageExtension(file.type);
    setSelectedFile(file);
    // setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };
  return (
    <div>
      <Grid>
        <Typography
          variant="h3"
          component="div"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Add Pet Grooming Package
        </Typography>
        <BackButton />
      </Grid>

      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Title */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                      onChange={(e) => setUserName(e.target.value.trimStart())}
                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Number of Session */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Number of Session"
                      variant="outlined"
                      type="number"
                      inputProps={{ inputMode: 'numeric', pattern: '/^[1-9][0-9]*$' }}
                      value={numberOfSession}
                      onChange={(e) => setNumberOfSession(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                      aria-readonly
                      disabled
                    />
                  </Grid>

                  {/* Number of Pets */}
                  <Grid
                    sx={{
                      // display: serviceMasterId.toString() === '62f640ca7f976c2292c575bb' ? 'none' : 'block',
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Number of Pets"
                      type="number"
                      variant="outlined"
                      value={NumberOfPets}
                      onChange={(e) => setNumberOfPets(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                      disabled
                    />
                  </Grid>

                  {/* Discriptions */}
                  {/* <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Button variant="contained" onClick={() => handleAdd()} sx={{ mb: 3 }}>
                      Add Description
                    </Button>

                    {packagefieldCount.map((description, i) => {
                      return (
                        <Grid sx={{ mt: 3 }}>
                          <TextField
                            id="outlined-basic"
                            label="Description"
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                              addNewhandleChange(e, i);
                            }}
                            style={{ width: '100%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    aria-label="Delete"
                                    edge="end"
                                    color="error"
                                    variant="outlined"
                                    onClick={() => handleDelete(i)}
                                  >
                                    Delete
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid> */}

                  {/* Admin Notes */}
                  {/* <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextareaAutosize
                      id="outlined-basic"
                      minRows={3}
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      placeholder="Admin Notes"
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid> */}

                  {/* Pet Type */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={petName}
                        name="petType"
                      required
                      {...register('petType', {
                        required: true,
                      })}
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((target_pet) => (
                          <MenuItem key={target_pet} value={target_pet} style={getStyles(target_pet, petName, theme)}>
                            {target_pet}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.petType && errors.petType.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                    </FormControl>
                  </Grid>

                  {/* Offering Dropdown */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Offering</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={offeringId}
                        name="offeringId"
                      required
                      {...register('offeringId', {
                        required: true,
                      })}
                        onChange={(e) => {
                          setOfferingID(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Offering" />}
                        MenuProps={MenuProps}
                      >
                        {offeringdata.map((e) => (
                          <MenuItem key={e._id} value={e} style={getStyles(e, offeringId, theme)}>
                            {e.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.offeringId && errors.offeringId.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>



                  {/* Price */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Price"
                      variant="outlined"
                      type="number"
                      name="price"
                      required
                      value={packagePrice}
                      {...register('price', {
                        required: true,
                      })}
                      onChange={(e) => setPackagePrice(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {errors.price && errors.price.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Offer Price */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Offer Price"
                      variant="outlined"
                      name="offerPrice"
                      type="number"
                      required
                      value={offerPrice}
                      {...register('offerPrice', {
                        required: true,
                      })}
                      onChange={(e) => setOfferPrice(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {errors.offerPrice && errors.offerPrice.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Validity */}
                  {/* <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Validity In Days"
                      variant="outlined"
                      type="number"
                      value={validityDay}
                      onChange={(e) => setValidityDay(e.target.value.replace(/[^0-9]/g, ""))}
                      style={{ width: '100%' }}
                    />
                  </Grid> */}

                  {/* Add Ons Dropdown */}
                  {/* <Grid sx={{mt:3}}> */}
                  {/* <Button variant="contained" onClick={() => handleAddAddOns()} sx={{ mb: 3 }}>
                      Add Add-Ons
                    </Button>

                    {packageAddOnsfieldCount.map((add_ons, i) => { */}
                  {/* return ( */}
                  <Grid sx={{ mt: 3 }}>
                    <FormControl sx={{ minWidth: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label">Add Ons</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={packageAddOnsfieldCount}
                        onChange={(e) => {
                          // addNewhandleChangeAddOns(e, i);
                          setPackageAddOnsFieldCount(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Add Ons" />}
                        MenuProps={MenuProps}
                      >
                        {addOnsdata.map((e) => {
                          return (
                            <MenuItem key={e._id} value={e} style={getStyles1(e, packageAddOnsfieldCount, theme)}>
                              {e.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* ); */}
                  {/* })} */}
                  {/* </Grid> */}

                  {/* choose file */}
                  <Grid sx={{ mt: 3 }}>
                    <div className="form-group">
                      <div
                        className="form-control"
                        style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}
                      >
                        <TextField
                          variant="outlined"
                          value={selectedFile ? selectedFile.name : ''}
                          name="file"
                          required
                          {...register('file', {
                            // required: true,
                            required: !selectedFile,
                          })}
                          label="Choose Icon"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <label htmlFor="file-upload">
                                  <input
                                    accept=".jpg, .png, .jpeg, .svg"
                                    id="file-upload"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                  />
                                  <Button variant="contained" component="span">
                                    Browse
                                  </Button>
                                </label>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          style={{ width: '100%' }}
                        />
                      </div>
                      {errors.file && errors.file.type === 'required' && (
                        <p className="text-danger" style={{ color: 'red' }}>
                          *required
                        </p>
                      )}
                      {imageData && (
                        <img style={{ marginTop: '10px' }} height={80} width={80} src={imageData} alt="Selected Image" />
                      )}
                    </div>
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={PackagesStatus}
                        name="PackagesStatus"
                      required
                      {...register('PackagesStatus', {
                        required: true,
                      })}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {Packages_status.map((Packages_status) => (
                          <MenuItem
                            key={Packages_status}
                            value={Packages_status}
                            style={getStyles(Packages_status, PackagesStatus, theme)}
                          >
                            {Packages_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.PackagesStatus && errors.PackagesStatus.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Subscription */}

                  {/* <Box
                            key={i}
                            boxShadow={3}
                            padding={2}
                            sx={{ width: '100%', marginBottom: 2 }}
                          > */}
                  <Grid sx={{ mt: 3 }}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        '& > :not(style)': { width: '25ch' },
                      }}
                      display="flex"
                      justifyContent="left"
                      alignItems="left"
                    >
                      Subscriptions
                    </Typography>
                  </Grid>
                  <Grid container columnSpacing={2} style={{ justifyContent: 'center' }} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="No of sessions"
                        variant="outlined"
                        value={subs.subscription_sessions}
                        onChange={(e) => {
                          const priceInput = e.target.value.replace(/\D/g, '');
                          const sessions = parseFloat(priceInput) || 1;
                          const price = parseFloat(subs.subscription_price) || '';
                          const offerPrice = price / sessions;

                          setSubs((prevSubs) => ({
                            ...prevSubs,
                            subscription_sessions: priceInput,
                            subscription_price: price,
                            subscription_offer_price: offerPrice.toFixed(0),
                          }));
                        }}

                        name="subscription_sessions"
                      // required
                      // {...register('subscription_sessions', {
                      //   required: true,
                      // })}
                        onKeyDown={handlespace}
                        style={{ width: '100%' }}
                      />
                      {errors.subscription_sessions && errors.subscription_sessions.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Price"
                        variant="outlined"
                        value={subs.subscription_price}
                        onChange={(e) => {
                          const priceInput = e.target.value.replace(/\D/g, '');
                          const price = parseFloat(priceInput) || 0;
                          const sessions = parseInt(subs.subscription_sessions) || 1;
                          const offerPrice = price / sessions;

                          setSubs((prevSubs) => ({
                            ...prevSubs,
                            subscription_price: priceInput,
                            subscription_offer_price: offerPrice.toFixed(0),
                          }));
                        }}

                        name="subscription_price"
                      // required
                      // {...register('subscription_price', {
                      //   required: true,
                      // })}
                        onKeyDown={handlespace}
                        style={{ width: '100%' }}
                      />
                       {/* {errors.subscription_price && errors.subscription_price.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="price per session"
                        variant="outlined"
                        value={subs.subscription_offer_price}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">/ session</InputAdornment>,
                        }}
                        readOnly

                        name="subscription_offer_price"
                        // required
                        // {...register('subscription_offer_price', {
                        //   required: true,
                        // })}
                        onKeyDown={handlespace}
                        style={{ width: '100%' }}
                      />
                      {/* {errors.subscription_offer_price && errors.subscription_offer_price.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Offer Description"
                        variant="outlined"
                        value={subs.subscription_description}
                        onChange={(e) => {
                          setSubs({ ...subs, subscription_description: e.target.value});
                        }}
                        name="subscription_description"
                        // required
                        // {...register('subscription_description', {
                        //   required: true,
                        // })}
                        style={{ width: '100%' }}
                      />
                      <Typography sx={{ color: 'red', opacity: '0.9' }}>* Note: Please do not enter Offer Count/digit</Typography>
                      {/* {errors.subscription_description && errors.subscription_description.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Offer count"
                        variant="outlined"
                        value={subs.offer_count}
                        onChange={(e) => {
                          setSubs({ ...subs, offer_count: e.target.value.replace(/[^0-9]/g, '') });
                        }}
                        name="offer_count"
                        // required
                        // {...register('offer_count', {
                        //   required: true,
                        // })}
                        style={{ width: '100%' }}
                      />
                      {/* {errors.offer_count && errors.offer_count.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                    </Grid>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Add Pet Grooming
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default AddPetGrooming;
