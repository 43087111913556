import React from 'react'
import {Button,} from '@mui/material';
import { getGroomingBookingOngoingAllData } from 'src/common/api-service';

const AllData = ({name , setData,Rowsperpage , Page , from_date , to_date , date}) => {

    const handleBtnForCallApi=()=>{

        // setLoading(true);
        // const payload = "";
        // pageParameter

        return getGroomingBookingOngoingAllData(Rowsperpage,Page , to_date , from_date , date).then(
          (res) => {
            setData(res.data.data)
          },
          (err) => {
            // setLoading(false);
            console.log('err data: ', err);
          }
        );
    }

  return (
    <div style={{display:"flex",justifyContent:"center" }}>
            <Button variant="contained" size='small' color="primary" sx={{ml:1 , mr:1}} onClick={handleBtnForCallApi}>All{name}</Button>

    </div>
  )
}

export default AllData