/* eslint-disable camelcase */

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@material-ui/icons/Search';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import { styled } from '@mui/system';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import backgroundDetails from '../../Assets/backgroundDetails.png';
import { DatePicker } from 'antd';

import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';

import { CouponsListHead, CouponsListToolbar, CouponsMoreMenu } from '../../sections/@dashboard/coupons';
// mock
import { getOthersEnquiryData, getCouponsData, getDogTrainingEnquiryData, enquiryUpdateById, othersUpdateById } from '../../common/api-service';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user_name', label: 'Customer Name', alignRight: false },
  { id: 'customer_contact', label: 'Customer Contact', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  //   { id: 'package_selected', label: 'Package Selected', alignRight: false },

  { id: 'enquiry_date', label: 'Enquiry Date', alignRight: false },
  { id: 'enquiry_time', label: 'Enquiry Time', alignRight: false },

  { id: 'service_type', label: 'Service Type', alignRight: false },

  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'details', label: 'Details', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },


  //   { id: 'start_time_slot', label: 'Start Time Slot', alignRight: false },
  // { id: 'pet_name', label: 'Pet Name', alignRight: false },
  // { id: 'pet_type', label: 'Pet Type', alignRight: false },
  // { id: 'customer_email', label: 'Customer Email', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export default function Others() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);
  console.log('filteredData', filteredData)
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);
  const [is_active, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idget, setIdget] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const { RangePicker } = DatePicker;

  const [popupOpen, setPopupOpen] = useState(false);
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);

  const [selectedDate, setSelectedDate] = useState('created_at');

  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  const datefilterhandleChange = (dates) => {
    console.log('dates:', dates); // Check if dates are correctly received
    if (dates) {
      const [start, end] = dates;
      setDefaultStartDate(start);
      setDefaultEndDate(end);
      setPopupOpen(false)
    } else {
      // Reset dates to null if cancel button is clicked
      setDefaultStartDate(null);
      setDefaultEndDate(null);
      // Fetch data again when cancel button is clicked
      others();
    }
  };



  const handleChangeStatus = (_id, selectedStatus) => {
    const payload = {
      others_enquiry_id: _id,
      status: selectedStatus
    };

    othersUpdateById(payload)
      .then((res) => {
        console.log('res:', res);
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
          showConfirmButton: false,
          timer: 1500
        });
        // Assuming `petWalking` is the function to fetch updated data
        others();
      })
      .catch((err) => {
        console.log('Error:', err);
      });
  };



  useEffect(() => {
    others();
  }, [offset, page, rowsPerPage, searchQuery, defaultStartDate, defaultEndDate]);

  const others = () => {
    setLoading(true);
    const payload = page;
    return getOthersEnquiryData(payload, rowsPerPage, searchQuery, defaultStartDate, defaultEndDate, selectedDate).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const navigate = useNavigate();
  const editpage = (_id) => {
    const masterid = _id;
    localStorage.setItem('setMasterId', masterid);
    navigate('/dashboard/edit_coupons');
  };

  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const openModal = (rowData) => {
    // console.log('rowData', rowData);
    setSelectedRowData(rowData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 'fit-to-content',
    bgcolor: '#e1f7f1',
    boxShadow: 10,
    borderRadius: '10px',
    border: '3px solid black',
    p: 4,
    backgroundImage: `url(${backgroundDetails})`,
  };

  const LeftModalGrid = styled(Grid)(({ theme }) => ({
    // backgroundImage: ' url("https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg")', // Replace with your actual image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%', // Adjust the height as needed
    padding: theme.spacing(2), // Add padding as needed
    color: 'ActiveCaption',
    // Add other styling properties as needed
  }));

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Others
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/add_coupons"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Dog Walking
          </Button> */}
        </Stack>

        <Stack style={{ marginBottom: '10px' }}
          spacing={2}
          direction="row-reverse"
          alignItems="center"
          sx={{ display: 'flex' }}
        >
          <Grid container style={{ display: 'flex', justifyContent: 'end' }}>
            <Grid item xs={8} sm={8} md={8} lg={8} style={{ alignContent: 'right', paddingTop: '5px' }}>
              {/* <RangePicker onChange={datefilterhandleChange} 
              defaultValue={[defaultStartDate, defaultEndDate]}
              /> */}

              <RangePicker
                defaultValue={[defaultStartDate, defaultEndDate]}
                open={popupOpen}
                onOpenChange={(open) => {
                  if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                    setPopupOpen(true);
                  } else {
                    setPopupOpen(open);
                  }
                }}
                onChange={datefilterhandleChange}
                onCancel={() => datefilterhandleChange(null)} // Ensure onCancel is correctly defined
              // renderExtraFooter={() => (
              //   <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
              //     <Button onClick={() => { setPopupOpen(false); petBoarding(); }} variant='contained' size='small' >Submit</Button>
              //   </Box>
              // )}
              />

              {/* <Select
                style={{ margin: '0px 0px  0px 2px', height: '34px' }}
                labelId="allocation-label"
                id="allocation"
                value={selectedDate}
                onChange={handleChangeDate}
              >
                <MenuItem value="All" disabled>Select </MenuItem>
                <MenuItem value="date">Start Date</MenuItem>
                <MenuItem value="created_at" selected> Booking Date</MenuItem>
                <MenuItem value="completed_on" selected> Completed Date</MenuItem>

              </Select> */}
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label="Search"
                id="fullWidth"
                InputProps={{
                  style: { margin: '6px 2px', height: '34px' },
                  endAdornment: (
                    <InputAdornment position="end" style={{ cursor: 'pointer' }}
                    // onClick={handleSearch}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: '16px', marginTop: '-4px' },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              // onKeyDown={handleKeyPress}
              />
            </Grid>
          </Grid>
        </Stack>

        <Card>
          {/* <CouponsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                  )}
                </Typography>
              ) : (
                <Table>
                  <CouponsListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredData?.map((row) => {
                      const {
                        _id,
                        service_master_id,
                        user_name,
                        address,
                        location,
                        user_contact,
                        created_at,
                        time_slot_1,
                        service_type,
                        start_date,
                        details,
                        package: packages,
                        total_count,
                        status
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}

                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, coupon_name)} />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ textAlign: 'center' }}
                          >
                            <Stack direction="row" spacing={2} justifyContent="center">
                              <Typography variant="subtitle2" noWrap sx={{ textAlign: 'center', cursor: 'pointer' }} className='booking-ref-ids' onClick={() => openModal(row)}>
                                {user_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {user_contact}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {address}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${location?.coordinates[0]},${location?.coordinates[1]}`}
                              // href={`https://www.google.com/maps?q=455,566}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell>

                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {moment(created_at).format('DD-MM-YYYY')}
                          </TableCell>


                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {moment(created_at).format(' HH:mm A')}
                          </TableCell>

                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {service_type
                            }
                          </TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                        {package_selected}
                      </TableCell> */}
                          {/* {packages.map((packageItem, index) => (
                          <TableRow key={index}>
                            <TableCell align="left" sx={{ textAlign: 'center' }}>
                              {packageItem.title}
                            </TableCell>
                          </TableRow>
                        ))} */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {moment(start_date).format('DD-MM-YYYY')}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {details}
                          </TableCell>
                          {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                        {pet_name}
                      </TableCell>
                      <TableCell align="left" sx={{ textAlign: 'center' }}>
                        {pet_type}
                      </TableCell>
                      <TableCell align="left" sx={{ textAlign: 'center' }}>
                        {customer_email}
                      </TableCell>                   */}
                          <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Select
                              size='small'
                              value={status}
                              onChange={(event) => handleChangeStatus(_id, event.target.value)} // Pass _id and selected value                              displayEmpty
                              inputProps={{ 'aria-label': 'Select Status' }}
                            >
                              <MenuItem value="" disabled>
                                Select Status
                              </MenuItem>
                              <MenuItem value="Select">Select</MenuItem>
                              <MenuItem value="Open">Open</MenuItem>
                              <MenuItem value="Forwarded to the team">Forwarded to the team</MenuItem>
                              <MenuItem value="Converted">Converted</MenuItem>
                              <MenuItem value="Dropped - price">Dropped - price</MenuItem>
                              <MenuItem value="Dropped - competition">Dropped - competition</MenuItem>
                              <MenuItem value="Dropped - non-serviceable">Dropped - non-serviceable</MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150]}
            component="div"
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <LeftModalGrid xs={6} sx={{ padding: '14px' }}>
                <Typography id="modal-modal-title" variant="h4" component="h2" style={{ textAlign: 'center' }}>
                  Customer Detail
                </Typography>
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: 2, marginLeft: '10px' }}>
                  {selectedRowData.customer_name}
                  <p style={{ fontSize: '15px' }}>{selectedRowData.customer_contact}</p>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, marginLeft: '10px' }}>
                  {selectedRowData && (
                    <div>
                      <p>Pet Name: {selectedRowData.pet_name}</p>
                      <p>Customer E-Mail: {selectedRowData.customer_email}</p>
                      <p>Start Date: {moment(selectedRowData.start_date).format('DD-MM-YYYY')}</p>
                      <p>Start Time Slot: {selectedRowData.time_slot_1}</p>
                      <p>End Date: {moment(selectedRowData.end_date).format('DD-MM-YYYY')}</p>
                      {/* <p>End Time Slot: {selectedRowData.time_slot_2}</p> */}
                    </div>
                  )}
                </Typography>
              </LeftModalGrid>
              <Grid xs={6} sx={{ padding: '14px' }} style={{ borderLeft: '2px solid black' }}>
                <Typography id="modal-modal-title" variant="h4" component="h2" style={{ textAlign: 'center' }}>
                  Pet Details
                </Typography>
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mt: 2, marginLeft: '60px' }}>
                  {selectedRowData.pet_name}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, marginLeft: '60px', marginTop: '10px' }}>
                  {selectedRowData && (
                    <div>
                      <p>Pet Type: {selectedRowData.pet_type}</p>
                      <p>Pet Breed: {selectedRowData.pet_breed}</p>
                      <p>Pet Gender: {selectedRowData.pet_gender}</p>
                      <p>Pet DOB: {moment(selectedRowData.pet_dob).format('DD-MM-YYYY')}</p>
                      <p>Pet Weight Category: {selectedRowData.pet_weight}</p>
                      <p>Pet Vaccination: {selectedRowData.pet_vaccination === 'true' ? 'Yes' : 'No'}</p>
                      <p>Pet Aggression: {selectedRowData.pet_aggression}</p>
                      <p>Pet Friendly with: {selectedRowData.pet_friendliness?.map((addOn, i) => addOn).join(', ')}</p>
                    </div>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
