import React, { useEffect, useState } from 'react';
import {
  Typography,
  Chip,
  Container,
  Box,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
  ThemeProvider,
  Tooltip,
  Button,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MapIcon from '@mui/icons-material/Map';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getPetDetailsById } from 'src/common/api-service';
import BackButton from 'src/components/BackButton';


const PetDetails = ({setPetShow}) => {
  const [petDetails, setPetDetails] = useState([]);
  console.log('petDetails',petDetails)

  const petDetailsData = () => {
    const payload = localStorage.getItem('petIdForPetDetail');
    return getPetDetailsById(payload).then((res) => {
      setPetDetails(res.data.data);
    });
  };

  useEffect(() => {
    petDetailsData();
  }, []);

  return (
    <div>
      <BackButton setPetShow={setPetShow}/>

      {petDetails.map((petDetail) => (
        <div key={petDetail.session_id}>

          <Box mt={3}>
            <Typography sx={{ml:'20px'}} variant="h4">Pet Details</Typography>
            <div style={{display:'flex' , margin:'20px'}}>
              <img src={petDetail.profile_pic} height={100} width={100} style={{border:'1px solid #884AED' , borderRadius:'10px'}}/>
            <Typography sx={{mt:'auto' , ml:'2%'}} variant="h4">{petDetail.name}</Typography>
            </div>

            <Divider orientation="horizontal" />

            <Grid container spacing={2} mt={0.01}>
              <Grid sx={{padding:'10px'}} item sm={6} xs={12} md={6} lg={6}>
              <Typography>
                  <span className='typo-bold-book-order'>User Name :</span> {petDetail.users.name}
                </Typography>
              <Typography>
                  <span className='typo-bold-book-order'>Pet Type :</span> {petDetail.pet_type}
                </Typography>
                            
                <Typography>
                  <span className='typo-bold-book-order'>Pet Gender :</span> {petDetail.gender}
                </Typography>
                <Typography>
                  <span className='typo-bold-book-order'>Pet DOB :</span> {petDetail.dob ? moment(petDetail.dob).format('DD-MM-YYYY') : 'Not mentioned'}
                </Typography> 
                <Typography>
                  <span className='typo-bold-book-order'>Pet Breed :</span> {petDetail.breed}
                </Typography>               
              </Grid>             

              <Grid sx={{borderLeft:'1px solid black'}} item sm={6} xs={12} md={6} lg={6}>
              <Typography>
                  <span className='typo-bold-book-order'>Pet Aggressiveness :</span> {petDetail.aggressive}
                </Typography> 
             
                <Typography>
                  <span className='typo-bold-book-order'>Pet Vaccinated :</span> {`${petDetail.is_vaccinated === 'true' ? 'Yes' : 'No'}`}
                </Typography> 
                <Typography>
                  <span className='typo-bold-book-order'>Vaccination Dose :</span> 
                  {
                    petDetail.vaccination?.map((vaccine, index) => 
                      <span key={index}> 
                       {index > 0 && ' , '}
                       {vaccine} 
                      </span>

                    )
                  }                 
                </Typography>              
                <Typography>
                  <span className='typo-bold-book-order'>Pet Weight :</span> {petDetail.weight}
                </Typography>

                <Typography>
                  <span className='typo-bold-book-order'>Pet Friendly with :</span> 
                  {
                    petDetail.friendly_with?.map((friendlyPet, index) => 
                      <span key={index}> 
                       {index > 0 && ' , '}
                       {friendlyPet} 
                      </span>

                    )
                  }                 
                </Typography>                 
              </Grid>
             
            </Grid>
            <Divider orientation="horizontal" />
          </Box>
          {/* More content */}
        </div>
      ))}
    </div>
  );
};

export default PetDetails;
