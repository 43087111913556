/* eslint-disable camelcase */
/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import {
  GroomingReschduleListHead,
  GroomingReschduleMoreMenu,
} from '../../../sections/@dashboard/groomingReschduleBooking';
// mock
import { getGroomingReschduleData, getGroomingReschduleSearchData } from '../../../common/api-service';
import InputAdornment from '@material-ui/core/InputAdornment';
import dayjs from 'dayjs';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import BackButton from '../../../components/BackButton';

import { DatePicker } from 'antd';
import { ThemeContext } from "../../../App";
import CustomerOrderDetails from '../CustomerOrderDetails';
import RescheduleReqActionBookingDetails from '../RescheduleReqActionBookingDetail';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'user_name', label: 'Name', alignRight: false },
  { id: 'user_contact_no', label: 'Phone No', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'package_name', label: 'Package', alignRight: false },
  // { id: 'previous_start_date', label: 'Previous Start Date', alignRight: false },
  { id: 'previous_start_date', label: 'Booking Date', alignRight: false },
  { id: 'reschedule_request_date', label: 'Reschedule Request Date', alignRight: false },
  { id: 'reschedule_time_slot', label: 'Reschedule Request Time Slot', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'type_of_session', label: 'Type Of Session ', alignRight: false },
  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'status', label: 'Action', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
// export const TotalRecordsContext = createContext();
export default function GroomingReschduleBooking() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');


  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('date')

  const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('week'));
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs());

  const [popupOpen, setPopupOpen] = useState(false);
  // const {setTotalNoRecord,totalNoRecord} = useContext(ThemeContext);
  const [show, setShow] = useState(false);


  const reschedule = () => {
    setLoading(true);
    const payload = page;
    return getGroomingReschduleData(payload, searchQuery, defaultStartDate.add(1, 'day'), defaultEndDate.add(1, 'day'), selectedDate, rowsPerPage, defaultStartDate, defaultEndDate).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    reschedule();
  }, [offset, page, searchQuery, selectedDate, rowsPerPage, show]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleSearch = () => {

    return getGroomingReschduleSearchData(page, searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );

  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {

      // handleSearch();
    }
  };


  const datefilterhandleChange = (props) => {

    const [start, end] = props;

    setDefaultStartDate(start);
    // setDefaultEndDate(end.add(1, 'day'))
    setDefaultEndDate(end)


  }

  // const handleReferenceID = (e) => {
  //   localStorage.setItem("bookingReferenceId", e);
  //   localStorage.setItem("s1", "Reschedule Request");
  // }

  const handleReferenceID = (booking_ref_id, _id) => {
    setShow(true);
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", _id);
    localStorage.setItem("s1", "Ongoing")

  }


  return (
    <>
      {show ? (<RescheduleReqActionBookingDetails setShow={setShow} />
      ) : (
        <Page title="User">
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h4" gutterBottom>
                Grooming Reschedule Request
              </Typography>
              <BackButton />
            </Stack>
            <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <TextField fullWidth label="Search" id="fullWidth"
                  InputProps={{
                    style: { margin: '6px 2px', height: '34px' },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                        onClick={handleSearch}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    style: { fontSize: '16px', marginTop: '-4px' },
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                  onKeyDown={handleKeyPress}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {/* <RangePicker onChange={datefilterhandleChange} 
              defaultValue={[defaultStartDate, defaultEndDate]}
              /> */}

                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    open={popupOpen}
                    onOpenChange={(open) => {
                      if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                        setPopupOpen(true);
                      } else {
                        setPopupOpen(open);
                      }
                    }}
                    onChange={datefilterhandleChange}
                    renderExtraFooter={() => (
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                        <Button onClick={() => { setPopupOpen(false); reschedule(); }} variant='contained' size='small' >Submit</Button>
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Card>
              {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {filteredData.length === 0 ? (
                    <Typography component="div">
                      {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                      {loading ? (
                        <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                      ) : (
                        <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                      )}
                    </Typography>
                  ) : (
                    <Table size='small'>
                      <GroomingReschduleListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={data.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredData.map((row) => {

                          const {
                            _id,
                            user_name,
                            service_name,
                            pet_name,
                            pet_type,
                            date_of_booking,
                            cancellation_request_date,
                            reschedule_date,
                            amount,
                            package_name,
                            start_date,
                            user_contact_no,
                            reschedule_status,
                            reschedule_time_slot,
                            booking_ref_id,
                            number_of_sessions,
                            session_ref_id,
                            service_master_id,
                            price_paid
                          } = row;
                          const isItemSelected = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            
                            <Link className='details-using-ref-id' to={'/dashboard/rescReqActionOrderDetail'} onClick={() => handleReferenceID(booking_ref_id)}>
                             {number_of_sessions === 1 ? session_ref_id?.split('-')[0] : session_ref_id}
                            </Link>
                          </TableCell> */}

                              <TableCell sx={{ textAlign: 'center' }} onClick={() => handleReferenceID(booking_ref_id, _id)} >
                                {/* <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}> */}
                                {/* {booking_ref_id} */}
                                <span className='booking-ref-ids'> {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}</span>
                                {/* </Link> */}
                              </TableCell>
                              <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                                <Typography variant="subtitle2" noWrap>
                                  {user_name}
                                </Typography>
                              </TableCell>

                              <TableCell align="left" sx={{ textAlign: 'center' }}>{user_contact_no}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>
                                <Typography>{start_date ? moment(start_date).format('DD-MM-YYYY') : start_date}</Typography>
                              </TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{service_name}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{pet_name.join(' , ')}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{pet_type.join(' , ')}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{package_name}</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                <Typography>{date_of_booking ? moment(date_of_booking).format('DD-MM-YYYY') : date_of_booking}</Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                <Typography>{reschedule_date ? moment(reschedule_date).format('DD-MM-YYYY') : reschedule_date}</Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                <Typography>{reschedule_time_slot}</Typography>
                              </TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>
                                {/* {amount} */}
                                {price_paid}

                              </TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}</TableCell>
                              <TableCell align="left" sx={{ textAlign: 'center' }}>{reschedule_status}</TableCell>

                              <TableCell align="right" sx={{ textAlign: 'center' }}>
                                <GroomingReschduleMoreMenu
                                  data={_id}
                                  handleRefresh={setOffset}
                                  currentStatus={reschedule_status}
                                  alldata={row}
                                  startDate={start_date}
                                  servicemasterId={service_master_id}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}

                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
                component="div"
                // count={data.number_of_records}
                count={data.total_number_of_records}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>

        </Page>
      )}
    </>
  );
}
