/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  addAddons,
  dropDownServiceMaster,
  getGroomingAddonsDataForUpdate,
  addonsGrommingUpdate,
} from '../../../../common/api-service';
import BackButton from '../../../../components/BackButton';

function GroomingEditAddons() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const addons_status = ['active', 'disabled'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [AdminNotes, setAdminNotes] = useState('');
  const [addonsDiscription, setAddonsDiscription] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [addonsstatus, setAddonsStatus] = React.useState(' ');
  const [idget, setIdget] = React.useState(' ');
  const [serviceMasterId, setServiceMaster] = React.useState([]);
  const [addonsPrice, setAddonsPrice] = useState('');
  const [displayAfterSession, setDisplayAfterSession] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [data, setData] = useState([]);

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };
  const getGroomingAddonsID = () => {
    const getGroomingAddonsID = localStorage.getItem('getGroomingAddonsID');
    setIdget(getGroomingAddonsID);
    const payload = {
      _id: getGroomingAddonsID,
    };
    return getGroomingAddonsDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        setUserName(data.title);
        setAddonsDiscription(data.description);
        setAdminNotes(data.admin_notes);
        setPetName(data.target_pet);
        setServiceMaster(data.service_master_id);
        setAddonsStatus(data.status);
        setAddonsPrice(data.price);
        setDisplayAfterSession(data.display_after_session);
        setChecked(data.is_checked);
      } else {
        console.log('error');
      }
    });
  };
  useEffect(() => {
    serviceMaster();
    getGroomingAddonsID();
  }, []);

  const handleClearClick = () => {
    setUserName('');
    setAddonsDiscription('');
    setAdminNotes('');
    setPetName('');
    setServiceMaster('');
    setAddonsStatus('');
    setAddonsPrice('');
    setDisplayAfterSession('');
    setChecked('');
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const handleChange = () => {
    setChecked(!checked);
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      _id: idget,
      title: userName,
      description: addonsDiscription,
      admin_notes: AdminNotes,
      target_pet: petName,
      display_after_session: displayAfterSession,
      price: addonsPrice,
      is_checked: checked,
      status: addonsstatus,
    };
    return addonsGrommingUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/groomingAddons', { replace: true });
          localStorage.removeItem('getGroomingAddonsID');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Update Gromming Addons
      </Typography>
      <BackButton/>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid>
                    {/* Title */}
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Discription */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Discription"
                      variant="outlined"
                      value={addonsDiscription}
                      onChange={(e) => setAddonsDiscription(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Admin Notes */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Pet Type */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={petName}
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((target_pet) => (
                          <MenuItem key={target_pet} value={target_pet} style={getStyles(target_pet, petName, theme)}>
                            {target_pet}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Price (Actual Cost) */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Price"
                      variant="outlined"
                      type="number"
                      value={addonsPrice}
                      name="price"
                      required
                      {...register('price', {
                        required: true,
                      })}
                      onChange={(e) => setAddonsPrice(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.price && errors.price.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Display After Session */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Display After Sesssion"
                      variant="outlined"
                      type="number"
                      value={displayAfterSession}
                      onChange={(e) => setDisplayAfterSession(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={addonsstatus}
                        onChange={(e) => {
                          setAddonsStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {addons_status.map((addons_status) => (
                          <MenuItem
                            key={addons_status}
                            value={addons_status}
                            style={getStyles(addons_status, addonsstatus, theme)}
                          >
                            {addons_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* isChecked */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Checkbox checked={checked} onChange={handleChange} />
                    isChecked
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Addons
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default GroomingEditAddons;
