/* eslint-disable camelcase */
/* eslint-disable */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import {
  GroomingCancellationListHead,
  GroomingCancellationMoreMenu,
} from '../../../sections/@dashboard/groomingCancellation';
// mock
import { getGroomingCancellationData, getGroomingCancellationSearchData } from '../../../common/api-service';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import BackButton from '../../../components/BackButton';
import { DatePicker } from 'antd';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'user_name', label: 'Name', alignRight: false },
  { id: 'user_contact_no', label: 'Phone No', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'reason_by_user', label: 'Reason', alignRight: false },
  { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  { id: 'package_name', label: 'Package', alignRight: false },
  { id: 'date_of_booking', label: 'Booking Date', alignRight: false },
  { id: 'cancellation_request_date', label: 'Cancellation Request Date', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  { id: 'status', label: 'Action', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function GroomingCancellation() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('created_at')

  const offer = () => {
    setLoading(true);
    const payload = page;
    return getGroomingCancellationData(payload, searchQuery, fromDate, toDate, selectedDate, rowsPerPage).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    offer();
  }, [offset, page, searchQuery, , fromDate, toDate, selectedDate, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const handleReferenceID = (e) => {
    localStorage.setItem("bookingReferenceId", e);
  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleSearch = () => {

    return getGroomingCancellationSearchData(page, searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );

  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {

      // handleSearch();
    }
  };

  const datefilterhandleChange = (props) => {
    if (props) {
      const [start, end] = props;
      setFromDate(start);
      setToDate(end);
    } else {
      setFromDate('');
      setToDate('');
    }
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Grooming Cancellation Request
          </Typography>
          {/* <BackButton /> */}
        </Stack>
        <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField fullWidth label="Search" id="fullWidth"
              InputProps={{
                style: { margin: '6px 2px', height: '34px' },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer' }}
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: { fontSize: '16px', marginTop: '-4px' },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
              onKeyDown={handleKeyPress}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <RangePicker
                onChange={datefilterhandleChange}
              />
            </Grid>
          </Grid>
        </Stack>
        <Card>
          {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {filteredData.length === 0 ? (
                <Typography component="div">
                  {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                  {loading ? (
                    <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                  )}
                </Typography>
              ) : (
                <Table size='small'>
                  <GroomingCancellationListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredData.map((row) => {
                      const {
                        _id,
                        user_name,
                        service_name,
                        pet_name,
                        pet_type,
                        date_of_booking,
                        cancellation_request_date,
                        amount,
                        package_name,
                        reason_by_user,
                        start_date,
                        user_contact_no,
                        booking_ref_id,
                        number_of_sessions,
                      } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left" sx={{  textAlign: 'center' }}>
                          <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id)}>
                              {booking_ref_id}
                            </Link>
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{  textAlign: 'center' }}>
                              <Typography variant="subtitle2" noWrap>
                                {user_name}
                              </Typography>
                          </TableCell>

                          <TableCell align="left" sx={{  textAlign: 'center' }}>{user_contact_no}</TableCell>
                          <TableCell align="left"  sx={{  textAlign: 'center' }}>
                            <Typography>{start_date ? moment(start_date).format('DD-MM-YYYY') : start_date}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>{service_name}</TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>{reason_by_user}</TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>{pet_name.join(' , ')}</TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>{pet_type.join(' , ')}</TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>{package_name}</TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>
                            <Typography>{date_of_booking ? moment(date_of_booking).format('DD-MM-YYYY') : date_of_booking}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>
                            <Typography>{cancellation_request_date ? moment(cancellation_request_date).format('DD-MM-YYYY') : cancellation_request_date}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{  textAlign: 'center' }}>{amount}</TableCell>
                          <TableCell align="center" sx={{  textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>

                          <TableCell align="right" sx={{  textAlign: 'center' }}>
                            <GroomingCancellationMoreMenu data={_id} handleRefresh={setOffset} currentStatus={status} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
            component="div"
            // count={data.number_of_records}
            count={data.total_number_of_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
