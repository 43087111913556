import React, { useEffect, useState } from 'react';
import {
  Typography,
  Chip,
  Container,
  Box,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
  ThemeProvider,
  Tooltip,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MapIcon from '@mui/icons-material/Map';
import journey1 from '../../Assets/journey1.png'
import journey2 from '../../Assets/journey2.png'
import journey3 from '../../Assets/journey3.png'
import journey4 from '../../Assets/journey4.png'
import checkIcon from '../../Assets/check_circle.png'



import moment from 'moment';
import { SitterGroomingMoreMenu } from '../../sections/@dashboard/sitterAllocationGrooming';

import BackButton from '../../components/BackButton';
import { getOrderDetailsData, getAllSessionsByBookingId, getSessionData } from '../../common/api-service';
import Scrollbar from '../../components/Scrollbar'
import { BookingHistoryListHead } from '../../sections/@dashboard/bookingHistory'
import BasicBreadcrumbs from './BreadCrumb';
import SessionsStatus from './SessionsStatus';
import { Link } from 'react-router-dom';
import PetDetails from '../PetList/PetDetails';




//------------------------------------------------------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'session_id', label: 'Session No.', alignRight: true },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },

  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  { id: 'sitter_contact', label: 'Sitter Contact', alignRight: false },

  { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
  { id: 'reschedule_date', label: 'Reschedule date', alignRight: false },



  { id: 'session_status', label: 'Status', alignRight: false },

  // { id: 'pet_type', label: 'Sitter Name', alignRight: false },



  { id: '' },
];


const CustomerOrderDetails = ({ setShow }) => {





  const [cancelOrederData, setCancelOrederData] = useState({});

  const [petShow, setPetShow] = useState(false);

  const getSessionsDetails = () => {
    const payload = localStorage.getItem('cancellationBookingReferenceId');
    return getSessionData(payload).then((res) => {
      setCancelOrederData(res.data.data);
    });
  };

  useEffect(() => {
    // getOrderDetails();
    getSessionsDetails();
  }, []);

  const theme = createTheme({
    palette: {
      divider: '#000000',
    },
  });

  const handlePetDetailsById = (_id) => {
    setPetShow(true)
    localStorage.setItem('petIdForPetDetail', _id)
  }

  const [showJourney, setShowJourney] = useState(false)
  const handleShowJourney = () => {
    setShowJourney(!showJourney)
  }

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {petShow ? (<PetDetails setPetShow={setPetShow} />) :
        (<>
          {/* <BasicBreadcrumbs /> */}
          <BackButton setShow={setShow} />
          {Array.isArray(cancelOrederData) && cancelOrederData.map((cancelOrder) => {
            const truncatedDescription = cancelOrder.free_offer_used === true ?
              `${cancelOrder?.offer_offer_info?.offer_description?.slice(0, 20)}...` : '-'
            return (
              <>
                <Container sx={{ display: 'flex' }}>
                  <Typography variant="h4">Booking ID #

                    {cancelOrder.number_of_sessions === 1 ? cancelOrder.session_ref_id.split('-')[0] : cancelOrder.session_ref_id}
                  </Typography>
                  {cancelOrder.payment_method === '' ? (
                    <Chip
                      icon={<FiberManualRecordIcon style={{ color: '#d50000' }} />}
                      label="Not Paid"
                      size="small"
                      style={{
                        color: '#d50000',
                        backgroundColor: '#ef9a9a',
                        marginTop: '1%',
                        height: 'fit-content',
                        marginLeft: '2%',
                      }}
                    />
                  ) : (
                    <Chip
                      icon={<FiberManualRecordIcon style={{ color: '#00a152' }} />}
                      label="Paid"
                      size="small"
                      style={{
                        color: '#00a152',
                        backgroundColor: '#a5d6a7',
                        marginTop: '1%',
                        height: 'fit-content',
                        marginLeft: '2%',
                      }}
                    />
                  )}


                  <span style={{

                    marginTop: '0.7%',
                    height: 'fit-content',
                    marginLeft: '1%',
                  }}>
                    <SessionsStatus sessStatus={cancelOrder.session_status} />
                  </span>



                  {/* <CalendarMonthIcon fontSize="small" sx={{ marginTop: '1%', marginLeft: '2%' }} />
                <Typography sx={{ marginTop: '1%', marginRight: '4%' }}> */}
                  {/* {moment(order.start_date).format('DD-MM-YYYY')} */}
                  {/* {moment(cancelOrder.date).format('DD-MM-YYYY')}
                  <span style={{ marginLeft: '15px', fontSize: '15px' }}>
                    {cancelOrder.reschedule_status === 'approved' ? cancelOrder.reschedule_date : cancelOrder.time_slot}
                  </span>
                </Typography> */}

                  <span style={{ display: `${cancelOrder.session_status === 'completed' || cancelOrder.session_status === 'ongoing' || cancelOrder.session_status === 'cancelled' || cancelOrder.reschedule_status === 'pending' ? 'none' : 'flex'}`, marginLeft: '2%' }}>
                    <SitterGroomingMoreMenu
                      // rowData={order}
                      // rowData={sessionDetailsdata[order.ongoing_session_number - 1]}
                      rowData={cancelOrder}
                      // rowData={sessionDetailsdata[order.ongoing_session_number - 1]}
                      // rowData1={filteredOrders}
                      data={cancelOrder.service_master_id}
                      // dataDate={moment(date !=null?date:"2022-11-26T00:00:00.000Z").format('YYYY-MM-DD')}
                      dataDate={moment(cancelOrder.date != null ? cancelOrder.date : '').format('DD-MM-YYYY')}
                    />
                  </span>
                </Container>
                <Container sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      color: 'white',
                      fontSize: 'small',
                      backgroundColor: '#006064',
                      maxWidth: 'fit-content',
                      padding: '3px 10px',
                      margin: '10px 20px',
                      borderRadius: '5px',
                    }}
                  >
                    <MapIcon />
                    <a
                      style={{ textDecoration: 'none', color: 'white', marginTop: '1.5%' }}
                      href={`https://www.google.com/maps?q=${cancelOrder.location?.coordinates[0]},${cancelOrder.location?.coordinates[1]}`}
                      // href={`https://www.google.com/maps?q=3,5`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View location on Map
                    </a>
                  </Box>
                  <CalendarMonthIcon fontSize="small" sx={{ marginTop: '1%', marginLeft: '1%', display: 'inline-flex', }} />
                  <Typography sx={{ marginTop: '1%', display: 'inline-flex', }}> Start Date :
                    {/* {moment(order.start_date).format('DD-MM-YYYY')} */}
                    {moment(cancelOrder.date).format('DD-MM-YYYY')}
                    <span style={{ marginLeft: '15px', fontSize: '15px' }}>
                      {cancelOrder.reschedule_status === 'approved' ? cancelOrder.reschedule_date : cancelOrder.time_slot}
                    </span>
                  </Typography>

                  <Button variant='contained' size='small' sx={{ marginLeft: 'auto', maxHeight: '35px', display: `${cancelOrder.session_status === 'completed' ? 'flex' : 'none'}` }} onClick={handleShowJourney}>{showJourney ? 'Back to Booking Details' : 'Grooming Journey'}</Button>

                </Container>

                {showJourney ?
                  (
                    <div style={{ padding: '30px' }}>
                      <h3 style={{ fontSize: '20px', fontWeight: 500, color: '#000000' }}>Grooming journey</h3>
                      <Grid container style={{ padding: '10px 30px' }}>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <div className='journey-track'>
                              <img src={journey1} alt="img" height={45} width={45} style={{ margin: '0px 5px' }} />
                              <div>
                                <Typography sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '21px', marginTop: '2%', marginBottom: '-2%' }}>Groomer alloted</Typography>
                                <span className='journey-sub-title' >Groomer for your service: {cancelOrder.sitter_name} </span>
                              </div>
                            </div>

                            <div className='journey-track'>
                              <img src={journey2} alt="img" height={45} width={45} style={{ margin: '20px 5px' }} />
                              <div>
                                <Typography sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '21px', marginTop: '2%', marginBottom: '-1%' }}>Groomer has arrived at your doorstep</Typography>
                                <span className='journey-sub-title' >Groomer's here! Grooming starts soon. </span>
                                <span className='journey-sub-title' >Navigation start time: {moment.utc(cancelOrder.navigation_started_on).format('hh:mm A')}</span>
                                <span className='journey-sub-title' >Navigation End time: {moment.utc(cancelOrder.reached_on).format('hh:mm A')}</span>

                              </div>
                            </div>

                            <div className='journey-track'>
                              <img src={journey3} alt="img" height={45} width={45} style={{ margin: '20px 5px' }} />
                              <div>
                                <Typography sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '21px', marginTop: '2%', marginBottom: '-1%' }}>Grooming almost done!</Typography>
                                <span className='journey-sub-title' >Final touches coming up! </span>
                                <span className='journey-sub-title' >Grooming start time: {moment.utc(cancelOrder.session_started_on).format('hh:mm A')}</span>
                                <span className='journey-sub-title' >Grooming End time: {moment.utc(cancelOrder.completed_on).format('hh:mm A')}</span>
                              </div>
                            </div>

                            <div className='journey-track'>
                              <img src={journey4} alt="img" height={45} width={45} style={{ margin: '0 5px' }} />
                              <div>
                                <Typography sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '21px', marginTop: '2%', marginBottom: '-2%' }}>Grooming done! Your pet shines</Typography>
                                <span className='journey-sub-title' >Your pet's makeover is complete! </span>
                              </div>
                            </div>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} >
                          <Box style={{marginTop: matches ? '30px' : '0px'}}>
                            <Box sx={{ borderLeft: '1px dashed #000000', padding: '14px', position: 'relative', paddingBottom: '20px'}}>
                              <img src={checkIcon} alt="Check Circle" style={{ position: 'absolute', top: -5, left: -12, height: 23, width: 23, backgroundColor: 'white' }} />
                              <Typography className='right-journey-track'>{cancelOrder.pet_name} ready for make over</Typography>

                              <div className="inner-booking-image-journey">
                                <div style={{ display: 'flex' }}>
                                  <img src={cancelOrder.started.before} alt="img" height={81} width={52} style={{ margin: '7px 15px 0  15px', borderRadius: '10px' }} />
                                  <div>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '18px', marginTop: '5%' }}>Your pet before grooming</Typography>
                                    <span className='journey-sub-title' >Stay tuned to see the transformation </span>
                                  </div>
                                </div>
                              </div>
                            </Box>

                            <Box sx={{ borderLeft: '1px dashed #000000', padding: '14px', position: 'relative', paddingBottom: '20px' }}>
                              <img src={checkIcon} alt="Check Circle" style={{ position: 'absolute', top: -5, left: -12, height: 23, width: 23, backgroundColor: 'white' }} />
                              <Typography className='right-journey-track'>Grooming equipment ready to transform your pet </Typography>

                              <div className="inner-booking-image-journey">
                                <div style={{ display: 'flex' }}>
                                  <img src={cancelOrder.started.equipment} alt="img" height={81} width={52} style={{ margin: '7px 15px 0  15px', borderRadius: '10px' }} />
                                  <div>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '18px', marginTop: '5%' }}>Equipment image to boost your confidence in our service and maintain trasparency</Typography>
                                    {/* <span className='journey-sub-title' >Stay tuned to see the transformation </span> */}
                                  </div>
                                </div>
                              </div>
                            </Box>

                            <Box sx={{ borderLeft: '1px dashed #000000', padding: '14px', position: 'relative', paddingBottom: '20px' }}>
                              <img src={checkIcon} alt="Check Circle" style={{ position: 'absolute', top: -5, left: -12, height: 23, width: 23, backgroundColor: 'white' }} />
                              <Typography className='right-journey-track'>Grooming procedures performed by {cancelOrder.sitter_name}</Typography>

                              <div style={{ padding: ' 10px 20px' }}>
                                <div>
                                  <Typography className='grooming-performed-by'>Offerings</Typography>
                                  <ul style={{ fontSize: '12px', lineHeight: '18px', fontWeight: '400', padding: '0 20px', color: '#000000' }}>
                                    {cancelOrder.started.task.map((value, index) => {
                                      return <li key={index}>{value}</li>
                                    })}
                                  </ul>
                                </div>

                                <div style={{ marginTop: '1%' }}>
                                  <Typography className='grooming-performed-by'>Add On's</Typography>
                                  {cancelOrder.add_ons.length>0 ? (
                                  <ul style={{ fontSize: '12px', lineHeight: '18px', fontWeight: '400', padding: '0 20px', color: '#000000' }}>
                                    {cancelOrder.add_ons.map((value, index) => {
                                      return <li key={index}>{value.title}</li>
                                    })}
                                  </ul>
                                  ) : (<Typography style={{fontSize: '10px', lineHeight: '18px', fontWeight: '400', padding: '0 10px', color: '#000000' }}>No Add On's used</Typography>)}
                                </div>

                                <div style={{ marginTop: '1%' }}>
                                  <Typography className='grooming-performed-by'>Free Session</Typography>
                                  {cancelOrder.free_offer_used === true ? (
                                    <ul style={{ fontSize: '12px', lineHeight: '18px', fontWeight: '400', padding: '0 20px', color: '#000000' }}>
                                      {cancelOrder.add_ons.map((value, index) => {
                                        return <li key={index}>{value.title}</li>
                                      })}
                                    </ul>
                                  ) : (<Typography style={{fontSize: '10px', lineHeight: '18px', fontWeight: '400', padding: '0 10px', color: '#000000' }}>No free offerings used</Typography>)}
                                </div>
                              </div>
                            </Box>

                            <Box sx={{ borderLeft: '1px dashed #000000', padding: '14px', position: 'relative', paddingBottom: '20px' }}>
                              <img src={checkIcon} alt="Check Circle" style={{ position: 'absolute', top: -5, left: -12, height: 23, width: 23, backgroundColor: 'white' }} />
                              <Typography className='right-journey-track'>Your pet's fabulous makeover is done! </Typography>

                              <div className="inner-booking-image-journey">
                                <div style={{ display: 'flex' }}>
                                  <img src={cancelOrder.started.after} alt="img" height={81} width={52} style={{ margin: '7px 15px 0  15px', borderRadius: '10px' }} />
                                  <div>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '18px', marginTop: '5%' }}>{cancelOrder.pet_name} is ready to impress!</Typography>
                                    <span className='journey-sub-title' >We hope our service met your expectations and brought joy to your furry friend!</span>
                                  </div>
                                </div>
                              </div>
                            </Box>

                            <Box sx={{ padding: '14px', position: 'relative', paddingBottom: '20px' }}>
                              <img src={checkIcon} alt="Check Circle" style={{ position: 'absolute', top: -5, left: -12, height: 23, width: 23, backgroundColor: 'white' }} />
                              <Typography className='right-journey-track'>We have received your payment against service </Typography>
                              <span className='journey-sub-title'  style={{marginLeft:'8px' , marginTop:'1px'}} >Cash payment of INR {cancelOrder.price_paid} received by the sitter. Thank you for choosing us!</span>
                            </Box>


                          </Box>
                        </Grid>
                      </Grid>

                    </div>
                  )
                  : 
                  (<>
                    <Box ml={2} mt={3}>
                      <Typography variant="h4">Booking Details</Typography>
                      <Divider orientation="horizantal" />
                      <Grid container spacing={2} mt={0.01}>
                        <Grid item sm={6} xs={12} md={6} lg={6} sx={{ borderRight: '1px solid #b0bec5' }}>
                          <Typography><span className='typo-bold-book-order'>Customer Name :</span> {cancelOrder.parent_name} </Typography>
                          <Typography style={{ display: 'flex' }}><span className='typo-bold-book-order'>Pet Name : </span><span style={{ marginLeft: '2px' }}> {cancelOrder.pet_name}</span> <p className='order-detail-pet-detail' onClick={() => { handlePetDetailsById(cancelOrder.pet_id[0]) }}>View pet details</p>

                          </Typography>
                          <Typography><span className='typo-bold-book-order'>Pet Type :</span> {cancelOrder?.target_pet[0]} </Typography>
                          <Typography><span className='typo-bold-book-order'>Customer Contact Number :</span> {cancelOrder.parent_phone_no} </Typography>
                          <Typography><span className='typo-bold-book-order'>Booking Date : </span>
                            {/* {moment(order.booking_date).format('DD-MM-YYYY')}  */}
                            {moment(cancelOrder.created_at).format('DD-MM-YYYY')}
                          </Typography>
                          <Typography><span className='typo-bold-book-order'>Customer Email :</span> {cancelOrder.email} </Typography>
                          {cancelOrder.completed_on || cancelOrder.cancelled_on ?
                            cancelOrder.completed_on ? <Typography><span className='typo-bold-book-order'>Completed Date :</span> {moment(cancelOrder.completed_on).format('DD-MM-YYYY')}</Typography> : <Typography> <span className='typo-bold-book-order'>Cancelled Date :</span> {moment(cancelOrder.cancelled_on).format('DD-MM-YYYY')}</Typography>
                            : ''
                          }
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={6}>
                          <Typography><span className='typo-bold-book-order'>Address : </span>
                            {cancelOrder.address}
                          </Typography>
                          <Typography><span className='typo-bold-book-order'>
                            Method of Payment :</span> {cancelOrder.pay_on_service === true ? `Pay after service ${cancelOrder.payment_method === '' ? ' ' : `(${cancelOrder.payment_method})`}` : 'Online'}{' '}
                          </Typography>
                          <Typography><span className='typo-bold-book-order'>Sitter Name : </span>
                            {cancelOrder.sitter_name}
                          </Typography>
                          <Typography><span className='typo-bold-book-order'>Sitter Contact Number :</span> {cancelOrder.sitter_contact} </Typography>
                          <Typography><span className='typo-bold-book-order'>
                            Booking Type :</span>{' '}
                            {cancelOrder.number_of_sessions === 1
                              ? 'One Time'
                              : `Subscription (${cancelOrder.srno}/${cancelOrder.number_of_sessions})`}
                          </Typography>

                        </Grid>
                      </Grid>
                      <Divider orientation="horizantal" />
                    </Box>

                    {/* ------------------------------------------------------------------------------------------------------------------------------ */}

                    <Box ml={2} mt={4}>
                      <Divider orientation="horizontal" />
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Service Name</TableCell>
                              <TableCell align="center">Package Name</TableCell>
                              <TableCell align="center">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">{cancelOrder.service_name}</TableCell>
                              <TableCell align="center">{cancelOrder.package_title}</TableCell>
                              <TableCell align="center">
                                {cancelOrder.number_of_sessions === 1
                                  ? cancelOrder.package_price
                                  : cancelOrder.srno === 1 ? cancelOrder.package.subscription_sessions_for_grooming[0].subscription_price : '0'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">{"Add on's"}</TableCell>
                              <TableCell align="center">
                                {cancelOrder.add_ons?.length === 0
                                  ? ' - '
                                  : cancelOrder?.add_ons?.map((d, i) => (
                                    <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                                      {d.title}
                                    </Typography>
                                  ))}
                              </TableCell>
                              <TableCell align="center">
                                {cancelOrder.addon_total ? cancelOrder.addon_total : '0'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">{'Free Services'}</TableCell>
                              {/* <TableCell align="center">{order.offer_description}</TableCell> */}
                              <TableCell align="center">
                                {cancelOrder.number_of_sessions === 1 ? (
                                  '-'
                                ) : (
                                  <Tooltip title={cancelOrder?.free_offer_info?.offer_description} arrow>
                                    <span>
                                      {/* {truncatedDescription} */}
                                      {cancelOrder.free_offer_used === true ? `${cancelOrder?.free_offer_info?.offer_description?.slice(0, 18)}...` : '-'}

                                    </span>
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell align="center">{'0'}</TableCell>
                            </TableRow>
                            <ThemeProvider theme={theme}>
                              <TableRow>

                              </TableRow>
                            </ThemeProvider>
                            <TableRow>
                              <TableCell align="center"> </TableCell>
                              <TableCell align="right">{'Total'}</TableCell>
                              <TableCell align="center">
                                {cancelOrder.package_price || cancelOrder.addon_total ? cancelOrder.srno === 1 ?
                                  parseInt(
                                    (cancelOrder.number_of_sessions === 1
                                      ? parseInt(cancelOrder.package_price, 10)
                                      : parseInt(cancelOrder.package.subscription_sessions_for_grooming[0].subscription_price, 10)) +
                                    (cancelOrder.addon_total === '0 Rs'
                                      ? 0
                                      : cancelOrder.add_ons.length === 0 ? 0 : cancelOrder.add_ons?.reduce((total, current) => total + parseInt(current.price, 10), 0))
                                  )

                                  :
                                  cancelOrder.addon_total
                                  : '0 Rs'}

                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"> </TableCell>
                              <TableCell align="right">{'Coupon discount'}</TableCell>
                              <TableCell align="center">
                                {/* {order.coupon_discount ? `${order.coupon_discount} ${order.coupon_discount_type === 'percentage' ? ' %' : ' Rs'}` : '0'} */}

                                {cancelOrder.coupon ? cancelOrder?.coupon?.couponId !== null ? `${cancelOrder?.coupon?.discountAmount} Rs`
                                  // ${order?.coupon_discount_type === 'percentage' ? ' %' : ' Rs'}
                                  : '0' : '0 Rs'}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="center"> </TableCell>
                              <TableCell align="right" sx={{ fontWeight: '700' }}>Grand Total</TableCell>
                              <TableCell align="center">

                                {/* {order.amount_paid} Rs */}
                                {cancelOrder.srno === 1 ?
                                  cancelOrder.price_paid === '0' ? '0 Rs' : `${cancelOrder.price_paid} Rs`
                                  :
                                  cancelOrder?.addon_total ? `${cancelOrder.addon_total} Rs` : '0 Rs'
                                }
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider orientation="horizontal" />
                    </Box>
                  </>
                  )}
              </>
            )
          })}
        </>)}
    </>
  );
};

export default CustomerOrderDetails;