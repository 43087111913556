/* eslint-disable */
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// import swal from 'sweetalert';
// component
import Iconify from '../../../components/Iconify';
import { GrommingReschduleApprove, getSessionCount } from '../../../common/api-service';
import { useContext } from 'react';
import { ThemeContext } from 'src/App';
import swal from 'sweetalert2';

// ----------------------------------------------------------------------
const GroomingReshduleMoreMenu = ({ data, handleRefresh, currentStatus,alldata, servicemasterId, startDate }) => {
// console.log('alldata :',alldata);



  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(data);

  const { sitterAllocationCount, setSitterAllocationCount } = useContext(ThemeContext);
  const { cancellationRequestCount, setCancellationRequestCount } = useContext(ThemeContext);
  const { rescheduleRequestCount, setRescheduleRequestCount } = useContext(ThemeContext);

  const { petBoardingCount, setPetBoardingCount } = useContext(ThemeContext);
  const { dogWalkingCount, setDogWalkingCount } = useContext(ThemeContext);
  const { petSittingCount, setPetSittingCount } = useContext(ThemeContext);
  const { dogTrainingCount, setDogTrainingCount } = useContext(ThemeContext);
  const { otherCount, setOtherCount } = useContext(ThemeContext);

  const updatecount = () => {

    getSessionCount().then(
      (res) => {
        const [requestedSessionsCount, rescheduledRequestsCount, cancellationRequestsCount,
        petboardingcount, dogwalkingcount, petsittingcount, dogtrainingcount, 
        othercount] = res.data.data;
        setSitterAllocationCount(requestedSessionsCount.requested_sessions_count);
        setRescheduleRequestCount(rescheduledRequestsCount.rescheduled_requests_count);
        setCancellationRequestCount(cancellationRequestsCount.cancellation_requests_count);
        setPetBoardingCount(petboardingcount.pet_boarding_count);
        setDogWalkingCount(dogwalkingcount.dog_walking_count);
        setPetSittingCount(petsittingcount.pet_sitting_count);
        setDogTrainingCount(dogtrainingcount.dog_training_count);
        setOtherCount(othercount.others_count);
      },
      (err) => {
        console.log('err data: ', err);
      });

  }

  // const handleApprove = () => {
  //   const payload = {
  //     session_id: id,
  //     reschedule_status: 'approved',
  //   };
  //   return GrommingReschduleApprove(payload).then((res) => {
  //     if (res.data.status === true) {
  //       swal({
  //         title: res.data.message,
  //         icon: 'success',
  //         timer: 2000,
  //         button: false,
  //       });
  //       handleRefresh((preval) => {
  //         return !preval;
  //       });
  //       updatecount();
  //     } else {
  //       swal({
  //         title: res.data.message,
  //         icon: 'danger',
  //         timer: 2000,
  //         button: false,
  //       });
  //     }
  //   });
  // };
  const navigate = useNavigate();

  // const handleApprove = () => {
  //   const payload = {
  //     session_id: id,
  //     reschedule_status: 'approved',
  //   };


  //   // Show confirmation dialog before proceeding
  //   setIsOpen(false);
  //   if(alldata.user_name){
  //   swal.fire({
  //     title: 'Do you want to change sitter or continue with same sitter ?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Change',
  //     cancelButtonText: 'No Change'
  //   }).then((result) => {
  //   console.log('result :', result);
  //     if (result.isConfirmed === true) {
        
  //       GrommingReschduleApprove(payload).then((res) => {
  //         console.log(res.data.status)
  //         if (res.data.status === true) {

  //           navigate('/dashboard/groomingSitterAllocation');

  //           // swal({
  //           //   title: res.data.message,
  //           //   icon: 'success',
  //           //   timer: 2000,
  //           //   button: false,
  //           // });
            
  //           handleRefresh((preval) => {
  //             return !preval;
  //           });
  //           updatecount();
  //         }
  //       })
  //     } else if(result.isConfirmed === false && result.dismiss === "cancel") {
  //           console.log("test")
  //           GrommingReschduleApprove(payload).then((res) => {
  //             if (res.data.status === true) {
  //               console.log("test1")
  //               swal.fire({
  //                 title: res.data.message,
  //                 icon: 'success',
  //                 timer: 2000, // Duration to automatically close the alert (in milliseconds)
  //                 showConfirmButton: false, // Hide the confirmation button
  //               });
  //               handleRefresh((preval) => {
  //                 return !preval;
  //               });
  //               updatecount();
  //           }
  //           // swal({
  //           //   title: res.data.message,
  //           //   icon: 'danger',
  //           //   timer: 2000,
  //           //   button: false,
  //           // });
  //         })
  //         }
      
  //   });
  // }
  // };

  const handleApprove = () => {
    const payload = {
      session_id: id,
      reschedule_status: 'approved',
    };
  
    // Show confirmation dialog before proceeding
    setIsOpen(false);
    localStorage.setItem('getGroomingAllocationID', servicemasterId);
    localStorage.setItem('getGroomingAllocationDate', startDate);
    localStorage.setItem('groomingCalenderData',JSON.stringify(alldata));
  
    if (alldata.hasOwnProperty('sitter_id')) {
      // If user_name exists, show the confirmation dialog
      swal.fire({
        title: 'Do you want to change sitter or continue with the same sitter?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Change',
        cancelButtonText: 'No Change'
      }).then((result) => {
        console.log('result :', result);
        if (result.isConfirmed === true) {
          // If user confirms, approve the request
          const payloadWithChangeSitter = {
            ...payload,
            is_change_sitter: true,
          };
          GrommingReschduleApprove(payloadWithChangeSitter).then((res) => {
            console.log(res.data.status);
            if (res.data.status === true) {
              // If request is approved, navigate and update counts
              // navigate('/dashboard/groomingSitterAllocation');
              navigate('/dashboard/groomingAllocationPage');
              handleRefresh((preval) => !preval);
              updatecount();
            }
          });
        } else if (result.isConfirmed === false && result.dismiss === "cancel") {
          // If user cancels, perform some action (optional)
          const payloadWithChangeSitter = {
            ...payload,
            is_change_sitter: false,
          };
          GrommingReschduleApprove(payloadWithChangeSitter).then((res) => {
            if (res.data.status === true) {
              navigate('/dashboard/groomingBookingUpcoming');
              swal.fire({
                title: res.data.message,
                icon: 'success',
                timer: 2000, // Duration to automatically close the alert (in milliseconds)
                showConfirmButton: false, // Hide the confirmation button
              });
              handleRefresh((preval) => !preval);
              updatecount();
            }
          });
        }
      });
    } else {
      const payloadWithChangeSitter = {
        ...payload,
        is_change_sitter: true,
      };
      return GrommingReschduleApprove(payloadWithChangeSitter).then((res) => {
            if (res.data.status === true) {
              // navigate('/dashboard/groomingSitterAllocation');
              navigate('/dashboard/groomingAllocationPage');
              // swal.fire({
              //   title: res.data.message,
              //   icon: 'success',
              //   timer: 2000,
              //   button: false,
              // });
              handleRefresh((preval) => {
                return !preval;
              });
              updatecount();
            } else {
              swal.fire({
                title: res.data.message,
                icon: 'danger',
                timer: 2000,
                button: false,
              });
            }
          });
    }
  };
  


  const handleReject = () => {
  
    const payload = {
      session_id: id,
      reschedule_status: 'rejected',
    };
    return GrommingReschduleApprove(payload).then((res) => {
      if (res.data.status === true) {
        swal({
          title: res.data.message,
          icon: 'success',
          timer: 2000,
          button: false,
        });
        handleRefresh((preval) => {
          return !preval;
        });
        updatecount();
      } else {
        swal({
          title: res.data.message,
          icon: 'danger',
          timer: 2000,
          button: false,
        });
      }
    });
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem disabled={currentStatus === 'approved' ? true : false} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon={'clarity:success-standard-solid'} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={currentStatus === 'approved' ? 'Approved' : 'Approve'}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={handleApprove}
          />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} component={RouterLink} to="/dashboard/groomingReschduleHistory">
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={currentStatus === 'rejected' ? 'Rejected' : 'Reject'}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={handleReject}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default GroomingReshduleMoreMenu;
