/* eslint-disable camelcase */
/* eslint-disable */
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { GroomingCancellationListHead } from '../../../sections/@dashboard/groomingCancellation';
// mock
import {
  getGroomingCancellationHistoryData,
  getGroomingCancellationHistorySearchData,
} from '../../../common/api-service';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import BackButton from '../../../components/BackButton';

import { DatePicker } from 'antd';
import { ShowChart } from '@mui/icons-material';
import CustomerOrderDetails from '../CustomerOrderDetails';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'booking_id', label: 'Booking Id', alignRight: false },
  { id: 'user_name', label: 'Name', alignRight: false },
  // { id: 'pet_name', label: 'Pet Name', alignRight: false },
  { id: 'pet_type', label: 'Pet Type', alignRight: false },
  // { id: 'service_name', label: 'Service Name', alignRight: false },
  { id: 'date_of_booking', label: 'Booking Date', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'time_slot', label: 'Time Slot', alignRight: false },
  { id: 'method_of_payment', label: 'Method of Payment', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  // { id: 'type_of_session', label: 'Type Of Session', alignRight: false },
  { id: 'status_of_payment', label: 'Status of Payment', alignRight: false },
  // { id: 'add_ons', label: 'Add Ons', alignRight: false },
  { id: 'package_name', label: 'Package', alignRight: false },
  // { id: 'user_contact_no', label: 'Phone No', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
  // { id: 'sitter_phone_no', label: 'Sitter Contact', alignRight: false },
  // { id: 'typeof_session', label: 'Type of Session', alignRight: false },
  // { id: 'sessions', label: 'Number of Session', alignRight: false },
  { id: 'reason_by_user', label: 'Reason', alignRight: false },
  { id: 'cancellation_request_date', label: 'Cancellation Request Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'offer_description', label: 'Offer Description', alignRight: false },
  // { id: 'offer_count', label: 'Offer Count', alignRight: false },
  // { id: 'offer_remaining', label: 'Offer Remaining', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

export default function GroomingCancellationHistory() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filteredData, setFilteredData] = useState([]);
  console.log('filteredData cancellationSZ', filteredData)


  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(false);
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('created_at')

  const [defaultStartDate, setDefaultStartDate] = useState(dayjs().startOf('week'));
  const [defaultEndDate, setDefaultEndDate] = useState(dayjs());
  const [popupOpen, setPopupOpen] = useState(false);
  const [show, setShow] = useState(false);


  const timeSlot = () => {
    setLoading(true);
    const payload = page;
    return getGroomingCancellationHistoryData(payload, searchQuery, defaultStartDate.add(1, 'day'), defaultEndDate.add(1, 'day'), selectedDate, rowsPerPage, defaultStartDate, defaultEndDate).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    timeSlot();
    // handleSearch();
  }, [offset, page, searchQuery, fromDate, toDate, selectedDate, rowsPerPage, show]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const [selectedDate, setSelectedDate] = useState('date');

  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredData([])
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleSearch = () => {
    return getGroomingCancellationHistorySearchData(page, searchQuery).then(
      (res) => {
        setData(res.data.data);
        setFilteredData(res.data.data.get_paginated);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // handleSearch();
    }
  };

  // const datefilterhandleChange = (props) => {
  //   if (props) {
  //     const [start, end] = props;
  //     setFromDate(start);
  //     setToDate(end);
  //   } else {
  //     setFromDate('');
  //     setToDate('');
  //   }
  // };

  const datefilterhandleChange = (props) => {

    const [start, end] = props;

    setDefaultStartDate(start);
    setDefaultEndDate(end)
    // setDefaultEndDate(end.add(1, 'day')

  }

  // const handleReferenceID = (booking_ref_id, number_of_sessions, session_ref_id, session_id) => {
  //   localStorage.setItem("bookingReferenceId", booking_ref_id);
  //   localStorage.setItem("s1", "Cancellation History");
  //   // localStorage.setItem("cancellationBookingReferenceId", number_of_sessions <=1 ? booking_ref_id : session_ref_id);
  //   localStorage.setItem("cancellationBookingReferenceId", session_id);


  //   // console.log('ref id', e);
  // }

  const handleReferenceID = (booking_ref_id, session_id) => {
    setShow(true);
    // navigate('/dashboard/customerorderDetails', { state: { defaultStartDate, defaultEndDate} });
    localStorage.setItem("bookingReferenceId", booking_ref_id);
    localStorage.setItem("cancellationBookingReferenceId", session_id);
    localStorage.setItem("s1", "Ongoing")

  }

  return (
    <>
      {show ?
        (<CustomerOrderDetails setShow={setShow} />
        ) : (
          <Page title="User">
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h4" gutterBottom>
                  Grooming Cancellation History
                </Typography>
                {/* <BackButton /> */}
              </Stack>
              <Stack style={{ marginBottom: '10px' }} direction="row-reverse" alignItems="center">
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Search"
                    id="fullWidth"
                    InputProps={{
                      style: { margin: '6px 2px', height: '34px' },
                      endAdornment: (
                        <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={handleSearch}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { fontSize: '16px', marginTop: '-4px' },
                    }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.replace(/[^A-Za-z0-9]/g, ''))}
                    onKeyDown={handleKeyPress}
                  />
                </Grid>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    {/* <RangePicker onChange={datefilterhandleChange} 
              defaultValue={[defaultStartDate, defaultEndDate]}
              /> */}

                    <RangePicker
                      defaultValue={[defaultStartDate, defaultEndDate]}
                      open={popupOpen}
                      onOpenChange={(open) => {
                        if (open && defaultStartDate && defaultStartDate[0] && defaultStartDate[1]) {
                          setPopupOpen(true);
                        } else {
                          setPopupOpen(open);
                        }
                      }}
                      onChange={datefilterhandleChange}
                      renderExtraFooter={() => (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px" }}>
                          <Button onClick={() => { setPopupOpen(false); timeSlot(); }} variant='contained' size='small' >Submit</Button>
                        </Box>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Select
                      style={{ margin: '0px 0px  0px 2px', height: '34px' }}
                      labelId="allocation-label"
                      id="allocation"
                      value={selectedDate}
                      onChange={handleChangeDate}
                    // inputProps={{ 'aria-label': 'Select' }}
                    >
                      <MenuItem value="All" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="start_date">Start Date</MenuItem>
                      <MenuItem value="date_of_booking" selected>Booking Date</MenuItem>
                      <MenuItem value="created_at">Cancelled Date</MenuItem>

                      {/* <MenuItem value="reschedule_date">Reschedule Date</MenuItem> */}
                    </Select>
                  </Grid>
                </Grid>



              </Stack>
              <Card>
                {loading && <CircularProgress style={{ position: 'absolute', top: '10%', left: '50%' }} />}
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    {filteredData.length === 0 ? (
                      <Typography component="div">
                        {/* <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box> */}
                        {loading ? (
                          <Box sx={{ textAlign: 'center', m: 1 }}>Wait, data is loading...</Box>
                        ) : (
                          <Box sx={{ textAlign: 'center', m: 1 }}>No Data available</Box>
                        )}
                      </Typography>
                    ) : (
                      <Table size='small'>
                        <GroomingCancellationListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={data.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredData && filteredData.map((row) => {


                            const {
                              _id,
                              user_name,
                              pet_name,
                              pet_type,
                              service_name,
                              date_of_booking,
                              start_date,
                              time_slot,
                              payment_method,
                              amount,
                              add_ons,
                              package_name,
                              user_contact_no,
                              user_location,
                              sitter_name,
                              sitter_phone_no,
                              number_of_sessions,
                              reason_by_user,
                              cancellation_request_date,
                              status,
                              offer_description,
                              offer_count,
                              offer_remaining,
                              pay_on_service,
                              booking_ref_id,
                              session_id,
                              session_ref_id,
                              price_paid
                            } = row;
                            const isItemSelected = selected.indexOf(_id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={_id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, number_of_sessions, session_ref_id, session_id)}>
                              {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}
                            </Link>
                          </TableCell> */}
                                <TableCell sx={{ textAlign: 'center' }} onClick={() => handleReferenceID(booking_ref_id, session_id)} >
                                  {/* <Link className='details-using-ref-id' to={'/dashboard/customerorderDetails'} onClick={() => handleReferenceID(booking_ref_id, _id)}> */}
                                  {/* {booking_ref_id} */}
                                  <span className='booking-ref-ids'> {number_of_sessions <= 1 ? booking_ref_id : session_ref_id}</span>
                                  {/* </Link> */}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                                  <Typography variant="subtitle2" noWrap>
                                    {user_name}
                                  </Typography>
                                </TableCell>
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {pet_name.join(' , ')}
                          </TableCell> */}
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {pet_type.join(' , ')}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {service_name}
                          </TableCell> */}
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  <Typography>{date_of_booking ? moment(date_of_booking).format('DD-MM-YYYY') : date_of_booking}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  <Typography>{start_date ? moment(start_date).format('DD-MM-YYYY') : start_date}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {time_slot}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {payment_method === ''  ? 'Pay after service' : 'Online'}
                          </TableCell> */}
                                <TableCell sx={{ textAlign: 'center' }}>{pay_on_service === true ? 'Pay after service' : "Online"}</TableCell>
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {/* {amount} */}
                                  {price_paid}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell> */}
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {/* {payment_method === '' ? 'Not Paid' : 'Paid'} */}
                                  {payment_method === '' ? 'Not Paid' : `Paid${pay_on_service ? ` (${payment_method})` : ''}`}
                                </TableCell>
                                {/* <TableCell component="th" scope="row" padding="none" sx={{  textAlign: 'center' }}>
                              {add_ons?.map((d, i) => (
                                <Typography variant="subtitle2" wordWrap>
                                  {d.title}
                                </Typography>
                              ))}
                          </TableCell> */}
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {package_name}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {user_contact_no}
                          </TableCell> */}
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            <a
                              style={{ textDecoration: 'none', color: '#32a852' }}
                              href={`https://www.google.com/maps?q=${user_location?.coordinates[0]},${user_location?.coordinates[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View on Google Map
                            </a>
                          </TableCell> */}
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {sitter_name ? sitter_name : '-'}
                                </TableCell>
                                {/* <TableCell align="left" sx={{ textAlign: 'center' }}>
                            {sitter_phone_no ? sitter_phone_no : '-'}
                          </TableCell> */}
                                {/* <TableCell align="center" sx={{ textAlign: 'center' }}>
                            {number_of_sessions === 1 || number_of_sessions === 0 ? 'Single' : 'Subscription'}
                          </TableCell>
                          <TableCell align="center" sx={{ textAlign: 'center' }}>
                            {number_of_sessions ? number_of_sessions : '-'}
                          </TableCell> */}
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {reason_by_user}
                                </TableCell>
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  <Typography>{cancellation_request_date ? moment(cancellation_request_date).format('DD-MM-YYYY') : cancellation_request_date}</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ textAlign: 'center' }}>
                                  {status}
                                </TableCell>
                                {/* offer columns */}
                                {/* <TableCell align="center">{offer_description}</TableCell>
                          <TableCell align="center">{offer_count}</TableCell>
                          <TableCell align="center">{offer_remaining}</TableCell> */}
                              </TableRow>
                            );
                          })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25, 50, 150]}
                  component="div"
                  // count={data.number_of_records}
                  count={data.total_number_of_records}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
          </Page>
        )
      }
    </>
  );
}
