/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import swal from 'sweetalert';
import { dropDownServiceMaster, addSingleSitter } from '../../common/api-service';

function EditBulkUpload() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];

  function getStyles(type, Name, theme) {
    return {
      fontWeight: Name.indexOf(type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
      //  theme.typography.fontWeightRegular,
    };
  }

  function getStylesV2() {
    return {
      fontWeight: theme.typography.fontWeightRegular,
      //  theme.typography.fontWeightRegular,
    };
  }

  const [sitterPhone, setSitterPhone] = useState('');
  const [sitterEmail, setSitterEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [sitterAge, setSitterAge] = React.useState();
  const [sitterGender, setSitterGender] = React.useState('');
  const [sitterAlterPhone, setSitterAlterPhone] = React.useState('');
  const [sitterCovid, setSitterCovid] = React.useState('');
  const [sitterAddress, setSitterAddress] = React.useState('');

  const [sitterArea, setSitterArea] = React.useState('');
  const [sitterPinCode, setSitterPinCode] = React.useState();
  const [sitterOccupation, setSitterOccupation] = React.useState('');
  const [sitterStay, setSitterStay] = React.useState('');
  const [sitterBenificiary, setSitterBenificiary] = React.useState('');

  const [sitterIfsc, setSitterIfsc] = React.useState('');
  const [sitterAccountNo, setSitterAccountNo] = React.useState('');
  const [sitterUpi, setSitterUpi] = React.useState('');
  const [sitterServices, setSitterServices] = React.useState([]);

  const [sitterCareExperiance, setSitterCareExperiance] = React.useState('');
  const [sitterYearExperience, setSitterYearExperience] = React.useState('');
  const [sitterHavePet, setSitterHavePet] = React.useState('');
  const [sitterTypePet, setSitterTypePet] = React.useState('');
  const [sitterPetName, setSitterPetName] = React.useState('');

  const [sitterBreed, setSitterBreed] = React.useState('');
  const [sitterPetAge, setSitterPetAge] = React.useState();
  const [sitterPetGender, setSitterPetGender] = React.useState('');
  const [sitterPetGenderBoarding, setSitterPetGenderBoarding] = React.useState('');
  const [sitterPetNeutered, setSitterPetNeutered] = React.useState('');

  const [sitterLinveIn, setSitterLinveIn] = React.useState('');
  const [sitterPetProofed, setSitterPetProofed] = React.useState('');
  const [sitterBalconyPet, setSitterBalconyPet] = React.useState('');
  const [sitterTerracePet, setSitterTerracePet] = React.useState('');
  const [sitterGardenPet, setSitterGardenPet] = React.useState('');

  const [sitterNumPetBoard, setSitterNumPetBoard] = React.useState();
  const [sitterTargetPet, setSitterTargetPet] = React.useState('');
  const [sitterGardenPetBoarding, setSitterGardenPetBoarding] = React.useState('');
  const [sitterSizePetBoarding, setSitterSizePetBoarding] = React.useState('');
  const [sitterAggersivePet, setSitterAggersivePet] = React.useState('');

  const [sitterOnlyOnePet, setSitterOnlyOnePet] = React.useState('');
  const [sitterSpecialNeed, setSitterSpecialNeed] = React.useState('');
  const [sitterDocSubmitted, setSitterDocSubmitted] = React.useState('');
  const [sitterQuiz, setSitterQuiz] = React.useState('');
  const [sitterBasicGrooming, setSitterBasicGrooming] = React.useState('');

  const [sitterBasicTraining, setSitterBasicTraining] = React.useState('');
  const [sitterPickDrop, setSitterPickDrop] = React.useState('');
  const [sitterNonVegFood, setSitterNonVegFood] = React.useState('');
  const [sitterOralMedicine, setSitterOralMedicine] = React.useState('');
  const [sitterSmallIntro, setSitterSmallIntro] = React.useState('');

  const [sitterLeashTraining, setSitterLeashTraining] = React.useState('');
  const [sitterPoopPick, setSitterPoopPick] = React.useState('');
  const [sitterWalkDog, setSitterWalkDog] = React.useState();
  const [sitterSizePetWalk, setSitterSizePetWalk] = React.useState('');
  const [sitterWalkMorning, setSitterWalkMorning] = React.useState('');

  const [sitterWalkAfterNoon, setSitterWalkAfterNoon] = React.useState('');
  const [idget, setIdget] = React.useState('');
  const [sitterWalkEvening, setSitterWalkEvening] = React.useState('');
  const [sitterAreaSpcialization, setSitterAreaSpcialization] = React.useState([]);
  const [sitterEquipment, setSitterEquipment] = React.useState('');
  const [sitterCertificate, setSitterCertificate] = React.useState('');
  const [sitterTraningMethod, setSitterTraningMethod] = React.useState([]);
  const [sitterServiceCompleted, setSitterServiceCompleted] = React.useState();
  const [sitterRepeatClient, setSitterRepeatClient] = React.useState();
  const [sitterRating, setSitterRating] = React.useState();
  const [sitterCity, setSitterCity] = React.useState('');

  const [phoneExists, setPhoneExists] = useState(false);
  const phoneRef = React.useRef(null);

  const [data, setData] = useState([]);

  const serviceMaster = (payload) => {
    return dropDownServiceMaster(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    serviceMaster();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      name: userName,
      email: sitterEmail,
      phone: sitterPhone,
      alternate_phone: sitterAlterPhone,
      age: sitterAge,
      gender: sitterGender,
      covid_vaccinated: sitterCovid,
      address: sitterAddress,
      sub_area: sitterArea,
      pincode: sitterPinCode,
      occupation: sitterOccupation,
      staying_with: sitterStay,
      bank_account_beneficiary: sitterBenificiary,
      ifsc_code: sitterIfsc,
      account_number: sitterAccountNo,
      upi_id: sitterUpi,
      services: sitterServices,
      pet_care_experience: sitterCareExperiance,
      years_of_experience: sitterYearExperience,
      have_a_pet: sitterHavePet,
      type_of_pet: sitterTypePet,
      pets_name: sitterPetName,
      pet_breed: sitterBreed,
      pet_age: sitterPetAge,
      pet_gender: sitterPetGender,
      is_pet_neutered: sitterPetNeutered,
      live_in: sitterLinveIn,
      property_pet_proofed: sitterPetProofed,
      balcony_for_pets: sitterBalconyPet,
      terrace_for_pets: sitterTerracePet,
      garden_for_pets: sitterGardenPet,
      no_of_pets_to_board: sitterNumPetBoard,
      target_pet: sitterTargetPet,
      gender_of_pet_for_boarding: sitterPetGenderBoarding,
      size_of_pet_for_boarding: sitterSizePetBoarding,
      can_handle_aggressive_pets: sitterAggersivePet,
      open_to_only_one_pet: sitterOnlyOnePet,
      board_pets_with_special_needs: sitterSpecialNeed,
      verification_docs_submitted: sitterDocSubmitted,
      finished_quiz: sitterQuiz,
      basic_grooming: sitterBasicGrooming,
      basic_training: sitterBasicTraining,
      pick_and_drop_service: sitterPickDrop,
      provide_non_veg_food: sitterNonVegFood,
      oral_medicine_administration: sitterOralMedicine,
      small_introduction: sitterSmallIntro,
      leash_training: sitterLeashTraining,
      poop_picking: sitterPoopPick,
      walk_dogs_at_a_time: sitterWalkDog,
      size_of_pet_for_walk: sitterSizePetWalk,
      walking_during_morning: sitterWalkMorning,
      walking_during_afternoon: sitterWalkAfterNoon,
      walking_during_evening: sitterWalkEvening,
      areas_of_specialization: sitterAreaSpcialization,
      use_pet_friendly_equipment: sitterEquipment,
      level_of_certification: sitterCertificate,
      training_methods: sitterTraningMethod,
      services_completed: sitterServiceCompleted,
      repeat_clients: sitterRepeatClient,
      city: sitterCity,
      rating: sitterRating,
    };

    return addSingleSitter(payload).then(
      (res) => {
        if (res.data.status === true) {
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/bulk_upload_details', { replace: true });
        } else if(res.data.message === "Phone number already exists..!") {
          
          setPhoneExists(true);
          phoneRef.current.focus();
        } else {
          swal({
            title: res.data.message,
            icon: 'error',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        swal({
          title: err,
          icon: 'error',
          timer: 2000,
          button: false,
        });
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Add Sitter
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Name */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      value={userName}
                      name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                      // onChange={(e) => setUserName(e.target.value.replace(/[^a-zA-Z0-9@./;:-\s]/g, ''))}
                      onChange={(e) => setUserName(e.target.value === ' ' ? e.target.value.trim().replace(/[^a-zA-Z@./;:-\s]/g, '') : e.target.value.replace(/[^a-zA-Z@./;:-\s]/g, '') || e.target.value.trim().replace(/[^\S[a-zA-Z ]+$]/g, ''))}

                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Email */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      value={sitterEmail}
                      name="email"
                      {...register('email', {
                        required: true,
                      })}
                      required
                      onChange={(e) => setSitterEmail(e.target.value.replace(/[^a-zA-Z0-9@.]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {errors.email && errors.email.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Gender */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Gender</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterGender}
                      name="gender"
                      // {...register('gender', {
                      //   required: true,
                      // })}
                      // required
                      onChange={(e) => {
                        setSitterGender(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}

                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.gender && errors.gender.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* City */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      value={sitterCity}
                      // required
                      // {...register('city', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterCity(e.target.value.replace(/[^a-zA-Z0-9@./;:-\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.city && errors.city.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Covid Vaccinated */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Covid Vaccinated</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterCovid}
                      // required
                      // {...register('sitterCovid', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterCovid(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Shot 1" style={getStylesV2()}>
                        Shot 1
                      </MenuItem>
                      <MenuItem value="Shot 2" style={getStylesV2()}>
                        Shot 2
                      </MenuItem>
                      <MenuItem value="None" style={getStylesV2()}>
                        None
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterCovid && errors.sitterCovid.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* Phone Number */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                      value={sitterPhone}
                      required
                      {...register('sitterPhone', {
                        required: true,
                      })}
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => setSitterPhone(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                      inputRef={phoneRef}
                    />
                    {errors.sitterPhone && errors.sitterPhone.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                    {phoneExists && ( // Show error message if phoneExists is true
                      <p className="text-danger" style={{ color: 'red' }}>
                        * Phone number already exists
                      </p>
                    )}
                  </Grid>

                  {/* Alternate Phone Number */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Alternate Phone"
                      variant="outlined"
                      value={sitterAlterPhone}
                      required
                      {...register('sitterAlterPhone', {
                        required: true,
                      })}
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => setSitterAlterPhone(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {errors.sitterAlterPhone && errors.sitterAlterPhone.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Age */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Age"
                      variant="outlined"
                      value={sitterAge}
                      // required
                      // {...register('sitterAge', {
                      //   required: true,
                      // })}
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => setSitterAge(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterAge && errors.sitterAge.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Address */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Address"
                      variant="outlined"
                      value={sitterAddress}
                      // required
                      // {...register('sitterAddress', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterAddress(e.target.value.replace(/[^a-zA-Z0-9@./;:-\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterAddress && errors.sitterAddress.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* sub_area */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Sub  Area"
                      variant="outlined"
                      value={sitterArea}
                      // required
                      // {...register('sitterArea', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterArea(e.target.value.replace(/[^a-zA-Z0-9@./;:-\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterArea && errors.sitterArea.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Pincode */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pincode"
                      variant="outlined"
                      value={sitterPinCode}
                      // required
                      // {...register('sitterPinCode', {
                      //   required: true,
                      // })}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => setSitterPinCode(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterPinCode && errors.sitterPinCode.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Occupation */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Occupation</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterOccupation}
                      // required
                      // {...register('sitterOccupation', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterOccupation(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Home Maker" style={getStylesV2()}>
                        Home Maker
                      </MenuItem>
                      <MenuItem value="Student" style={getStylesV2()}>
                        Student
                      </MenuItem>
                      <MenuItem value="Self Employed" style={getStylesV2()}>
                        Self Employed
                      </MenuItem>
                      <MenuItem value="Working in company" style={getStylesV2()}>
                        Working in company{' '}
                      </MenuItem>
                      <MenuItem value="Profession like doctor, CA, Painter, Writer" style={getStylesV2()}>
                        Profession like doctor, CA, Painter, Writer
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterOccupation && errors.sitterOccupation.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  {/* live In */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Live In</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterLinveIn}
                      // required
                      // {...register('sitterLinveIn', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterLinveIn(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Independant" style={getStylesV2()}>
                        Independant
                      </MenuItem>
                      <MenuItem value="House" style={getStylesV2()}>
                        House
                      </MenuItem>
                      <MenuItem value="Apartment" style={getStylesV2()}>
                        Apartment
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterLinveIn && errors.sitterLinveIn.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* staying_with */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Staying With</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterStay}
                      // required
                      // {...register('sitterStay', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterStay(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Family" style={getStylesV2()}>
                        Family
                      </MenuItem>
                      <MenuItem value="Friends" style={getStylesV2()}>
                        Friends
                      </MenuItem>
                      <MenuItem value="Alone" style={getStylesV2()}>
                        Alone
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterStay && errors.sitterStay.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* Target pet */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Target Pet</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterTargetPet}
                      required
                      {...register('sitterTargetPet', {
                        required: true,
                      })}
                      onChange={(e) => {
                        setSitterTargetPet(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Dog" style={getStylesV2()}>
                        Dog
                      </MenuItem>
                      <MenuItem value="Cat" style={getStylesV2()}>
                        Cat
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {errors.sitterTargetPet && errors.sitterTargetPet.type === 'required' && (
                    <p className="text-danger" style={{ color: 'red' }}>
                      *required
                    </p>
                  )}

                  {/* bank_account_beneficiary */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Bank Account Beneficiary"
                      variant="outlined"
                      value={sitterBenificiary}
                      // required
                      // {...register('sitterBenificiary', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterBenificiary(e.target.value.replace(/[^A-Za-z\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterBenificiary && errors.sitterBenificiary.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Ifsc Code */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Ifsc Code"
                      variant="outlined"
                      value={sitterIfsc}
                      // required
                      // {...register('sitterIfsc', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterIfsc(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterIfsc && errors.sitterIfsc.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* account_number */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Account Number"
                      variant="outlined"
                      value={sitterAccountNo}
                      // required
                      // {...register('sitterAccountNo', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterAccountNo(e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterAccountNo && errors.sitterAccountNo.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* upi_id */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="UPI Id"
                      variant="outlined"
                      value={sitterUpi}
                      // required
                      // {...register('sitterUpi', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterUpi(e.target.value.replace(/[^a-zA-Z0-9@.]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterUpi && errors.sitterUpi.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Service Master Dropdown */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Service Master</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={sitterServices}
                        required
                        {...register('sitterServices', {
                          required: true,
                        })}
                        onChange={(e) => {
                          setSitterServices(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                        MenuProps={MenuProps}
                      >
                        {data.map((service_master_id) => (
                          <MenuItem
                            key={service_master_id._id}
                            value={service_master_id._id}
                            style={getStyles(service_master_id, sitterServices, theme)}
                          >
                            {service_master_id.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.sitterServices && errors.sitterServices.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* pet type */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterTypePet}
                      // required
                      // {...register('sitterTypePet', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterTypePet(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Cat" style={getStylesV2()}>
                        Cat
                      </MenuItem>
                      <MenuItem value="Dog" style={getStylesV2()}>
                        Dog
                      </MenuItem>
                      <MenuItem value="Fish" style={getStylesV2()}>
                        Fish
                      </MenuItem>
                      <MenuItem value="Ohters" style={getStylesV2()}>
                        Ohters
                      </MenuItem>
                      <MenuItem value="NA, if you have no pet" style={getStylesV2()}>
                        NA, if you have no pet
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterTypePet && errors.sitterTypePet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* pet_care_experience */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Care Experience"
                      variant="outlined"
                      value={sitterCareExperiance}
                      // required
                      // {...register('sitterCareExperiance', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterCareExperiance(e.target.value.replace(/[^A-Za-z\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterCareExperiance && errors.sitterCareExperiance.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* years_of_experience */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Year Of Experience"
                      variant="outlined"
                      value={sitterYearExperience}
                      // required
                      // {...register('sitterYearExperience', {
                      //   required: true,
                      // })}
                      inputProps={{ maxLength: 3 }}
                      onChange={(e) => setSitterYearExperience(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterYearExperience && errors.sitterYearExperience.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* pets_name */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet name"
                      variant="outlined"
                      value={sitterPetName}
                      // required
                      // {...register('sitterPetName', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterPetName(e.target.value.replace(/[^a-zA-Z0-9-\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterPetName && errors.sitterPetName.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* pet_breed */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Breed"
                      variant="outlined"
                      value={sitterBreed}
                      // required
                      // {...register('sitterBreed', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterBreed(e.target.value.replace(/[^a-zA-Z\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterBreed && errors.sitterBreed.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* pet_age */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Pet Age"
                      variant="outlined"
                      value={sitterPetAge}
                      // required
                      // {...register('sitterPetAge', {
                      //   required: true,
                      // })}
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => setSitterPetAge(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterPetAge && errors.sitterPetAge.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* pet_gender */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Pet Gender</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterPetGender}
                      // required
                      // {...register('sitterPetGender', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterPetGender(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Male" style={getStylesV2()}>
                        Male
                      </MenuItem>
                      <MenuItem value="Female" style={getStylesV2()}>
                        Female
                      </MenuItem>
                      <MenuItem value="NA" style={getStylesV2()}>
                        NA
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterPetGender && errors.sitterPetGender.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}       */}
                  {/* no_of_pets_to_board */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">No Of pets To Board</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterNumPetBoard}
                      label="No Of pets To Board"
                      // required
                      // {...register('sitterNumPetBoard', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterNumPetBoard(+ e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="1" style={getStylesV2()}>
                        1
                      </MenuItem>
                      <MenuItem value="2" style={getStylesV2()}>
                        2
                      </MenuItem>
                      <MenuItem value="3" style={getStylesV2()}>
                        3
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterNumPetBoard && errors.sitterNumPetBoard.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}


                  {/* board_with_special_need */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">Board with special needs</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterSpecialNeed}
                      label="Board with special needs"
                      // required
                      // {...register('sitterSpecialNeed', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterSpecialNeed(e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Pet who is on heat cycle" style={getStylesV2()}>
                        Pet who is on heat cycle
                      </MenuItem>
                      <MenuItem value=" Pet who is shedding hair" style={getStylesV2()}>
                        {' '}
                        Pet who is shedding hair
                      </MenuItem>
                      <MenuItem value="Special care need pets like pregnancy, paralysis" style={getStylesV2()}>
                        Special care need pets like pregnancy, paralysis
                      </MenuItem>
                      <MenuItem value="Elder pets who have health issues" style={getStylesV2()}>
                        Elder pets who have health issues
                      </MenuItem>
                      <MenuItem value="Aggressive pets" style={getStylesV2()}>
                        Aggressive pets
                      </MenuItem>
                      <MenuItem value=" None of the above" style={getStylesV2()}>
                        {' '}
                        None of the above
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterSpecialNeed && errors.sitterSpecialNeed.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* gender_of_pet_for_boarding */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">Gender Of Pet For Boarding</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterPetGenderBoarding}
                      label="Gender Of Pet For Boarding"
                      // required
                      // {...register('sitterPetGenderBoarding', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterPetGenderBoarding(e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Male pets" style={getStylesV2()}>
                        Male pets
                      </MenuItem>
                      <MenuItem value="Female pets" style={getStylesV2()}>
                        Female pets
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterPetGenderBoarding && errors.sitterPetGenderBoarding.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* size_of_pet_for_boarding */}

                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">Size of pet for Boarding</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterSizePetBoarding}
                      label="Size of pet for Boarding"
                      // required
                      // {...register('sitterSizePetBoarding', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterSizePetBoarding(e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Puppy" style={getStylesV2()}>
                        Puppy
                      </MenuItem>
                      <MenuItem value="Small" style={getStylesV2()}>
                        Small
                      </MenuItem>
                      <MenuItem value="Medium" style={getStylesV2()}>
                        Medium
                      </MenuItem>
                      <MenuItem value="Large" style={getStylesV2()}>
                        Large
                      </MenuItem>
                      <MenuItem value="Giant" style={getStylesV2()}>
                        Giant
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterSizePetBoarding && errors.sitterSizePetBoarding.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* walk_dogs_at_a_time */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walk Dogs at a time</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkDog}
                        label="alk Dogs at a time"
                        // required
                        // {...register('sitterWalkDog', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterWalkDog(+ e.target.value);
                        }}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterWalkDog && errors.sitterWalkDog.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* size_of_pet_for_walk */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">Size of pet for Walk</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterSizePetWalk}
                      label="size_of_pet_for_walk"
                      // required
                      // {...register('sitterSizePetWalk', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterSizePetWalk(e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Puppy" style={getStylesV2()}>
                        Puppy
                      </MenuItem>
                      <MenuItem value="Small" style={getStylesV2()}>
                        Small
                      </MenuItem>
                      <MenuItem value="Medium" style={getStylesV2()}>
                        Medium
                      </MenuItem>
                      <MenuItem value="Large" style={getStylesV2()}>
                        Large
                      </MenuItem>
                      <MenuItem value="Giant" style={getStylesV2()}>
                        Giant
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterSizePetWalk && errors.sitterSizePetWalk.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* Areas of Specialization */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">Areas of Specialization</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterAreaSpcialization}
                      label="area of specialization"
                      // required
                      // {...register('sitterAreaSpcialization', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterAreaSpcialization(e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Trimming" style={getStylesV2()}>
                        Trimming
                      </MenuItem>
                      <MenuItem value="Hair Styling" style={getStylesV2()}>
                        Hair Styling
                      </MenuItem>
                      <MenuItem value=" Dog Diet Plans" style={getStylesV2()}>
                        {' '}
                        Dog Diet Plans
                      </MenuItem>
                      <MenuItem value="Skin & Hair conditioning tips" style={getStylesV2()}>
                        Skin & Hair conditioning tips
                      </MenuItem>
                      <MenuItem value="Handling Aggression" style={getStylesV2()}>
                        {' '}
                        Handling Aggression
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterAreaSpcialization && errors.sitterAreaSpcialization.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* Training Methods */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">Training Methods</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      // multiple
                      value={sitterTraningMethod}
                      // required
                      // {...register('sitterTraningMethod', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterTraningMethod(e.target.value);
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Positive Re-enforcement" style={getStylesV2()}>
                        Positive Re-enforcement
                      </MenuItem>
                      <MenuItem value="Military Tools" style={getStylesV2()}>
                        Military Tools
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterTraningMethod && errors.sitterTraningMethod.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* Small Introduction */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Small Introduction"
                      variant="outlined"
                      value={sitterSmallIntro}
                      // required
                      // {...register('sitterSmallIntro', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterSmallIntro(e.target.value.replace(/[^a-zA-Z0-9-\s]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterSmallIntro && errors.sitterSmallIntro.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* Level of Certification */}
                  <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                    <InputLabel id="demo-controlled-open-select-label">Level of Certification</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      // multiple
                      value={sitterCertificate}
                      label="Level of Certification"
                      // required
                      // {...register('sitterCertificate', {
                      //   required: true,
                      // })}
                      onChange={(e) => {
                        setSitterCertificate(e.target.value);
                      }}
                      // input={<OutlinedInput id="select-multiple-chip" label="Service Master" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="Basic Training" style={getStylesV2()}>
                        Basic Training
                      </MenuItem>
                      <MenuItem value="Intermediate Training" style={getStylesV2()}>
                        Intermediate Training
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {/* {errors.sitterCertificate && errors.sitterCertificate.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}

                  {/* Service Completed */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Service Completed"
                      variant="outlined"
                      value={sitterServiceCompleted}
                      // required
                      // {...register('sitterServiceCompleted', {
                      //   required: true,
                      // })}
                      onChange={(e) => setSitterServiceCompleted(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterServiceCompleted && errors.sitterServiceCompleted.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* have_a_pet */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-simple-select">Have a Pet</InputLabel>
                      <Select
                        labelId="demo-simple-select"
                        id="demo-simple-select"
                        // multiple
                        value={sitterHavePet}
                        //   required
                        // {...register('sitterHavePet', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterHavePet(e.target.value);
                        }}
                        input={<OutlinedInput id="demo-simple-select" label="Have a Pet" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterHavePet && errors.sitterHavePet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* is_pet_neutered */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">is_Pet Neutered</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPetNeutered}
                        label="is_Pet Neutered"
                        // required
                        // {...register('sitterPetNeutered', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterPetNeutered(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterPetNeutered && errors.sitterPetNeutered.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* property_pet_proofed */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Property Pet Proofed</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPetProofed}
                        label="Property Pet Proofed"
                        // required
                        // {...register('sitterPetProofed', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterPetProofed(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterPetProofed && errors.sitterPetProofed.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* balcony_for_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Balcony for Pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterBalconyPet}
                        label="Balcony for Pets"
                        // required
                        // {...register('sitterBalconyPet', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterBalconyPet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterBalconyPet && errors.sitterBalconyPet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* terrace_for_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Terrace for pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterTerracePet}
                        label="Terrace for pets"
                        // required
                        // {...register('sitterTerracePet', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterTerracePet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterTerracePet && errors.sitterTerracePet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* garden_for_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Garden for pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterGardenPet}
                        label="Garden for pets"
                        // required
                        // {...register('sitterGardenPet', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterGardenPet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterGardenPet && errors.sitterGardenPet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* can_handle_aggressive_pets */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Handle Aggressive Pets</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterAggersivePet}
                        label="Handle Aggressive Pets"
                        // required
                        // {...register('sitterAggersivePet', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterAggersivePet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterAggersivePet && errors.sitterAggersivePet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* open_to_only_one_pet */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Open to only one pet</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterOnlyOnePet}
                        label="Open to only one pet"
                        // required
                        // {...register('sitterOnlyOnePet', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterOnlyOnePet(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterOnlyOnePet && errors.sitterOnlyOnePet.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* verification_docs_submitted */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Verification docs submitted</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterDocSubmitted}
                        label="Verification docs submitted"
                        // required
                        // {...register('sitterDocSubmitted', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterDocSubmitted(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterDocSubmitted && errors.sitterDocSubmitted.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* finished_quiz */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Finished quiz</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterQuiz}
                        label="Finished quiz"
                        // required
                        // {...register('sitterQuiz', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterQuiz(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterQuiz && errors.sitterQuiz.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* basic_grooming */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Basic Grooming</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterBasicGrooming}
                        label="Basic Grooming"
                        // required
                        // {...register('sitterBasicGrooming', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterBasicGrooming(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                        <MenuItem value="May be, It depends on breed">May be, It depends on breed</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterBasicGrooming && errors.sitterBasicGrooming.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* basic_training */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Basic Traning</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterBasicTraining}
                        // required
                        // {...register('sitterBasicTraining', {
                        //   required: true,
                        // })}
                        label="Basic Traning"
                        onChange={(e) => {
                          setSitterBasicTraining(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                        <MenuItem value="May be, It depends on breed">May be, It depends on breed</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterBasicTraining && errors.sitterBasicTraining.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* pick_and_drop_service */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Pick and Drop Service</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPickDrop}
                        label="Pick and Drop Service"
                        // required
                        // {...register('sitterPickDrop', {
                        //   required: true,
                        // })}
                        onChange={(e) => {
                          setSitterPickDrop(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                        <MenuItem value="May be, It depends on breed">May be, It depends on breed</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterPickDrop && errors.sitterPickDrop.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* provide_non_veg_food */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Provide non veg food</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterNonVegFood}
                        label="Provide non veg food"
                        // required
                        // {...register('sitterNonVegFood', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterNonVegFood(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterNonVegFood && errors.sitterNonVegFood.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* oral_medicine_administration */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Oral medicine administration</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterOralMedicine}
                        label="Oral medicine administration"
                        // required
                        // {...register('sitterOralMedicine', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterOralMedicine(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                        <MenuItem value="May be, It depends on breed">May be, It depends on breed</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterOralMedicine && errors.sitterOralMedicine.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* leash_training */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Leash Training</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterLeashTraining}
                        label="Leash Training"
                        // required
                        // {...register('sitterLeashTraining', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterLeashTraining(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterLeashTraining && errors.sitterLeashTraining.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* poop_picking */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Poop Picking</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterPoopPick}
                        label="Leash Training"
                        // required
                        // {...register('sitterPoopPick', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterPoopPick(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterPoopPick && errors.sitterPoopPick.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                        )} */}
                  </Grid>

                  {/* walking_during_morning */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walking during morning</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkMorning}
                        label="Walking during morning"
                        // required
                        // {...register('sitterWalkMorning', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterWalkMorning(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterWalkMorning && errors.sitterWalkMorning.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* walking_during_afternoon */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walking during afternoon</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkAfterNoon}
                        label="Walking during afternoon"
                        // required
                        // {...register('sitterWalkAfterNoon', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterWalkAfterNoon(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterWalkAfterNoon && errors.sitterWalkAfterNoon.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* walking_during_evening */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Walking during Evening</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterWalkEvening}
                        label="Walking during Evening"
                        // required
                        // {...register('sitterWalkEvening', {
                        //   required: true,
                        //   })}                          
                        onChange={(e) => {
                          setSitterWalkEvening(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterWalkEvening && errors.sitterWalkEvening.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  {/* use_pet_friendly_equipment */}
                  <Grid>
                    <FormControl sx={{ mt: 3, minWidth: '100%' }}>
                      <InputLabel id="demo-controlled-open-select-label">Use pet friendly equipment</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={sitterEquipment}
                        label="Use pet friendly equipment"
                        // required
                        // {...register('sitterEquipment', {
                        //   required: true,
                        //   })}
                        onChange={(e) => {
                          setSitterEquipment(e.target.value);
                        }}
                      >
                        <MenuItem value="yes">yes</MenuItem>
                        <MenuItem value="no">no</MenuItem>
                      </Select>
                    </FormControl>
                    {/* {errors.sitterEquipment && errors.sitterEquipment.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* Repeat Clients */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Repeat Clients"
                      variant="outlined"
                      value={sitterRepeatClient}
                      // required
                      // {...register('sitterRepeatClient', {
                      //   required: true,
                      //   })}
                      onChange={(e) => setSitterRepeatClient(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterRepeatClient && errors.sitterRepeatClient.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                      )} */}
                  </Grid>

                  {/* Rating */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Rating"
                      variant="outlined"
                      value={sitterRating}
                      // required
                      // {...register('sitterRating', {
                      //   required: true,
                      //   })}
                      onChange={(e) => setSitterRating(+ e.target.value.replace(/[^0-9]/g, ''))}
                      style={{ width: '100%' }}
                    />
                    {/* {errors.sitterRating && errors.sitterRating.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )} */}
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Submit Sitter
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditBulkUpload;
