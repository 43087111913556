import React from 'react'
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Typography } from '@mui/material';

const GroomingJourneyListHead = ({headLabel}) => {
    return (
        <>
            <TableHead>
                <TableRow>
                    {headLabel.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                        >
                            <Typography sx={{ fontSize: '12pt' }}>
                                {headCell.label}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    )
}

export default GroomingJourneyListHead