import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import Button from '@mui/material/Button';
// component

// ----------------------------------------------------------------------
const SitterMoreMenu = ({ data, dataDate, rowData, rowData1 }) => {

  const navigate = useNavigate();

  const ref = useRef(null);
  const [id, setId] = useState(data);
  const [date, setStartDate] = useState(dataDate);
  const [calData, setCalData] = useState(rowData);
  const [calData1, setCalData1] = useState(rowData1);
  
  const [sitterName, setSitterName] = useState(null);

  const handleEdit = (data) => {
    // localStorage.setItem("s1","Sitter Allocations")
    // route[1] === "groomingBookingUpcoming" ? localStorage.setItem("s1","Upcoming") : localStorage.setItem("s1","Sitter Allocations")
    const setid = id;
    const setcalendarData = calData;
    const setdate = date;
    setSitterName(data.sitter_name)
    localStorage.setItem('groomingCalenderData', JSON.stringify(data));
    localStorage.setItem('getGroomingAllocationID', setid);
    localStorage.setItem('getGroomingAllocationDate', setdate);
  };

  return (
    <>
      <Button variant="contained" component={RouterLink} to="/dashboard/groomingAllocationPage" onClick={()=>{handleEdit(rowData)}}

        size='small'>
        { rowData?.sitter_name ||
          calData1 && calData1.length > 0 && calData1?.map((f) => f?.grooming_sessions[0]?.sitter_id)
          // calData.grooming_sessions.some(session => session.status.includes('requested')) ? 'Allocate' : (calData.grooming_sessions.length > 0 ? 'Reallocate' : '')

          ? "Reallocate" : "Allocate"
        }
      </Button >
    </>
  );
};

export default SitterMoreMenu;
