import React from 'react'
import { Link, Link as RouterLink } from 'react-router-dom';

import {
    Stack,
    Button,
    Typography,
    Grid,
} from '@mui/material';

const NavButtons = () => {
    return (
        <div>
            <Stack direction="row" alignItems="center" justifyContent="center" mb={2}>

                <Grid container spacing={1}>
                    <Grid item lg={4} md={4} sm={3} xs={12}>
                        <Typography variant="h4" >
                            Booking History
                        </Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={2} xs={12}>
                        <Button
                            sx={{ width: "100%" }}
                            variant="contained"
                            component={RouterLink}
                            to="/dashboard/groomingBookingHistory"
                        >
                            All
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={2} sm={3} xs={12}>
                        <Button
                            sx={{ width: "100%" }}
                            variant="outlined"
                            component={RouterLink}
                            to="/dashboard/groomingBookingUpcoming"
                        >
                            Upcoming Booking
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={2} sm={3} xs={12}>
                        <Button
                            sx={{ width: "100%" }}
                            variant="outlined"
                            component={RouterLink}
                            to="/dashboard/groomingBookingOngoing"
                        >
                            Ongoing Booking
                        </Button>
                    </Grid>
                    <Grid item lg={2.3} md={3} sm={3} xs={12}>
                        <Button
                            sx={{ width: "100%" }}
                            variant="outlined"
                            component={RouterLink}
                            to="/dashboard/groomingBookingCompleted"
                        >
                            Completed Booking
                        </Button>
                    </Grid>


                </Grid>






            </Stack>
        </div>
    )
}

export default NavButtons
