/* eslint-disable camelcase */
/* eslint prefer-const: "error" */
// /* eslint prefer-let: 2 */
/* eslint-env es6 */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { addTrainingLesson, dropDownSubLesson } from '../../../common/api-service';

function AddTrainingLesson() {
  const [AdminNotes, setAdminNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [lessonfieldCount, setLessonFieldCount] = useState([]);
  const [subLesson, setSubLesson] = useState([]);
  const [userName, setUserName] = useState('');
  const [lessonStatus, setStatus] = React.useState([]);
  const [serviceMasterId, setServiceMaster] = React.useState('62f646cde874f755ae088610');
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const lesson_status = ['active', 'disabled'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const subLessonTitle = (payload) => {
    return dropDownSubLesson(payload).then(
      (res) => {
        setData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    subLessonTitle();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      service_master_id: serviceMasterId,
      title: userName,
      sub_lessons: subLesson,
      admin_notes: AdminNotes,
      status: lessonStatus,
    };
    return addTrainingLesson(payload).then(
      (res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          navigate('/dashboard/trainingLesson', { replace: true });
          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: res.data.message,
            icon: 'error',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        Add Lesson
      </Typography>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid>
                    {/* Title */}
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      name="title"
                      required
                      {...register('title', {
                        required: true,
                      })}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Sub Titles */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Sub Lesson</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={subLesson}
                        onChange={(e) => {
                          setSubLesson(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Sub Lesson" />}
                        MenuProps={MenuProps}
                      >
                        {data.map((sub_lessons) => (
                          <MenuItem
                            key={sub_lessons._id}
                            value={sub_lessons}
                            style={getStyles(sub_lessons, subLesson, theme)}
                          >
                            {sub_lessons.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Admin Notes */}
                  <Grid sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={lessonStatus}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {lesson_status.map((lesson_status) => (
                          <MenuItem
                            key={lesson_status}
                            value={lesson_status}
                            style={getStyles(lesson_status, lessonStatus, theme)}
                          >
                            {lesson_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Add Lesson
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default AddTrainingLesson;
