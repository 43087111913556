import React, { useEffect, useState } from 'react';
import {
  Typography,
  Chip,
  Container,
  Box,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
  ThemeProvider,
  Tooltip,
  Button,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MapIcon from '@mui/icons-material/Map';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SitterGroomingMoreMenu } from '../../sections/@dashboard/sitterAllocationGrooming';

import BackButton from '../../components/BackButton';
import { getOrderDetailsData, getAllSessionsByBookingId } from '../../common/api-service';
import Scrollbar from '../../components/Scrollbar'
import { BookingHistoryListHead } from '../../sections/@dashboard/bookingHistory'
import BasicBreadcrumbs from './BreadCrumb';




//------------------------------------------------------------------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: 'session_id', label: 'Session No.', alignRight: true },
//   { id: 'start_date', label: 'Start Date', alignRight: false },
//   { id: 'time_slot', label: 'Time Slot', alignRight: false },

//   { id: 'sitter_name', label: 'Sitter Name', alignRight: false },
//   { id: 'sitter_contact', label: 'Sitter Contact', alignRight: false },

//   { id: 'reschedule_status', label: 'Reschedule Status', alignRight: false },
//   { id: 'reschedule_date', label: 'Reschedule date', alignRight: false },



//   { id: 'session_status', label: 'Status', alignRight: false },




//   { id: '' },
// ];


const CustomerOrderDetails = () => {
  const [orderDetailsdata, setOrderDetailsdata] = useState([]);
  const [addonPrice, setAdddonPrice] = useState();

  console.log('orderDetailsdata :', orderDetailsdata);
  //    const [orderId,setOrderId]=useState()
  //    setOrderId(localStorage.getItem('bookingReferenceId'))
  const upcomingrefID = localStorage.getItem('upcomingRefId');

  const getOrderDetails = () => {
    const payload = localStorage.getItem('bookingReferenceId');
    return getOrderDetailsData(payload).then((res) => {
      setOrderDetailsdata(res.data.booking_details);
    });
  };

  const [sessionDetailsdata, setSessionDetailsdata] = useState([]);
  console.log("sessionDetailsdata", sessionDetailsdata)

  const getSessionsData = () => {
    // const payload = localStorage.getItem('bookingReferenceId');
    {
      orderDetailsdata.map((bookId) => {
        const payload = `${bookId.grooming_book_services_id}`
        return getAllSessionsByBookingId(payload).then((res) => {
          setSessionDetailsdata(res.data.data);
        });
      })
    }

  };

  const [filteredOrders, setFilteredOrders] = useState({});
  console.log('filteredOrders========== :', filteredOrders);

  const filterGroomingSessions = () => {
    const filteredOrders = orderDetailsdata.map(order => ({
      ...order,
      grooming_sessions: order.grooming_sessions.filter(session => session._id === upcomingrefID)
    }));
    setFilteredOrders(filteredOrders);
  };

  useEffect(() => {
    filterGroomingSessions();
    getSessionsData();

  }, [orderDetailsdata]); // Trigger filtering whenever orderDetailsdata changes


  useEffect(() => {
    getOrderDetails();
  }, []);

  const theme = createTheme({
    palette: {
      divider: '#000000',
    },
  });

  const [showMoreMap, setShowMoreMap] = useState({});

  const toggleShowMore = (rowId) => {
    setShowMoreMap((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };



  return (
    <>
      <BasicBreadcrumbs />
      <BackButton />
      {orderDetailsdata.map((order) => {
        const truncatedDescription =
          order.offer_description.length > 20 ? `${order.offer_description.slice(0, 20)}...` : order.offer_description;
        return (
          <>
            <Container sx={{ display: 'flex' }}>
              <Typography variant="h4">Subscription ID #
                {order.booking_ref_id}
                {/* {order.number_of_sessions === 1 ? order.booking_ref_id : order.grooming_sessions[order.ongoing_session_number - 1].session_ref_id} */}
              </Typography>
              {order.payment_method === '' ? (
                <Chip
                  icon={<FiberManualRecordIcon style={{ color: '#d50000' }} />}
                  label="Not Paid"
                  size="small"
                  style={{
                    color: '#d50000',
                    backgroundColor: '#ef9a9a',
                    marginTop: '1%',
                    height: 'fit-content',
                    marginLeft: '2%',
                  }}
                />
              ) : (
                <Chip
                  icon={<FiberManualRecordIcon style={{ color: '#00a152' }} />}
                  label="Paid"
                  size="small"
                  style={{
                    color: '#00a152',
                    backgroundColor: '#a5d6a7',
                    marginTop: '1%',
                    height: 'fit-content',
                    marginLeft: '2%',
                  }}
                />
              )}


            </Container>
            <Box
              sx={{
                display: 'flex',
                color: 'white',
                fontSize: 'small',
                backgroundColor: '#006064',
                maxWidth: 'fit-content',
                padding: '3px 10px',
                margin: '10px 20px',
                borderRadius: '5px',
              }}
            >
              <MapIcon />
              <a
                style={{ textDecoration: 'none', color: 'white', marginTop: '1.5%' }}
                href={`https://www.google.com/maps?q=${order.location?.coordinates[0]},${order.location?.coordinates[1]}`}
                // href={`https://www.google.com/maps?q=3,5`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View location on Map
              </a>
            </Box>



            <Box ml={2} mt={3}>
              <Typography variant="h4">Booking Details</Typography>
              <Divider orientation="horizantal" />
              <Grid container spacing={2} mt={0.01}>
                <Grid item sm={6} xs={12} md={6} lg={6} sx={{ borderRight: '1px solid #b0bec5' }}>
                  <Typography>Customer Name : {order.parent_name} </Typography>
                  <Typography>Pet Name : {order.pet_name[0]}</Typography>
                  <Typography>Pet Type : {order.pet_type[0]} </Typography>
                  <Typography>Customer Contact Number : {order.parent_phone_no} </Typography>
                  <Typography>Booking Date :
                    {/* {moment(order.booking_date).format('DD-MM-YYYY')}  */}
                    {moment(order.grooming_sessions[order.ongoing_session_number - 1]?.created_at).format('DD-MM-YYYY')}

                  </Typography>


                </Grid>
                <Grid item sm={6} xs={12} md={6} lg={6}>
                  <Typography>Address :
                    {order.address}
                  </Typography>
                  <Typography>
                    Method of Payment : {order.pay_on_service === true ? `Pay after service ${order.payment_method === '' ? ' ' : `(${order.payment_method})`}` : 'Online'}{' '}
                  </Typography>
                  <Typography sx={{ display: `${order.number_of_sessions === 1 ? ' flex' : 'none'}` }}>Sitter Name :
                    {order.sitter_name}
                  </Typography>
                  <Typography sx={{ display: `${order.number_of_sessions === 1 ? ' flex' : 'none'}` }}>Sitter Contact Number : {order.sitter_phone_no} </Typography>
                  <Typography>
                    Booking Type :{' '}
                    {order.number_of_sessions === 1
                      ? 'One Time'
                      : `Subscription`}{' '}
                  </Typography>

                </Grid>
              </Grid>
              <Divider orientation="horizantal" />
            </Box>
            {/* ---------------------------------------------------------------------------------------------------------------------------------------- */}

            <Box ml={2} mt={4} mb={0} sx={{ maxHeight: 250, display: `${order.number_of_sessions === 1 ? 'none' : 'flex'}`, overflow: 'auto' }}>
              <Divider orientation="horizontal" />
              {/* <TableContainer sx={{ minWidth: 800 }}> */}
              {sessionDetailsdata.length === 0 ? (
                <Typography component="div">
                  <Box sx={{ textAlign: 'center', m: 1 }}>Wait data is loading!!!</Box>
                </Typography>
              ) : (
                <Table size='small' >
                  {/* <BookingHistoryListHead
                      headLabel={TABLE_HEAD}
                    /> */}

                  <TableHead sx={{minWidth:'150px'}}>
                    <TableRow>
                      <TableCell align="center">Session No.</TableCell>
                      <TableCell align="center">Start Date</TableCell>
                      <TableCell align="center">Time Slot</TableCell>

                      <TableCell align="center">Sitter Name</TableCell>
                      <TableCell align="center">Sitter Contact</TableCell>
                      <TableCell align="center">Reschedule Status</TableCell>

                      <TableCell align="center">Reschedule date</TableCell>
                      <TableCell align="center">Add On's</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sessionDetailsdata.map((row) => {
                      const {
                        _id,
                        session_number,
                        sitter_name,
                        sitter_contact,
                        date,
                        session_ref_id,
                        time_slot,
                        session_status,
                        request_for_rescheduled,
                        add_ons,

                      } = row;

                      return (
                        <TableRow
                          // hover
                          key={_id}
                        // tabIndex={-1}
                        // role="checkbox"

                        >
                          <TableCell style={{ cursor: 'pointer'  }} sx={{ textAlign: 'left' }}>
                            {session_ref_id}
                          </TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{date ? moment(date).format('DD-MM-YYYY') : '-'}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center'  , fontSize:'13px'}}>{time_slot ? time_slot : '-'}</TableCell>

                          <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_name ? sitter_name : '-'}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{sitter_contact ? sitter_contact : '-'}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{request_for_rescheduled === true ? 'Yes' : '-'}</TableCell>
                          <TableCell align="left" sx={{ textAlign: 'center' }}>{request_for_rescheduled === true ? moment(date).format('DD-MM-YYYY') : '-'}</TableCell>

                          <TableCell align="left" sx={{ textAlign: 'center', minWidth: '150px' ,  }}>
                            {row.add_ons.map((d, i) => (
                              <Typography
                                variant="body2"
                                key={i}
                                sx={{ display: (showMoreMap[row._id] || i < 1) ? 'block' : 'none' }}>
                                {d.title}
                              </Typography>
                            ))}
                            {row.add_ons.length > 1 && (
                              <span style={{cursor:'pointer' , color:'blue' , fontSize:'12px'}} onClick={() => toggleShowMore(row._id)}>
                                {showMoreMap[row._id] ? 'Show Less' : 'Show More '}
                              </span>
                            )}
                          </TableCell>





                          <TableCell align="left" sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{session_status}</TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {/* </TableContainer> */}
            </Box>
            {/* <Divider orientation="horizontal" /> */}


            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            <Box ml={2} mt={4} >
              <Divider orientation="horizontal" />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Service Name</TableCell>
                      <TableCell align="center">Package Name</TableCell>
                      <TableCell align="center">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{order.service_name}</TableCell>
                      <TableCell align="center">{order.package_title}</TableCell>
                      <TableCell align="center">
                        {order.number_of_sessions === 1
                          ? order.package_offer_price
                          : order.package.subscription_sessions_for_grooming[0].subscription_price}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">{"Add on's"}</TableCell>
                      <TableCell align="center">
                        {order.add_ons.length === 0
                          ? ' - '
                          : order.add_ons.map((d, i) => (
                            <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                              {d.title}
                            </Typography>
                          ))}
                      </TableCell>
                      <TableCell align="center">
                        {order.add_ons.length === 0
                          ? '0'
                          : order.add_ons
                            .map((d, i) => (
                              <Typography variant="subtitle2" key={i} sx={{ display: 'block' }}>
                                {d.price}
                              </Typography>
                            ))
                            .reduce((total, current) => total + parseInt(current.props.children, 10), 0)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">{'Free Services'}</TableCell>
                      {/* <TableCell align="center">{order.offer_description}</TableCell> */}
                      <TableCell align="center">
                        {order.number_of_sessions === 1 ? (
                          '-'
                        ) : (
                          <Tooltip title={order.offer_description} arrow>
                            <span>{truncatedDescription}</span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">{'0'}</TableCell>
                    </TableRow>
                    <ThemeProvider theme={theme}>
                      <TableRow>
        
                      </TableRow>
                    </ThemeProvider>
                    <TableRow>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="right">{'Total'}</TableCell>
                      <TableCell align="center">
                      
                        {parseInt(
                          (order.number_of_sessions === 1
                            ? parseInt(order.package_offer_price, 10)
                            : parseInt(order.package.subscription_sessions_for_grooming[0].subscription_price, 10)) +
                          (order.add_ons.length === 0
                            ? 0
                            : order.add_ons.reduce((total, current) => total + parseInt(current.price, 10), 0))
                        )}

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="right">{'Coupon discount'}</TableCell>
                      <TableCell align="center">{order.coupon_discount ? `${order.coupon_discount} ${order.coupon_discount_type === 'percentage' ? ' %' : ' Rs'}` : '0'}</TableCell>
                    </TableRow>
              
                    <TableRow>
                      <TableCell align="center"> </TableCell>
                      <TableCell align="right" sx={{ fontWeight: '700' }}>Grand Total</TableCell>
                      <TableCell align="center">{order.amount_paid} Rs</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider orientation="horizontal" />
            </Box>
          </>
        );
      })}





    </>
  );
};

export default CustomerOrderDetails;
