/* eslint-disable */
/* eslint-disable camelcase */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import { dropDownLesson, getTrainingPackagesDataForUpdate, trainingPackagesUpdate } from '../../common/api-service';

function EditDogTraining() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const pet_types = ['Dog', 'Cat'];
  const packages_status = ['active', 'disabled'];

  function getStyles(pet_type, petName, theme) {
    return {
      fontWeight:
        petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
  function getStyles1(pet_type, petName, theme) {
    return {
      fontWeight: theme.typography.fontWeightMedium,
      // petName.indexOf(pet_type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const [packagefieldCount, setPackageFieldCount] = useState([]);
  const [packageAddOnsfieldCount, setPackageAddOnsfieldCount] = useState([]);
  const [packageStatus, setPackageStatus] = React.useState('');
  const [idget, setIdget] = React.useState('');
  const [AdminNotes, setAdminNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [petName, setPetName] = React.useState([]);
  const [data, setData] = useState([]);
  const [packagePrice, setPackagePrice] = React.useState('');
  const [NumberOfPets, setNumberOfPets] = React.useState('');
  const [validityDay, setValidityDay] = React.useState('');
  const [checked, setChecked] = React.useState('');
  const [packageLessonfieldCount, setPackageLessonFieldCount] = useState([]);
  const [lessonsdata, setLessonData] = React.useState([]);
  const [offerPrice, setOfferPrice] = React.useState('');
  const [numberOfSession, setNumberOfSession] = React.useState('');
  const [validitySession, setValiditySession] = React.useState([]);
  const [freeSessions, setFreeSessions] = React.useState('');
  const [afterNoSessions, setAfterNoSessions] = React.useState('');
  const [lessonTitle, setLessonTitle] = React.useState('');
  const [extraLessonForFree, setExtraLessonsForFree] = React.useState([]);
  const [lessonNumberSession, setlessonNumberSession] = React.useState([]);

  const handleAdd = () => {
    const abc = [...packagefieldCount, []];
    setPackageFieldCount(abc);
  };

  const addNewhandleChange = (onChangevalue, i) => {
    const inputData = [...packagefieldCount];
    inputData[i] = onChangevalue.target.value;
    setPackageFieldCount(inputData);
  };

  const handleDelete = (i) => {
    const deleteVal = [...packagefieldCount];
    deleteVal.splice(i, 1);
    setPackageFieldCount(deleteVal);
  };

  const handleAddLesson = () => {
    const abc = [...packageLessonfieldCount, []];
    setPackageLessonFieldCount(abc);
  };
  const addNewhandleChangeLesson = (onChangevalue, i) => {
    const inputDataLesson = [...packageLessonfieldCount];
    inputDataLesson[i] = onChangevalue.target.value;
    setPackageLessonFieldCount(inputDataLesson);
  };

  const addNewhandleChangeNumberSession = (onChangevalue, i) => {
    let inputDataLesson = [...packageLessonfieldCount];
    inputDataLesson[i].push({ after_no_of_sessions: onChangevalue.target.value });

    setPackageLessonFieldCount(inputDataLesson);
  };

  const handleDeleteLesson = (i) => {
    const deleteVal = [...packageLessonfieldCount];
    deleteVal.splice(i, 1);
    setPackageLessonFieldCount(deleteVal);
  };

  const getTrainingPackageID = () => {
    const getTraningPackageID = localStorage.getItem('getTraningPackageID');
    setIdget(getTraningPackageID);
    const payload = {
      _id: getTraningPackageID,
    };
    console.log('Payload', payload);
    return getTrainingPackagesDataForUpdate(payload).then((res) => {
      if (res.data.status === true) {
        const data = res.data.data;
        console.log('package data', data);
        setUserName(data.title);
        setPackageFieldCount(data.description);

        const lessonTitle = data.lessons_for_admin;
        let lessonst1 = data.lessons_for_admin.map((mlesson, lin) => {
          return mlesson.map((childeless) => {
            if (childeless.title) {
              return childeless.title;
            }
          });
        });

        lessonst1.map((i, j) => {
          return lessonst1[j].splice(-1);
        });

        console.log('LessonTitleadkfjbdf', lessonTitle);
        console.log('lessonst1', lessonst1);
        setLessonTitle(lessonst1);
        setPackageLessonFieldCount(lessonst1);
        setAdminNotes(data.admin_notes);
        setPetName(data.target_pet);
        setPackagePrice(data.price);
        setNumberOfPets(data.number_of_pets);
        let lessonst = data.lessons_for_admin.map((mlesson, lin) => {
          return mlesson[mlesson.length - 1].after_no_of_sessions;
        });
        console.log('lessonst', lessonst);
        setlessonNumberSession(data.number_of_sessions);
        setFreeSessions(data.free_trial_sessions);
        setAfterNoSessions(lessonst);
        setValidityDay(data.validity_in_days);
        setValiditySession(data.validity_for_sessions);
        setNumberOfSession(data.number_of_sessions);
        setPackageStatus(data.status);

        const extraLessonTitle = data.extra_lessons_for_free;

        const extraLessonTitle1 = extraLessonTitle.map((e) => {
          e = e.title;
          return e;
        });
        setExtraLessonsForFree(extraLessonTitle1);
        setOfferPrice(data.offer_price);
      } else {
        console.log('error');
      }
    });
  };

  const lessonDropDown = (payload) => {
    return dropDownLesson(payload).then(
      (res) => {
        setLessonData(res.data.data);
      },
      (err) => {
        console.log('err data: ', err);
      }
    );
  };

  useEffect(() => {
    getTrainingPackageID();
    lessonDropDown();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = () => {
    setLoading(true);
    console.log('packageLessonfieldCount submit: ', packageLessonfieldCount);
    var lessonDataForSubmit = packageLessonfieldCount.map((i, j) => {
      return lessonsdata.filter(function (obj) {
        return packageLessonfieldCount[j].indexOf(obj.title) !== -1;
      });
    });

    var extralessonDataForSubmit = lessonsdata.filter(function (obj) {
      return packageLessonfieldCount.indexOf(obj.title) !== -1;
    });
    console.log('lessonDataForSubmit: ', lessonDataForSubmit);
    console.log('extralessonDataForSubmit: ', extralessonDataForSubmit);
    const payload = {
      _id: idget,
      lessons: lessonDataForSubmit,
      title: userName,
      description: packagefieldCount,
      admin_notes: AdminNotes,
      target_pet: petName,
      price: packagePrice === '' ? 0 : packagePrice,
      number_of_pets: NumberOfPets === '' ? 0 : NumberOfPets,
      validity_in_days: validityDay === '' ? 0 : validityDay,
      number_of_sessions: numberOfSession === '' ? 0 : numberOfSession,
      validity_for_sessions: validitySession === '' ? 0 : validitySession,
      free_trial_sessions: freeSessions === '' ? 0 : freeSessions,
      after_no_of_sessions: afterNoSessions === '' ? 0 : afterNoSessions,
      extra_lessons_for_free: extralessonDataForSubmit,
      status: packageStatus,
    };
    console.log('payload', payload);
    return trainingPackagesUpdate(payload).then(
      (res) => {
        if (res.data.status === true) {
          console.log(res.data);
          setLoading(false);

          swal({
            title: res.data.message,
            icon: 'success',
            timer: 2000,
            button: false,
          });
          navigate('/dashboard/packages');
        } else {
          swal({
            title: res.data.message,
            icon: 'danger',
            timer: 2000,
            button: false,
          });
        }
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  return (
    <div>
      <Grid>
        <Typography
          variant="h3"
          component="div"
          sx={{
            '& > :not(style)': { width: '25ch' },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Add Dog Training Package
        </Typography>
      </Grid>
      <Card>
        <CardContent>
          <Box component="form" minHeight="50vh" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* Title */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      value={userName}
                      name="title"
                      // required
                      // {...register('title', {
                      //   required: true,
                      // })}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.title && errors.title.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Button variant="contained" onClick={() => handleAddLesson()} sx={{ mb: 3 }}>
                      Add Lessons
                    </Button>
                    {packageLessonfieldCount.map((lessons, i) => {
                      console.log('lessons r_____', lessons);
                      return (
                        <Grid sx={{ mt: 3 }}>
                          <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                            <InputLabel id="demo-multiple-chip-label">Lessons</InputLabel>
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              multiple
                              value={lessons}
                              onChange={(e) => {
                                addNewhandleChangeLesson(e, i);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Lessons" />}
                              MenuProps={MenuProps}
                            >
                              {console.log('lessonsdata', lessonsdata)}
                              {lessonsdata.map((e) => {
                                return (
                                  <MenuItem key={e._id} value={e.title} style={getStyles1(e, lessons, theme)}>
                                    {e.title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          {/* After Number of Session */}
                          <Grid
                            sx={{
                              mt: 3,
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="After Number of Session"
                              variant="outlined"
                              value={afterNoSessions[i]}
                              onChange={(e) => addNewhandleChangeNumberSession(e, i)}
                              style={{ width: '100%' }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Button
                                      sx={{ mt: 2 }}
                                      aria-label="Delete"
                                      edge="end"
                                      color="error"
                                      variant="outlined"
                                      onClick={() => handleDeleteLesson(i)}
                                    >
                                      Delete
                                    </Button>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* Free Trial Sessions */}

                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Free Session"
                      variant="outlined"
                      value={freeSessions}
                      onChange={(e) => setFreeSessions(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Validity Sessions */}

                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Validity Session"
                      variant="outlined"
                      value={validitySession}
                      onChange={(e) => setValiditySession(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Extra Lesson For Free */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Extra Lessons Foor Free</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={extraLessonForFree}
                        onChange={(e) => {
                          setExtraLessonsForFree(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Extra Lessons For Free" />}
                        MenuProps={MenuProps}
                      >
                        {lessonsdata.map((e) => (
                          <MenuItem key={e._id} value={e.title} style={getStyles(e, extraLessonForFree, theme)}>
                            {e.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Number of Session */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Number of Session"
                      variant="outlined"
                      value={numberOfSession}
                      onChange={(e) => setNumberOfSession(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Number of Pets */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Number of Pets"
                      type="number"
                      variant="outlined"
                      value={NumberOfPets}
                      onChange={(e) => setNumberOfPets(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Discription */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Button variant="contained" onClick={() => handleAdd()} sx={{ mb: 3 }}>
                      Add Description
                    </Button>

                    {packagefieldCount.map((description, i) => {
                      return (
                        <Grid sx={{ mt: 3 }}>
                          <TextField
                            id="outlined-basic"
                            label="Description"
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                              addNewhandleChangeNumberSession(e, i);
                            }}
                            style={{ width: '100%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    aria-label="Delete"
                                    edge="end"
                                    color="error"
                                    variant="outlined"
                                    onClick={() => handleDelete(i)}
                                  >
                                    Delete
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* Admin Notes */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextareaAutosize
                      id="outlined-basic"
                      minRows={3}
                      label="Admin Notes"
                      variant="outlined"
                      value={AdminNotes}
                      placeholder="Admin Notes"
                      onChange={(e) => setAdminNotes(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  {/* Pet Type */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Pet Type</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={petName}
                        onChange={(e) => {
                          setPetName(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Pet Type" />}
                        MenuProps={MenuProps}
                      >
                        {pet_types.map((target_pet) => (
                          <MenuItem key={target_pet} value={target_pet} style={getStyles(target_pet, petName, theme)}>
                            {target_pet}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Price */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Price"
                      variant="outlined"
                      name="price"
                      required
                      value={packagePrice}
                      // {...register('price', {
                      //   required: true,
                      // })}
                      onChange={(e) => setPackagePrice(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.price && errors.price.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Offer Price */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Offer Price"
                      variant="outlined"
                      name="offerPrice"
                      required
                      value={offerPrice}
                      // {...register('offerPrice', {
                      //   required: true,
                      // })}
                      onChange={(e) => setOfferPrice(e.target.value)}
                      style={{ width: '100%' }}
                    />
                    {errors.offerPrice && errors.offerPrice.type === 'required' && (
                      <p className="text-danger" style={{ color: 'red' }}>
                        *required
                      </p>
                    )}
                  </Grid>

                  {/* Validity */}
                  <Grid
                    sx={{
                      mt: 3,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Validity In Days"
                      variant="outlined"
                      value={validityDay}
                      onChange={(e) => setValidityDay(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  {/* Status */}
                  <Grid>
                    <FormControl sx={{ minWidth: '100%', mt: 3 }}>
                      <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={packageStatus}
                        onChange={(e) => {
                          setPackageStatus(e.target.value);
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        MenuProps={MenuProps}
                      >
                        {packages_status.map((packages_status) => (
                          <MenuItem
                            key={packages_status}
                            value={packages_status}
                            style={getStyles(packages_status, packageStatus, theme)}
                          >
                            {packages_status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid sx={{ mt: 3 }}>
                    <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                      <LoadingButton variant="contained" type="submit">
                        Update Dog Training Package
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditDogTraining;
